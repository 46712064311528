import React from 'react';

import WarningBox from '@/components/WarningBox';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {areAddressesEqual} from '@/utils/ethereum';

export const useDeliverWalletValidation = ():
  | ITransactionValidationError
  | undefined => {
  const {transferParams, deliveryWallet} = useTransferState();

  console.log({
    transferParams,
    deliveryWallet,
  });

  if (
    !deliveryWallet ||
    !areAddressesEqual(transferParams.from, deliveryWallet.address)
  ) {
    return undefined;
  }

  return {
    message: (
      <WarningBox
        message={{
          id: 'transfer.validation.wrongDeliveryWallet',
        }}
      />
    ),
  };
};
