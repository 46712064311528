import React, {FC} from 'react';
import {View} from 'react-native';

import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import Toast from '@/modules/Toasts/Toast';
import {IPendingTransaction} from '@/store/transactions';
import {useThemedStyles} from '@/theme';
import {TransactionStep} from '@/types/transactions';
import {getExplorerTxLink} from '@/utils/ethereum';

import {styles} from './TransactionToast.style';

interface IProps {
  transaction: IPendingTransaction;
  onClose: () => void;
}

const TransactionToast: FC<IProps> = ({transaction, onClose}) => {
  const style = useThemedStyles(styles);

  const {txHash, step, error} = transaction;
  const txUrl = getExplorerTxLink('', txHash);

  return (
    <Toast onClose={onClose} closeOnPress={false}>
      <View>
        {step === TransactionStep.Error && error && (
          <Text
            id={error.textId}
            align="center"
            color="invertedTextColor"
            size="xs">
            {error.text}
          </Text>
        )}
        {step === TransactionStep.Success && (
          <Text
            id="buyNft.txSuccess"
            align="center"
            color="invertedTextColor"
            size="xs"
          />
        )}
        {step === TransactionStep.ApprovalTransaction && (
          <Text
            id="buyNft.waitingForApproval"
            align="center"
            color="invertedTextColor"
            size="xs"
          />
        )}
        {step === TransactionStep.MainTransaction && (
          <Text
            id={
              txHash
                ? 'buyNft.waitingForValidation'
                : 'buyNft.waitingForConfirmation'
            }
            align="center"
            color="invertedTextColor"
            size="xs"
          />
        )}
        {step === TransactionStep.Checkout && (
          <Text
            id="buyNft.processingTransaction"
            align="center"
            color="invertedTextColor"
            size="xs"
          />
        )}

        {txUrl && (
          <Space mt="xs">
            <ExternalLink url={txUrl}>
              <Space style={style.etherscanLink}>
                <Text
                  size="xs"
                  underline
                  id="buyNft.viewTransactionDetails"
                  color="invertedTextColor"
                />
                <Space ml="xs" />
                <Icon
                  name="externalLink"
                  provider="custom"
                  size={12}
                  color="invertedTextColor"
                />
              </Space>
            </ExternalLink>
          </Space>
        )}
      </View>
    </Toast>
  );
};

export default TransactionToast;
