import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles, ARROW_ICON_SIZE} from './Carousel.style';

interface IProps<ItemType> {
  items: ItemType[];
  renderItem: (item: ItemType) => ReactNode;
  keyExtractor: (item: ItemType) => string;
  onSelectItem: (item: ItemType) => void;
  activeKey: string;
  itemWidth: number;
  disabled?: boolean;
}

const INACTIVE_ITEM_SCALE = 0.6;
const INACTIVE_ITEM_OPACITY = 0.2;

const getArrowPosition = (
  itemWidth: number,
  buttonSize = 32,
  offset = spacing.xs,
) =>
  itemWidth / 2 +
  buttonSize / 2 +
  INACTIVE_ITEM_SCALE * itemWidth -
  ((1 - INACTIVE_ITEM_SCALE) * itemWidth) / 2 +
  offset;

const Carousel = <ItemType,>({
  items,
  renderItem,
  keyExtractor,
  onSelectItem,
  itemWidth,
  activeKey,
  disabled = false,
}: IProps<ItemType>) => {
  const style = useThemedStyles(styles);

  const length = items.length;
  const activeIndex = items.findIndex(item => keyExtractor(item) === activeKey);

  return (
    <View style={style.container}>
      <View
        style={[
          style.arrowContainer,
          {
            transform: [
              {
                translateX: -getArrowPosition(itemWidth),
              },
            ],
          },
        ]}>
        <IconButton
          hitSlop="m"
          disabled={activeIndex === 0 || disabled}
          onPress={() => onSelectItem(items[activeIndex - 1])}
          icon={{name: 'arrowLeft', provider: 'custom', size: ARROW_ICON_SIZE}}
        />
      </View>

      {items.map((item, index) => {
        const isActive = index === activeIndex;
        const scale = isActive ? 1 : INACTIVE_ITEM_SCALE;
        const zIndex = isActive ? 1 : 0;
        const baseTranslate =
          ((length - 1) / 2) * itemWidth - activeIndex * itemWidth;
        const scaleCompensationTranslate =
          (activeIndex - index) * ((1 - INACTIVE_ITEM_SCALE) * itemWidth);
        const translateX = baseTranslate + scaleCompensationTranslate;

        return (
          <CarouselItem
            key={keyExtractor(item)}
            width={itemWidth}
            zIndex={zIndex}
            translateX={translateX}
            opacity={isActive ? 1 : INACTIVE_ITEM_OPACITY}
            scale={scale}>
            {renderItem(item)}
          </CarouselItem>
        );
      })}

      <View
        style={[
          style.arrowContainer,
          {
            transform: [
              {
                translateX: getArrowPosition(itemWidth),
              },
            ],
          },
        ]}>
        <IconButton
          hitSlop="m"
          disabled={activeIndex === length - 1 || disabled}
          onPress={() => onSelectItem(items[activeIndex + 1])}
          icon={{name: 'arrowRight', provider: 'custom', size: ARROW_ICON_SIZE}}
        />
      </View>
    </View>
  );
};

const CarouselItem: FC<{
  children: ReactNode;
  width: number;
  opacity: number;
  zIndex: number;
  translateX: number;
  scale: number;
}> = ({children, width, opacity, zIndex, translateX, scale}) => {
  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(opacity),
      transform: [
        // @ts-ignore
        {translateX: withTiming(translateX)},
        // @ts-ignore
        {scale: withTiming(scale)},
      ],
    }),
    [opacity, translateX, scale],
  );

  return (
    <Animated.View style={[{width, zIndex}, animatedStyle]}>
      {children}
    </Animated.View>
  );
};

export default Carousel;
