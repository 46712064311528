import {IIconProps} from '@/components/Icon';
import {INftEvent} from '@/types/nfts';
import {IBaseUser} from '@/types/user';

const EVENT_TYPE_ICONS: {[key: string]: IIconProps} = {
  mint: {
    name: 'nftMint',
    provider: 'custom',
  },
  transfer: {
    name: 'exchange',
    provider: 'fontAwesome',
  },
};

export const useEventInfo = (
  event: INftEvent,
): {
  icon: IIconProps;
  relativeTimestamp: number;
  sender?: IBaseUser;
  receiver?: IBaseUser;
} => {
  const {type, sender, receiver, createdAtTime} = event;
  const icon = EVENT_TYPE_ICONS[type];
  const relativeTimestamp =
    (new Date(createdAtTime).getTime() - Date.now()) / 1000;

  return {
    icon,
    relativeTimestamp,
    sender: sender || undefined,
    receiver: receiver || undefined,
  };
};
