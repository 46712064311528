import API from '@/services/spinampApi';
import {IPlaybackEvent} from '@/types/events';
import {ISigner} from '@/types/session';
import {getSignedRequestBody} from '@/utils/api';

export const sendEvents = async (
  events: IPlaybackEvent[],
  signer: ISigner,
): Promise<void> => {
  const body = await getSignedRequestBody(events, signer);

  return await API.post('/playbackEvent', body);
};
