import azeretMonoBold from '@/assets/fonts/AzeretMono-Bold.ttf';
import azeretMonoLight from '@/assets/fonts/AzeretMono-Light.ttf';
import azeretMonoRegular from '@/assets/fonts/AzeretMono-Regular.ttf';
import azeretMonoSemiBold from '@/assets/fonts/AzeretMono-SemiBold.ttf';
import format1452 from '@/assets/fonts/Format_1452.ttf';
import neueHassDisplayLight from '@/assets/fonts/NeueHaasDisplay-Light.ttf';
import poppinsBold from '@/assets/fonts/Poppins-Bold.ttf';
import poppinsLight from '@/assets/fonts/Poppins-Light.ttf';
import poppinsRegular from '@/assets/fonts/Poppins-Regular.ttf';
import poppinsSemiBold from '@/assets/fonts/Poppins-SemiBold.ttf';
import spaceGrotesqueB from '@/assets/fonts/SpaceGrotesk-Bold.ttf';
import spaceGrotesqueL from '@/assets/fonts/SpaceGrotesk-Light.ttf';
import spaceGrotesqueM from '@/assets/fonts/SpaceGrotesk-Medium.ttf';
import spaceGrotesqueSB from '@/assets/fonts/SpaceGrotesk-SemiBold.ttf';

const fontsConfig = [
  {fontFamily: 'Poppins-Light', font: poppinsLight},
  {fontFamily: 'Poppins-Regular', font: poppinsRegular},
  {fontFamily: 'Poppins-SemiBold', font: poppinsSemiBold},
  {fontFamily: 'Poppins-Bold', font: poppinsBold},
  {fontFamily: 'Format_1452', font: format1452},
  {fontFamily: 'NeueHaasDisplay-Light', font: neueHassDisplayLight},
  {fontFamily: 'AzeretMono-Light', font: azeretMonoLight},
  {fontFamily: 'AzeretMono-Regular', font: azeretMonoRegular},
  {fontFamily: 'AzeretMono-SemiBold', font: azeretMonoSemiBold},
  {fontFamily: 'AzeretMono-Bold', font: azeretMonoBold},
  {fontFamily: 'Helvetica', font: 'Helvetica'},
  {fontFamily: 'SpaceGrotesk-Light', font: spaceGrotesqueL},
  {fontFamily: 'SpaceGrotesk-Medium', font: spaceGrotesqueM},
  {fontFamily: 'SpaceGrotesk-Bold', font: spaceGrotesqueB},
  {fontFamily: 'SpaceGrotesk-SemiBold', font: spaceGrotesqueSB},
];

export const loadFonts = () => {
  const style = document.createElement('style');

  fontsConfig.forEach(({fontFamily, font}) => {
    const fontStyles = `@font-face {
        font-family: ${fontFamily};
        font-style: normal;
        font-display: swap;
        src: url(${font});
      }`;

    style.appendChild(document.createTextNode(fontStyles));
    document.head.appendChild(style);
  });
};
