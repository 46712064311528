import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  divider: {
    marginHorizontal: -spacing.l,
    borderTopWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
  },
}));
