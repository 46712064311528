import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface FeedState {
  shouldPlay: boolean;
  activeIndex: number;
}

export const initialState: FeedState = {
  shouldPlay: false,
  activeIndex: 0,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setShouldPlay: (state, action: PayloadAction<boolean>) => {
      state.shouldPlay = action.payload;
    },
    setActiveIndex: (state, action: PayloadAction<number>) => {
      state.activeIndex = action.payload;
    },
  },
});

export const {setShouldPlay, setActiveIndex} = feedSlice.actions;

export default feedSlice.reducer;
