import {NavigationAction} from '@react-navigation/native';
import {CSSProperties, ReactNode} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

type LinkChildren = (({hover}: {hover: boolean}) => ReactNode) | ReactNode;

export type CrossPlatformStyle = StyleProp<ViewStyle> & CSSProperties;

export interface ILinkProps {
  children: LinkChildren;
  // react-navigation does not export To type properly
  to: any;
  action?: NavigationAction;
  style?: CrossPlatformStyle;
  onPress?: () => void;
}

export const renderContent = (children: LinkChildren, hover: boolean = false) =>
  typeof children === 'function' ? children({hover}) : children;
