import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    root: {
      flex: 1,
      paddingTop: safeAreaInsets.top,
      width: 'auto',
      backgroundColor: theme.colors.background,
    },
    divider: {
      transform: [{translateY: -1}],
      height: 1,
      width: '100%',
      backgroundColor: theme.colors.backgroundDark,
    },
    scrollContainer: {
      flex: 1,
    },
    contentContainer: {
      flexGrow: 1,
    },
    item: {
      paddingVertical: spacing.xs,
      paddingHorizontal: spacing.s,
      marginVertical: spacing.xxs,
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconWrapper: {
      width: spacing.m,
      maxWidth: spacing.m,
      marginRight: spacing.xs,
    },
    externalLinkIcons: {
      flexDirection: 'row',
      marginLeft: spacing.xs,
      gap: spacing.xs,
      paddingVertical: spacing.xs,
    },
  }),
);
