import React, {FC} from 'react';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './DashedDivider.style';

const DashedDivider: FC = () => {
  const style = useThemedStyles(styles);

  return <View style={style.divider} />;
};

export default DashedDivider;
