import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.textColor,
    opacity: 0.2,
  },
}));
