import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const SCREEN_PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  scrollContainer: {
    height: 432,
  },
  scrollContent: {
    padding: SCREEN_PADDING,
    paddingTop: spacing.xxs,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  listFooter: {
    maxWidth: 300,
    marginTop: spacing.s,
  },
  listFooterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  listHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  listHeader: {
    maxWidth: '100%',
  },
  emptyText: {
    textAlign: 'center',
    marginVertical: spacing.l,
  },
  descriptionText: {
    textAlign: 'center',
    paddingBottom: spacing.xs,
  },
  tabs: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: spacing.l,
    paddingTop: spacing.xxs,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  inlineError: {
    maxWidth: 400,
    alignSelf: 'center',
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  gradientContainer: {
    height: spacing.s,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
