import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {FlatList} from 'react-native-gesture-handler';

import Modal, {IModalProps} from '@/components/Modal';
import {WalletCard} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './WalletListModal.style';

interface IProps extends IModalProps {
  addresses: IAddress[];
}

const WalletListModal: FC<IProps> = ({addresses, ...modalProps}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <Modal {...modalProps} titleId="wallets.title">
      <FlatList
        contentContainerStyle={style.content}
        data={addresses.filter(a => a.isPublic)}
        renderItem={({item: address}) => (
          <WalletCard
            isSelected
            wallet={address}
            onPress={() => {
              modalProps.onClose();
              navigation.navigate(Routes.MainNavigation, {
                screen: Routes.Wallet,
                params: {id: address.address},
              });
            }}
          />
        )}
      />
    </Modal>
  );
};

export default WalletListModal;
