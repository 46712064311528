import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  inputWrapper: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    flexDirection: 'row',
    backgroundColor: theme.colors.backgroundLight,
  },
  input: {
    flex: 1,
    color: theme.colors.textColor,
    fontSize: 14,
    fontFamily: theme.font.regular,
    paddingVertical: Platform.select({
      android: spacing.xxs,
      default: spacing.xs,
    }),
  },
  inputActive: {
    fontFamily: theme.font.semibold,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
