import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const CARD_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  wallet: {
    height: CARD_HEIGHT,
    borderRadius: theme.box.borderRadius,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.colors.backgroundDark,
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing.xs,
    gap: spacing.s,
  },
  wallet_selected: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderColor,
  },
}));
