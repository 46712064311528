import {useCallback} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import {useCreatePlaylist, useDeletePlaylist} from '@/queries/ownedPlaylists';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {selectUserPlaylists} from '@/store/playlists';
import {selectSelectedUserId} from '@/store/user';
import {PlaylistType} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';

interface IUseArtistFollowOptions {
  skipFeedQueryInvalidation: boolean;
}

const DEFAULT_OPTIONS = {
  skipFeedQueryInvalidation: false,
};

/**
 * Allows seeing if an artist is followed and toggling between following/unfollowing them.
 *
 * Note: if you try to follow an artistId which is not in our local DB, this will fail.
 */
export const useArtistFollow = (
  options: IUseArtistFollowOptions = DEFAULT_OPTIONS,
) => {
  const {deletePlaylistAsyncMutation} = useDeletePlaylist();
  const {createPlaylistAsyncMutation} = useCreatePlaylist();
  const playlists = useAppSelector(selectUserPlaylists);
  const userId = useAppSelector(selectSelectedUserId);

  const {db} = useDbQuery();

  const follow = async (artistId: string) => {
    const artist = db.artists[artistId];

    if (!artist) {
      Sentry.captureMessage(
        `Can't follow artist ${artistId} because it is not in the local db cache`,
      );

      return;
    }

    await createPlaylistAsyncMutation({
      title: artist.name,
      type: PlaylistType.artist,
      artistId: artist.id,
    });

    if (!options.skipFeedQueryInvalidation) {
      queryClient.invalidateQueries([QueryKeys.feed, userId]);
    }
  };

  const unfollow = useCallback(
    async (artistId: string) => {
      const artistFollow = playlists.find(p => p.artistId === artistId);

      if (!artistFollow) {
        return null;
      }
      await deletePlaylistAsyncMutation(artistFollow.id);

      if (!options.skipFeedQueryInvalidation) {
        queryClient.invalidateQueries([QueryKeys.feed, userId]);
      }
    },
    [playlists],
  );
  const getIsFollowed = useCallback(
    (artistId: string) => !!playlists.find(p => p.artistId === artistId),
    [playlists],
  );

  const toggleFollow = useCallback(
    (artistId: string) =>
      getIsFollowed(artistId) ? unfollow(artistId) : follow(artistId),
    [getIsFollowed, playlists],
  );

  return {
    getIsFollowed,
    toggleFollow,
    follow,
    unfollow,
  };
};
