import React, {useState} from 'react';
import Modal from '@/components/Modal/Modal';

import {LocalThemeProvider, useThemedStyles} from '@/theme';

import {IArtist} from '@/types/common';
import ArtistScreenContents from '@/screens/Artist/ArtistScreenContents';
import {styles} from './common.styles';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import useOnUnfocus from '@/hooks/useOnUnfocus';
import ArtistInfo from '@/screens/Artist/components/ArtistInfo/ArtistInfo';

interface ITrackModalProps {
  artist: IArtist;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ArtistModal: React.FC<ITrackModalProps> = props => {
  const {artist, isOpen, setIsOpen} = props;
  const style = useThemedStyles(styles);
  const {artistThemes} = useCustomThemesConfigQuery();

  useOnUnfocus(setIsOpen);

  const [localTab, changeLocalTab] = useState('tracks');
  const tabControl = {
    tab: localTab,
    changeTab: changeLocalTab,
  };

  return (
    <LocalThemeProvider
      customTheme={artist.customTheme}
      predefinedThemeName={
        artist.predefinedThemeName || artistThemes[artist.id]
      }>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        contentStyle={style.modal}>
        <ArtistScreenContents
          tabControl={tabControl}
          artist={artist}
          artistInfo={
            <ArtistInfo
              isOwnProfile={false}
              linkEnabled={true}
              artist={artist}
            />
          }
        />
      </Modal>
    </LocalThemeProvider>
  );
};

export default ArtistModal;
