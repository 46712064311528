import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  title: {
    marginBottom: spacing.xs,
  },
  artistName: {
    maxWidth: '100%',
  },
  artistTitleTextContainer: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));
