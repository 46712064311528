import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const SPIN_LOGO_SIZE = 24;

export const styles = createThemedStyles(theme => ({
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moreButton: {
    marginRight: 15,
  },
  spinButton: {
    padding: 0,
    width: SPIN_LOGO_SIZE,
    height: SPIN_LOGO_SIZE,
    borderRadius: SPIN_LOGO_SIZE / 2,
    overflow: 'hidden',
    borderColor: theme.colors.backgroundDark,
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinLogo: {
    width: '100%',
    height: '100%',
  },
  rewind: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  rewindIcon: {
    color: theme.colors.borderColor,
  },
}));
