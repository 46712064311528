import {Link, useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AvatarLayout from '@/components/AvatarLayout';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import ImageWithPreview from '@/components/ImageWithPreview';
import {CollapsibleRenderHtml} from '@/components/RenderHtml';
import Share from '@/components/Share';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {externalPlatformsConfig} from '@/constants/externalPlatforms';
import spacing from '@/constants/spacing';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useResponsive} from '@/hooks/useResponsive';
import {WalletListModal} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {ImageSize} from '@/types/media';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {getArtistUrl} from '@/utils/share';

import {styles} from './ArtistInfo.style';

interface IProps {
  artist: IArtist;
  isOwnProfile: boolean;
  linkEnabled: boolean;
}

const ArtistInfo: FC<IProps> = ({artist, isOwnProfile, linkEnabled}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();
  const {isMobile} = useResponsive();

  const [isWalletsModalOpen, openWalletsModal, closeWalletsModal] =
    useBooleanState(false);

  const {getIsFollowed, toggleFollow} = useArtistFollow();
  const isFollowed = getIsFollowed(artist.id);

  const artistLinkConfig = {
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Artist,
        params: {slugOrId: artist.slug},
      },
    },
  };

  const artistName = (
    <Text weight="semibold" size="l" align="center" style={style.titleText}>
      {artist.name}
    </Text>
  );

  return (
    <View style={style.container}>
      <AvatarLayout
        avatar={
          artist.avatarUrl && (
            <View style={style.avatarWrapper}>
              <ImageWithPreview
                source={{uri: artist.avatarUrl}}
                style={style.avatar}
                resizeWidth={ImageSize.artist}
                hasBorderRadius
                fallback={
                  <Icon
                    name="userMusic"
                    provider="custom"
                    size={36}
                    color="primary"
                  />
                }
              />
            </View>
          )
        }
        title={
          <>
            {linkEnabled ? (
              <Link {...artistLinkConfig}>{artistName}</Link>
            ) : (
              <>{artistName}</>
            )}
            {artist.addresses.some(a => a.isPublic) && (
              <TransparentButton
                pressedColor="background"
                onPress={openWalletsModal}
                height={spacing.l}
                text={{id: 'wallets.title', weight: 'regular', size: 'xs'}}
                icon={{name: 'wallet', provider: 'custom', size: 16}}
                iconSpacing={spacing.xxs}
                suffix={<Icon name="arrowRight" provider="custom" size={16} />}
              />
            )}
          </>
        }
        leftAction={
          <Share url={getArtistUrl(artist.slug)} title={artist.name}>
            {({onPress, icon}) => <IconButton onPress={onPress} icon={icon} />}
          </Share>
        }
        rightAction={
          isOwnProfile ? (
            <IconButton
              onPress={() =>
                navigation.navigate(Routes.ArtistEdit, {slugOrId: artist.slug})
              }
              icon={{
                name: 'edit',
                provider: 'custom',
                fill: isFollowed,
              }}
            />
          ) : (
            <IconButton
              onPress={() => toggleFollow(artist.id)}
              icon={{
                name: 'heart',
                provider: 'custom',
                fill: isFollowed,
                color: isFollowed ? 'favoritesColor' : 'textColor',
              }}
            />
          )
        }
      />

      {!!artist.description && (
        <View style={style.description}>
          <CollapsibleRenderHtml html={artist.description} closedHeight={100} />
        </View>
      )}

      {artist.externalLinks?.length > 0 && (
        <View style={style.socialLinks}>
          {artist.externalLinks.map(externalLink => {
            const config = externalPlatformsConfig[externalLink.type];

            return (
              <IconButton
                href={externalLink.url}
                size={isMobile ? spacing.xxl : undefined}
                key={externalLink.id}
                icon={{
                  name: config?.icon || 'externalPlatform',
                  provider: 'custom',
                }}
              />
            );
          })}
        </View>
      )}

      {isWalletsModalOpen && (
        <WalletListModal
          addresses={artist.addresses}
          isOpen
          onClose={closeWalletsModal}
        />
      )}
    </View>
  );
};

export default ArtistInfo;
