import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const borderRadius = 99999;

export const styles = createThemedStyles(theme => ({
  artistTitle: {
    flexDirection: 'row',
    gap: spacing.xs,
    alignItems: 'center',
  },
  artistTitleAvatar: {
    width: 36,
    height: 36,

    // Round the image
    borderRadius: borderRadius,
    overflow: 'hidden',
  },
  tracks: {
    backgroundColor: theme.colors.background,
    width: '100%',
    // This is unfortunate but fixes tracks not showing
    position: 'absolute',
  },
  empty: {
    margin: spacing.xs,
  },
  avatarPlaceholder: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: borderRadius,
    backgroundColor: theme.colors.backgroundDark,
  },

  track: {
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  artworkContainer: {
    borderRadius: theme.box.borderRadius,
    padding: spacing.xxs,
  },
}));
