import React from 'react';
import {ScrollView, View} from 'react-native';

import CustomIcons from '@/assets/icons';
import Button from '@/components/Button';
import OpacityGradient from '@/components/OpacityGradient';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useGenerateFeedMutation} from '@/queries/feed';
import {selectActiveUserId} from '@/store/user';
import {useTheme, useThemedStyles} from '@/theme';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';

interface IRefillFeedCardProps {
  scrollToTop?: () => void;
  genres?: Genre[];
  onboarding?: boolean;
}

type Genre = {
  text: string;
  key: string;
};

const REFILL_GENRES: Genre[] = [
  // These are ordered by how many of each genre tag exist
  // in Spindexer's raw_tags table
  {text: 'Hip-hop & Rap', key: 'Hip-hop & Rap'},
  {text: 'Electronic', key: 'Electronic'},
  {text: 'Pop', key: 'Pop'},
  {text: 'Dance & EDM', key: 'Dance & EDM'},
  {text: 'R&B & Soul', key: 'R&B & Soul'},
  {text: 'Indie', key: 'Indie'},
  {text: 'Alternative Rock', key: 'Alternative Rock'},
  {text: 'Experimental', key: 'Experimental'},
  {text: 'House', key: 'House'},
  {text: 'Ambient', key: 'Ambient'},
  {text: 'Folk & Singer-Songwriter', key: 'Folk & Singer-Songwriter'},
  {text: 'Drum & Bass', key: 'Drum & Bass'},
  {text: 'Techno', key: 'Techno'},
  {text: 'Trap', key: 'Trap'},
  {text: 'Rock', key: 'Rock'},
  {text: 'Lofi', key: 'Lofi'},
  {text: 'Soundtrack', key: 'Soundtrack'},
  {text: 'World', key: 'World'},
  {text: 'Deep House', key: 'Deep House'},
  {text: 'Downtempo', key: 'Downtempo'},
  {text: 'Afrobeat', key: 'Afrobeat'},
  {text: 'Dubstep', key: 'Dubstep'},
  {text: 'Metal', key: 'Metal'},
  {text: 'Latin', key: 'Latin'},
  {text: 'Jazz & Blues', key: 'Jazz & Blues'},
  {text: 'Classical', key: 'Classical'},
  {text: 'Reggaeton', key: 'Reggaeton'},
  {text: 'Piano', key: 'Piano'},
  {text: 'Country', key: 'Country'},
  {text: 'Reggae', key: 'Reggae'},
  {text: 'Dancehall', key: 'Dancehall'},
  {text: 'Trance', key: 'Trance'},
  {text: 'Disco', key: 'Disco'},
  {text: 'Triphop', key: 'Triphop'},
  {text: 'Hyperpop', key: 'Hyperpop'},
];

const RefillFeedCard: React.FC<IRefillFeedCardProps> = ({
  scrollToTop,
  genres = REFILL_GENRES,
  onboarding = false,
}) => {
  const style = useThemedStyles(styles);

  const toast = useToast();

  const theme = useTheme();

  const activeUserId = useAppSelector(selectActiveUserId);

  const {generateFeed, mutation} = useGenerateFeedMutation(activeUserId);

  const generateGenre = (genre: string) => () =>
    generateFeed([{name: 'genre', args: [genre]}], {
      onSuccess: (generatedFeedItemsCount, _variables, _context) => {
        if (generatedFeedItemsCount === 0) {
          toast.showToast({
            textId: 'feed.genreRefill.noMoreTracks',
            values: {genre},
          });
        } else {
          if (generatedFeedItemsCount <= 5) {
            toast.showToast({
              textId: 'feed.genreRefill.fewTracksLeft',
              values: {genre, count: generatedFeedItemsCount},
            });
          }
          scrollToTop?.();
        }
      },
      onError: (_error, _variables, _options) => {
        toast.showToast({
          textId: 'feed.genreRefill.error',
        });
      },
    });

  const textIdBase = onboarding ? 'feed.refill.onboarding' : 'feed.refill';
  return (
    <BaseFeedCard>
      <Space p="s" style={style.featureContainer}>
        <CustomIcons.feedEmpty
          height={100}
          style={style.featureIcon}
          color={theme.colors.textColor}
        />

        <Space pb="m" />
        <Text
          id={`${textIdBase}.title`}
          weight="bold"
          style={style.centeredText}
          size="l"
        />
        <Space pb="m" />
        <Text id={`${textIdBase}.description`} style={style.centeredText} />
      </Space>
      <ScrollView
        contentContainerStyle={style.genreContainer}
        showsVerticalScrollIndicator={false}>
        {genres.map(g => (
          <Button
            key={g.key}
            isLoading={
              mutation.isLoading &&
              mutation.variables?.flatMap(it => it.args).includes(g.key)
            }
            text={g.text}
            onPress={generateGenre(g.key)}
            style={style.refillButton}
          />
        ))}
      </ScrollView>
      <View style={style.gradientContainer}>
        <OpacityGradient
          color="background"
          style={style.gradient}
          vertical={true}
        />
      </View>
    </BaseFeedCard>
  );
};

export default RefillFeedCard;
