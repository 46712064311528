import spacing from '@/constants/spacing';
import {createThemedStyles, isThemeMonochrome} from '@/theme';

export const styles = createThemedStyles(theme => ({
  progressContainer: {
    width: '100%',
    height: spacing.xxs,
    zIndex: 9999,
    top: -spacing.xs,
  },
  hitSlopContainer: {
    height: spacing.m,
  },
  progress: {
    backgroundColor: theme.colors.backgroundDark,
    borderRadius: 0,
  },
  imageContainer: {
    height: '100%',
    flexShrink: 1,
    overflow: 'hidden',
    borderTopRightRadius: Math.max(0, theme.box.borderRadius - 3),
    borderTopLeftRadius: Math.max(0, theme.box.borderRadius - 3),
  },
  infoContainer: {
    flexGrow: 1,
    padding: spacing.xs,
    alignItems: 'flex-start',
    borderTopWidth: 0,
    borderColor: theme.colors.borderColor,
  },
}));
