import {useNetInfo} from '@react-native-community/netinfo';
import {useEffect} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import {saveEvents, setIsSyncing} from '@/store/events';

export const useSyncPlaybackEvents = () => {
  const {isConnected} = useNetInfo();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // reset isSyncing value on app start in case where app was killed during syncing
    dispatch(setIsSyncing(false));
  }, []);

  useEffect(() => {
    if (isConnected) {
      dispatch(saveEvents());

      const intervalId = setInterval(() => {
        dispatch(saveEvents());
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [isConnected]);
};
