import {useNavigation} from '@react-navigation/native';
import React, {FC, ReactNode} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {
  SharedValue,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';

import Avatar from '@/components/Avatar/Avatar';
import {useDrawer} from '@/components/DrawerMenu';
import Icon, {IIconProps} from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text/Text';
import spacing, {HIT_SLOP} from '@/constants/spacing';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useResponsive} from '@/hooks/useResponsive';
import {calculateBackgroundColor, useTheme, useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {Color} from '@/types/theme';
import {getUserAvatar} from '@/utils/user';

import {
  styles,
  AVATAR_SIZE,
  ACTIONS_MIN_WIDTH,
  HEADER_PADDING,
} from './Header.style';
import {useCenteredHeader} from './useCenteredHeader';

// showBack, showMenu and leftActions are all mutually exclusive
type ILeftActionsProps =
  | {
      showBack?: true;
      showMenu?: false;
      leftActions?: undefined;
    }
  | {
      showBack?: false;
      showMenu?: true;
      leftActions?: undefined;
    }
  | {
      showBack?: false;
      showMenu?: false;
      leftActions?: ReactNode;
    };

export type IHeaderProps = {
  titleId?: string;
  title?: string;
  titleValues?: Record<string, string>;
  titleProps?: TextProps;
  icon?: IIconProps;
  rightActions?: ReactNode;
  mockTitle?: boolean;
  scrollPosition?: SharedValue<number>;
  backgroundColor?: Color;
  modalMode?: boolean;
  children?: ReactNode;
} & ILeftActionsProps;

const Header: FC<IHeaderProps> = ({
  titleId,
  title,
  titleValues,
  titleProps = {},
  icon,
  leftActions,
  rightActions,
  showBack = false,
  showMenu = false,
  mockTitle = false,
  scrollPosition,
  backgroundColor = 'backgroundLight',
  modalMode = false,
  children,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {isMobile} = useResponsive();
  const {openDrawer} = useDrawer();
  const navigation = useNavigation();
  const activeUser = useActiveUser();

  const headerMaskStyle = useAnimatedStyle(() => {
    if (!scrollPosition) {
      return {
        opacity: 1,
      };
    }

    return {
      opacity: withSpring(scrollPosition.value > spacing.xxs ? 1 : 0, {
        mass: 0.2,
      }),
    };
  }, []);

  const renderLeftActions = () => {
    if (isMobile && showMenu) {
      return (
        <TouchableOpacity onPress={openDrawer} hitSlop={HIT_SLOP}>
          {activeUser ? (
            <Avatar
              url={getUserAvatar(activeUser)}
              id={activeUser.id}
              size={AVATAR_SIZE}
              resizeWidth={ImageSize.avatar * 2}
            />
          ) : (
            <Icon provider="custom" name="user" size={18} />
          )}
        </TouchableOpacity>
      );
    }

    if (showBack) {
      return (
        <IconButton
          onPress={() => navigation.goBack()}
          icon={{name: 'arrowLeft', provider: 'custom'}}
        />
      );
    }

    return leftActions;
  };

  const {onLeftActionsLayout, onRightActionsLayout, actionWidth} =
    useCenteredHeader();

  return (
    <View
      style={[
        style.container,
        !modalMode && style.safeArea,
        modalMode && style.container_modal,
        {
          backgroundColor: calculateBackgroundColor(theme, backgroundColor),
        },
      ]}>
      <Animated.View style={[style.headerMask, headerMaskStyle]} />
      <View style={style.headerWrapper}>
        <View style={style.header}>
          <View onLayout={onLeftActionsLayout} style={style.actionsContainer}>
            {renderLeftActions()}
          </View>

          <Animated.View
            style={[
              style.titleContainer,
              !mockTitle && headerMaskStyle,
              {
                left: HEADER_PADDING + (actionWidth || ACTIONS_MIN_WIDTH),
                right: HEADER_PADDING + (actionWidth || ACTIONS_MIN_WIDTH),
              },
            ]}>
            {icon && (
              <Space mr="xs">
                <Icon {...icon} />
              </Space>
            )}
            <Text
              id={titleId}
              values={titleValues}
              weight="semibold"
              numberOfLines={1}
              size="s"
              {...titleProps}>
              {title}
            </Text>
          </Animated.View>

          <View style={style.actionsContainer} onLayout={onRightActionsLayout}>
            {rightActions}
          </View>
        </View>
        {children}
      </View>
    </View>
  );
};

export default Header;
