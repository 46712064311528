import {Linking, Platform} from 'react-native';

import {IArtist, IBaseArtist, ITrack} from '@/types/common';
import {
  formatFilenameForCloudinary,
  makeCloudinaryDownloadUrl,
} from '@/utils/cloudinary';

export const sortTracksByDate = (a: ITrack, b: ITrack): number => {
  return (
    new Date(b.createdAtTime || '').getTime() -
    new Date(a.createdAtTime || '').getTime()
  );
};

export const getTrackArtists = (track: ITrack) => {
  const artists: IArtist[] = [track.artist];

  if (track.supportingArtist) {
    artists.push(track.supportingArtist);
  }

  return artists;
};

export const getTrackArtistsNames = (track: ITrack) =>
  getTrackArtists(track)
    .map(a => a.name)
    .join(' & ');

export const downloadTrack = (track: ITrack) => {
  // The file extension will be added by Cloudinary
  let downloadFilename = `${track.artist.name} - ${track.title}`;

  // iOS Safari doesn't allow filenames for download to contain dots
  if (Platform.OS === 'ios') {
    downloadFilename = downloadFilename.replaceAll('.', '');
  }

  const formattedFilename = formatFilenameForCloudinary(downloadFilename);
  const audioUrlForDownload = makeCloudinaryDownloadUrl(
    track.lossyAudioUrl,
    'mp3',
    formattedFilename,
  );

  Linking.openURL(audioUrlForDownload);
};
