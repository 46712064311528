import React from 'react';

import CardButton from '@/components/CardButton';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import PasskeyOnboardingBanner from '@/modules/Collect/components/PasskeyOnboardingBanner';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useTopUp} from '@/modules/TopUp/useTopUp';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useUpdateUserMetadataMutation} from '@/queries/user';

export const usePasskeyOnboarding = ():
  | ITransactionValidationError
  | undefined => {
  const {topUp} = useTopUp();
  const {
    balance,
    selectedOffer,
    selectedOfferTotalPrice,
    setRoute,
    paymentWallet,
    user,
  } = useCollectState();
  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  if (!paymentWallet || !selectedOffer || !user) {
    return undefined;
  }

  const {onboarding} = user.metadata;

  const isPasskeyWalletEmpty =
    isPasskeyWallet(paymentWallet) && balance && balance.value === BigInt(0);

  const hasMultipleWallets = user.addresses.filter(a => a.isWallet).length > 1;

  const closeOnboarding = () =>
    updateUserMetadataMutation.mutate({
      user,
      metadataUpdate: {
        onboarding: {
          seenTopUpPasskey: true,
        },
      },
    });

  if (isPasskeyWalletEmpty && !onboarding.seenTopUpPasskey) {
    return {
      action: (
        <>
          <PasskeyOnboardingBanner onClose={closeOnboarding} />
          <CardButton
            onPress={() => {
              topUp({
                address: paymentWallet.address,
                chainId: selectedOffer.chainId,
                amount: selectedOfferTotalPrice,
              });
              closeOnboarding();
            }}
            text={{id: 'transactions.insufficientBalance.topUp'}}
            icon={{name: 'creditCard', provider: 'custom'}}
          />
          {hasMultipleWallets ? (
            <TransparentButton
              style={{alignSelf: 'center'}}
              onPress={() => setRoute('walletsPicker')}
              text={{
                id: 'collect.passkeyOnboarding.changePaymentMethod',
              }}
            />
          ) : (
            <TransparentButton
              style={{alignSelf: 'center'}}
              onPress={() => setRoute('addExternalWallet')}
              text={{
                children: (
                  <Text align="center">
                    <Text
                      id="collect.passkeyOnboarding.addWallet1"
                      size="xs"
                      align="center"
                    />
                    <Text
                      id="collect.passkeyOnboarding.addWallet2"
                      size="xs"
                      align="center"
                      weight="semibold"
                      underline
                    />
                  </Text>
                ),
              }}
            />
          )}
        </>
      ),
    };
  }

  return undefined;
};
