import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {removeDeviceToken, saveDeviceToken} from '@/api/notifications';
import {useAppSelector} from '@/hooks/useRedux';
import {selectSelectedUserId, selectSignerByUserId} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';
import {ISigner} from '@/types/session';
import {
  checkNotificationsPermission,
  enabledStatuses,
  getNotificationToken,
  requestNotificationsPermission,
} from '@/utils/notifications';

export const useNotificationsPermissionQuery = (isRoot = false) => {
  const {data: authorizationStatus} = useQuery({
    queryKey: [QueryKeys.notificationsPermissions],
    queryFn: checkNotificationsPermission,
    enabled: isRoot,
  });

  return {
    authorizationStatus,
    areNotificationsEnabled:
      !!authorizationStatus && enabledStatuses.includes(authorizationStatus),
  };
};

export const useNotificationsPermissionMutation = () => {
  const queryClient = useQueryClient();
  const {mutate: requestPermission} = useMutation(
    requestNotificationsPermission,
    {
      onSuccess: authorizationStatus => {
        queryClient.setQueryData(
          [QueryKeys.notificationsPermissions],
          authorizationStatus,
        );
      },
    },
  );

  return {requestPermission};
};

export const useNotificationsTokenQuery = (isEnabled = false) => {
  const userId = useAppSelector(selectSelectedUserId);
  const signer = useAppSelector(state => selectSignerByUserId(state, userId));
  const queryClient = useQueryClient();

  const {data: token} = useQuery({
    queryKey: [QueryKeys.notificationToken, userId],
    queryFn: async () => {
      const currentToken = queryClient.getQueryData<string | undefined>([
        QueryKeys.notificationToken,
        userId,
      ]);
      const newToken = await getNotificationToken();

      if (signer && newToken !== currentToken) {
        await saveDeviceToken(newToken, signer);
      }

      return newToken;
    },
    enabled: isEnabled && !!signer,
  });

  return token;
};

export const useRemoveDeviceTokenMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({userId, signer}: {userId: string; signer: ISigner}) => {
      const deviceToken = queryClient.getQueryData<string>([
        QueryKeys.notificationToken,
        userId,
      ]);

      if (deviceToken) {
        return removeDeviceToken(deviceToken, signer);
      }
    },
    {
      onSuccess: (data, variables) => {
        queryClient.removeQueries({
          queryKey: [QueryKeys.notificationToken, variables.userId],
          exact: true,
        });
      },
    },
  );
};
