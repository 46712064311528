import React, {ReactNode} from 'react';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './FeedCardActions.styles';
import Space from '@/components/Space';
import {IIconProps} from '@/components/Icon';
import IconButton from '@/components/IconButton';

interface IBaseFeedCardProps {
  actions: (IAction | null | ReactNode)[];
}

interface IActionProps {
  action: IAction | ReactNode;
}

export interface IAction {
  onPress: () => void;
  icon: IIconProps;
  disabled?: boolean;
}

const Action: React.FC<IActionProps> = ({action}) => {
  if ((action as IAction).icon) {
    const {onPress, icon, disabled} = action as IAction;
    return (
      <IconButton
        onPress={onPress}
        icon={icon}
        hitSlop="s"
        disabled={disabled}
      />
    );
  } else {
    return <>{action as ReactNode}</>;
  }
};

const FeedCardActions: React.FC<IBaseFeedCardProps> = ({actions}) => {
  const style = useThemedStyles(styles);

  return (
    <Space pt="xs" pb="xxs" style={style.actionsContainer}>
      {actions.map((action, index) => {
        if (!action) {
          return null;
        }

        return (
          <View key={index} style={style.action}>
            <Action action={action} />
          </View>
        );
      })}
    </Space>
  );
};

export default FeedCardActions;
