import {Chain} from 'viem';
import {
  mainnet,
  goerli,
  polygon,
  optimism,
  base,
  zora,
  sepolia,
} from 'viem/chains';

import {config} from '@/constants/config';

interface IChain {
  id: number;
  code: string;
  name: string;
  icon?: string;
  explorerUrl: string;
  rpcUrl: string;
  chain: Chain;
  jiffyscanCode?: string;
  isTestnet?: boolean;
  rampSymbol?: string;
}

interface IChainsById {
  [chainId: number]: IChain;
}

interface IChainsByCode {
  [chainByCode: string]: IChain;
}

interface IChainsByName {
  [chainByName: string]: IChain;
}

export const supportedChains: IChain[] = [
  {
    id: 10,
    code: 'optimism',
    icon: 'optimism',
    name: 'Optimism',
    explorerUrl: 'https://optimistic.etherscan.io/',
    rpcUrl: `https://opt-mainnet.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: optimism,
    jiffyscanCode: 'optimism',
    rampSymbol: 'OPTIMISM_ETH',
  },
  {
    id: 1,
    code: 'ethereum',
    icon: 'ethereum',
    name: 'Ethereum',
    explorerUrl: 'https://etherscan.io/',
    rpcUrl: `https://eth-mainnet.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: mainnet,
    jiffyscanCode: 'mainnet',
    rampSymbol: 'ETH_ETH',
  },
  {
    id: 137,
    code: 'polygon',
    icon: 'polygon',
    name: 'Polygon',
    explorerUrl: 'https://polygonscan.com/',
    rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: polygon,
    jiffyscanCode: 'matic',
    rampSymbol: 'POLYGONZKEVM_ETH',
  },
  {
    id: 8453,
    code: 'base',
    icon: 'base',
    name: 'Base',
    explorerUrl: 'https://basescan.org/',
    rpcUrl: `https://base-mainnet.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: base,
    jiffyscanCode: 'base',
    rampSymbol: 'BASE_ETH',
  },
  {
    id: 7777777,
    code: 'zora',
    icon: 'zora',
    name: 'Zora',
    explorerUrl: 'https://explorer.zora.energy/',
    rpcUrl: 'https://rpc.zora.energy',
    chain: zora,
  },
  {
    id: 11155111,
    code: 'sepolia',
    name: 'Sepolia',
    explorerUrl: 'https://sepolia.etherscan.io/',
    rpcUrl: `https://eth-sepolia.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: sepolia,
    jiffyscanCode: 'sepolia',
    rampSymbol: 'SEPOLIA_ETH',
    isTestnet: true,
  },
  {
    id: 5,
    code: 'goerli',
    name: 'Goerli',
    explorerUrl: 'https://goerli.etherscan.io/',
    rpcUrl: `https://eth-goerli.g.alchemy.com/v2/${config.ALCHEMY_API_KEY}`,
    chain: goerli,
    jiffyscanCode: 'goerli',
    isTestnet: true,
  },
];

export const productionChains = supportedChains.filter(c => !c.isTestnet);

export const chainsById: IChainsById = supportedChains.reduce(
  (result, chain) => ({
    ...result,
    [chain.id]: chain,
  }),
  {},
);

export const chainsByCode: IChainsByCode = supportedChains.reduce(
  (result, chain) => ({
    ...result,
    [chain.code]: chain,
  }),
  {},
);

export const chainsByName: IChainsByName = supportedChains.reduce(
  (result, chain) => ({
    ...result,
    [chain.name.toLowerCase()]: chain,
  }),
  {},
);

export const playlistMintableChains = ['optimism', 'ethereum', 'polygon'].map(
  code => ({
    value: code,
    label: chainsByCode[code].name,
  }),
);

export const getChainById = (chainId: number) => {
  const chain = chainsById[chainId];

  if (!chain) {
    throw new Error('Chain not supported');
  }

  return chain;
};
