import {v4 as uuidv4} from 'uuid';

import {INormalized} from '@/types/common';
import {Primitive, UnionToIntersection} from '@/types/utils';

export const getUniqueItems = <Type>(items: Type[]): Type[] => [
  ...new Set(items),
];

export const normalize = <Type>(
  inputArray: Type[],
  key: keyof Type,
): INormalized<Type> => {
  const normalized = inputArray.reduce<{
    ids: string[];
    items: {[key: string]: Type};
  }>(
    (result, item) => {
      const id = item[key];

      if (typeof id !== 'string') {
        return result;
      }

      return {
        ids: [...result.ids, id],
        items: {
          ...result.items,
          [id]: item,
        },
      };
    },
    {ids: [], items: {}},
  );

  return {
    items: normalized.items,
    ids: getUniqueItems(normalized.ids),
  };
};

export const getNormalizedList = <Type>({items, ids}: INormalized<Type>) =>
  ids.map(id => items[id]).filter(item => !!item);

export const groupBy = <ArrayType>(
  array: ArrayType[],
  key: keyof ArrayType,
) => {
  return array.reduce<{[group: string]: ArrayType[]}>((result, item) => {
    const groupKey = item[key];

    if (typeof groupKey === 'string') {
      return {
        ...result,
        [groupKey]: [...(result[groupKey] || []), item],
      };
    }

    return result;
  }, {});
};

export const sortStrings = (a?: string, b?: string) => {
  if (!a || !b) {
    return 0;
  }
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
};

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
export const removeAccent = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const insensitiveIncludes = (
  str: string | unknown,
  searchPhrase: string,
) => {
  // it fixes issue with occasionally broken data like missing artist's names
  if (typeof str !== 'string') {
    return false;
  }

  return removeAccent(str)
    .toLowerCase()
    .includes(removeAccent(searchPhrase).toLowerCase());
};

export const chunkArray = <Item>(
  array: Item[],
  itemsPerPage: number,
): Item[][] =>
  array.reduce<Item[][]>((accum, item, index) => {
    if (index % itemsPerPage === 0 && index !== array.length) {
      accum.push([]);
    }
    const lastItemPage = accum[accum.length - 1];
    lastItemPage.push(item);
    return accum;
  }, []);

export const flatChunkedArray = <Item>(chunkedArray: Item[][]) =>
  chunkedArray.reduce<Item[]>((result, chunk) => [...result, ...chunk], []);

// Creates unique hash for given string.
// It's used mostly for creating identifiers from remote audio urls for cache management.
export const createHash = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    let code = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + code;
    // eslint-disable-next-line no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
};

export const areArraysEqualFlat = <T>(array1: T[], array2: T[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((item, index) => item === array2[index]);
};

export const generateId = () => uuidv4();

export const isNotNil = <T>(x: T | undefined | null): x is T => {
  return x !== null && x !== undefined;
};

type Falsy = false | 0 | '' | null | undefined;

export const isTruthy = <T>(x: T | Falsy): x is Exclude<T, Falsy> => !!x;

export const areElementsShared = <T>(
  array1: T[],
  array2: T[],
  equalTo: (obj1: T, obj2: T) => boolean,
): boolean =>
  array1?.some(some => array2?.some(other => equalTo(some, other))) ?? false;

export const noop = (..._args: unknown[]) => {};

export const getLastItem = <T>(array: T[]) => array[array.length - 1];

export const matchesConditions = <T extends Record<string, Primitive>>(
  obj: T,
  conditions: Partial<T>,
) => Object.entries(conditions).every(([key, value]) => obj[key] === value);

export const omit = <T extends {[key: string]: any}>(
  value: T,
  ...fields: Array<keyof T>
): T => {
  const copy = {...value};
  fields.forEach(field => {
    delete copy[field];
  });
  return copy;
};

export const merge = <T extends {}[]>(
  ...objects: T
): UnionToIntersection<T[number]> => {
  return Object.assign({}, ...objects);
};

export const getUniqueItemsByFieldName = <T>(
  array: T[],
  fieldName: keyof T,
): T[] => {
  const uniqueMap = new Map<any, T>();

  for (const item of array) {
    const fieldValue = item[fieldName];
    if (!uniqueMap.has(fieldValue)) {
      uniqueMap.set(fieldValue, item);
    }
  }

  return Array.from(uniqueMap.values());
};

export const identity = <T>(x: T) => x;

export const sleep = (milliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, milliseconds));
