import {IIconProps} from '@/components/Icon';
import {config} from '@/constants/config';

interface IExternalLink {
  icon: IIconProps;
  url: string;
  textId?: string;
  text?: string;
}

interface IExternalLinksConfig {
  spinamp: IExternalLink[];
  contact: IExternalLink[];
}

export const externalLinks: IExternalLinksConfig = {
  spinamp: [
    {
      url: config.SPINAMP_FEEDBACK_URL,
      textId: 'menu.feedback',
      icon: {
        name: 'note',
        size: 16,
      },
    },
    {
      url: config.WEB_PAGE_URL,
      textId: 'menu.about',
      icon: {
        provider: 'custom',
        name: 'info',
        size: 18,
      },
    },
    {
      url: config.DEV_PAGE_URL,
      textId: 'menu.developers',
      icon: {
        provider: 'fontAwesome',
        name: 'code',
        size: 18,
      },
    },
  ],
  contact: [
    {
      url: config.SPINAMP_DISCORD_URL,
      text: 'Discord',
      icon: {
        provider: 'custom',
        name: 'discord',
      },
    },
    {
      url: config.SPINAMP_TWITTER_URL,
      text: 'X (Twitter)',
      icon: {
        provider: 'custom',
        name: 'twitter',
      },
    },
    {
      url: config.SPINAMP_WARPCAST_URL,
      text: 'Warpcast',
      icon: {
        provider: 'custom',
        name: 'warpcast',
      },
    },
  ],
};
