import {getFeedItemId} from 'api-utils';
import {useMemo} from 'react';

import {upsertFeedItem} from '@/api/feed';
import {useAppSelector} from '@/hooks/useRedux';
import {
  findFeedItemInQueriesData,
  mutateFeedItemInCache,
  useRawFeedQuery,
} from '@/queries/feed';
import {useToggleTrackInPlaylist} from '@/queries/ownedPlaylists';
import {queryClient} from '@/services/reactQuery';
import {store} from '@/store';
import {selectFavsPlaylist} from '@/store/playlists';
import {selectActiveUserId, selectSignerByUserId} from '@/store/user';
import {IFeedEntityType} from '@/types/feed';
import {QueryKeys} from '@/types/queryKeys';

interface IOptions {
  skipFeedQueryInvalidation: boolean;
  skipFeedSync: boolean;
}

const DEFAULT_OPTIONS = {
  skipFeedQueryInvalidation: false,
  skipFeedSync: false,
};

export const useFavToggle = (
  trackId?: string,
  options: IOptions = DEFAULT_OPTIONS,
) => {
  const favorites = useAppSelector(selectFavsPlaylist);
  const userId = useAppSelector(selectActiveUserId);

  const isFav = useMemo(
    () => !!trackId && !!favorites?.trackIds?.includes(trackId),
    [trackId, favorites],
  );

  const {toggleTrackInPlaylistAsyncMutation} = useToggleTrackInPlaylist();

  const toggleFav = async () => {
    if (favorites && trackId) {
      await toggleTrackInPlaylistAsyncMutation({
        playlistId: favorites.id,
        trackId: trackId,
      });
      toggleLikeFeedItem(userId, trackId, isFav, options);
    }
  };

  return {
    isFav,
    toggleFav,
  };
};

/**
 * This is a special version of useFavToggle with a different signature that allows using the hook when you don't know yet what the
 * trackId will be. You only need to pass the trackId when invoking the functions this hook returns.
 */
export const useFavToggleDynamic = (options: IOptions = DEFAULT_OPTIONS) => {
  const {toggleTrackInPlaylistAsyncMutation} = useToggleTrackInPlaylist();
  const favorites = useAppSelector(selectFavsPlaylist);
  const userId = useAppSelector(selectActiveUserId);

  const isTrackFaved = (trackId: string) =>
    !!trackId && !!favorites && favorites?.trackIds?.includes(trackId);

  const toggleFaveTrack = async (trackId: string) => {
    await toggleTrackInPlaylistAsyncMutation({
      playlistId: favorites.id,
      trackId: trackId,
    });

    toggleLikeFeedItem(userId, trackId, !!isTrackFaved(trackId), options);
  };

  return {
    isTrackFaved,
    toggleFaveTrack,
  };
};

const toggleLikeFeedItem = async (
  userId: string | undefined,
  trackId: string,
  isFav: boolean,
  options: IOptions = DEFAULT_OPTIONS,
) => {
  if (options.skipFeedSync) {
    return;
  }

  const userAction = isFav ? null : 'like';
  const mutationKey = [QueryKeys.feed, userId];

  await queryClient.cancelQueries({queryKey: mutationKey});

  const previousQueriesData = queryClient.getQueriesData<
    ReturnType<typeof useRawFeedQuery>['data']
  >({
    queryKey: mutationKey,
    exact: false,
  });

  const id = getFeedItemId({
    userId,
    entityType: IFeedEntityType.track,
    entityId: trackId,
  });
  const feedItem = findFeedItemInQueriesData(id, previousQueriesData);
  if (!feedItem) {
    return;
  }
  feedItem.userAction = userAction;

  mutateFeedItemInCache(feedItem, previousQueriesData).catch(() => {
    // If the mutation fails, we rollback to the previous queries data
    queryClient.setQueriesData(
      {
        queryKey: mutationKey,
        exact: false,
      },
      previousQueriesData,
    );
  });

  const signer = userId && selectSignerByUserId(store.getState(), userId);
  if (signer) {
    upsertFeedItem(
      {
        id,
        userId,
        entityType: IFeedEntityType.track,
        entityId: trackId,
        userAction,
        updatedAtTime: new Date().toISOString(),
      },
      signer,
    ).then(() => {
      // The feed keeps track faves in sync with feed item likes, so when we fave a track
      // the feed must be refetched because it might have changed
      if (!options.skipFeedQueryInvalidation) {
        queryClient.invalidateQueries([QueryKeys.feed, userId]);
      }
    });
  }
};
