import axios from 'axios';

import {IPurchaseParams, IRampPurchase, ITopUpParams} from '../types';
import {chainsById} from '@/constants/chains';
import {config} from '@/constants/config';

export const rampApi = axios.create({
  baseURL: config.RAMP_API_URL,
});

rampApi.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response),
);

export const getSharedRampParams = ({
  address,
  chainId,
  amount,
  userId,
  email,
}: ITopUpParams) => {
  const chainConfig = chainsById[chainId];

  if (!chainConfig.rampSymbol) {
    throw new Error('Top Up Error - chain not supported');
  }

  return {
    url: 'https://app.demo.ramp.network',
    hostApiKey: config.RAMP_API_KEY,
    hostAppName: 'Spinamp',
    hostLogoUrl: 'https://app.spinamp.xyz/spin-logo.png',
    enabledFlows: ['ONRAMP'],
    defaultFlow: 'ONRAMP',
    userAddress: address,
    swapAsset: chainConfig.rampSymbol,
    swapAmount: amount?.toString(),
    webhookStatusUrl: `${config.SPINAMP_BACKEND_URL}/ramp/webhook?userId=${userId}`,
    userEmailAddress: email,
  };
};

export const fetchTopUpStatus = ({
  id,
  token,
}: IPurchaseParams): Promise<IRampPurchase> =>
  rampApi.get(`purchase/${id}`, {params: {secret: token}});
