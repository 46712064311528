export const formatDuration = (_seconds: number) => {
  const seconds = Math.abs(_seconds);
  const longerThenOneHour = Math.floor(seconds / 3600) > 0;
  const startingIndex = longerThenOneHour ? 11 : 14;
  return new Date(seconds * 1000).toISOString().slice(startingIndex, 19);
};

export const formatDate = (inputDate: string | number): string => {
  const date = new Date(inputDate);
  return date.toLocaleDateString();
};

export const formatDateTime = (inputDate: string | number): string => {
  const date = new Date(inputDate);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
