import {useNavigation} from '@react-navigation/native';
import {createContext, FC, ReactNode, useState} from 'react';
import {encodeFunctionData} from 'viem';
import {useBalance} from 'wagmi';

import {useGasEstimation} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {ITransferParams} from '@/modules/Transfer';
import {TransferStep} from '@/modules/Transfer/types';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {getUserDeliveryWallet} from '@/utils/user';

type TransferModal =
  | undefined
  | 'walletPicker'
  | 'addExternalWallet'
  | 'addPasskeyWallet';

export interface ITransferStateContext {
  track: ITrack;
  user: IUser;
  senderWallet: IAddress;
  balance?: IBalance;
  deliveryWalletAddress?: string;
  deliveryWallet?: IAddress;
  setDeliveryWalletAddress: (address: string) => void;
  transferParams: ITransferParams;
  close: () => void;
  transferStep: TransferStep;
  setTransferStep: (step: TransferStep) => void;
  txHash: string | undefined;
  setTxHash: (txHash: string | undefined) => void;
  userOpHash: string | undefined;
  setUserOpHash: (txHash: string | undefined) => void;
  transactionError: any | undefined;
  setTransactionError: (error: any | undefined) => void;
  gasEstimation: IGasEstimation;
  openedModal: TransferModal;
  setOpenedModal: (modal: TransferModal) => void;
  closeModal: () => void;
  clearTransactionDetails: () => void;
}

export const TransferStateContext = createContext<
  ITransferStateContext | undefined
>(undefined);

interface IProps {
  user: IUser;
  track: ITrack;
  senderWallet: IAddress;
  transferParams: ITransferParams;
  children: ReactNode;
}

const TransferStateProvider: FC<IProps> = ({
  user,
  transferParams,
  track,
  senderWallet,
  children,
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();

  const [transferStep, setTransferStep] = useState<TransferStep>('checkout');
  const [txHash, setTxHash] = useState<string>();
  const [userOpHash, setUserOpHash] = useState<string>();
  const [transactionError, setTransactionError] = useState<any>();

  const [openedModal, setOpenedModal] = useState<TransferModal>(undefined);

  const [deliveryWalletAddress, setDeliveryWalletAddress] = useState(
    transferParams.to || getUserDeliveryWallet(user)?.address,
  );
  const deliveryWallet = user.addresses.find(a =>
    areAddressesEqual(a.address, deliveryWalletAddress),
  );

  const gasEstimation = useGasEstimation(
    {
      chainId: transferParams.chainId,
      wallet: senderWallet,
      transaction: {
        to: transferParams.contract,
        data: encodeFunctionData(getTransferWriteContractInput(transferParams)),
      },
    },
    transferStep === 'checkout',
  );

  const {data: balance} = useBalance({
    address: senderWallet.address,
    chainId: transferParams.chainId,
    watch: true,
  });

  return (
    <TransferStateContext.Provider
      value={{
        track,
        user,
        senderWallet,
        balance,
        deliveryWallet,
        deliveryWalletAddress,
        setDeliveryWalletAddress,
        transferParams: {
          ...transferParams,
          to: deliveryWallet?.address,
        },
        close: () => navigation.goBack(),
        transferStep,
        setTransferStep,
        txHash,
        setTxHash,
        userOpHash,
        setUserOpHash,
        transactionError,
        setTransactionError,
        gasEstimation,
        openedModal,
        setOpenedModal,
        closeModal: () => setOpenedModal(undefined),
        clearTransactionDetails: () => {
          setTxHash(undefined);
          setUserOpHash(undefined);
          setTransactionError(undefined);
        },
      }}>
      {children}
    </TransferStateContext.Provider>
  );
};

export default TransferStateProvider;
