import {createContext} from 'react';

import {SpinampWrappedPage} from '@/screens/SpinampWrapped/types';
import {IArtist, ITrack} from '@/types/common';
import {IBaseUser} from '@/types/user';

interface ISpinampWrappedContext {
  pages: {id: string; Component: any}[];
  page: number;
  close: () => void;
  goToPage: (page: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  tracks: {track: ITrack; count: number}[];
  artists: {artist: IArtist; count: number}[];
  supportedArtists: IArtist[];
  totalNftsCollected: number;
  activePageId: SpinampWrappedPage;
  user: IBaseUser | null;
}

export const SpinampWrappedContext = createContext<
  ISpinampWrappedContext | undefined
>(undefined);
