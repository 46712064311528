import SPACES from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  carousel: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: SPACES.xl,
  },
}));
