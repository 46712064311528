import {useIsMutating, useIsFetching} from '@tanstack/react-query';
import React from 'react';

import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text/Text';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppSelector} from '@/hooks/useRedux';
import {selectActiveUserId} from '@/store/user';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';

import Feed from './components/Feed';

const FeedScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const myUserId = useAppSelector(selectActiveUserId);

  const isGeneratingFeed = useIsMutating({
    mutationKey: [MutationKeys.feedGeneration],
  });
  const isFetchingFeed = useIsFetching({
    queryKey: [QueryKeys.feed, myUserId],
  });

  const isLoading = !!(isGeneratingFeed || isFetchingFeed);

  const renderContent = () => {
    if (!myUserId) {
      // Our user ID does not exist yet and we need it because we're not impersonating
      // another user. This can happen in two ways:
      // 1. the user launched the app while offline and has never been online
      // 2. the user is online for the first time but we haven't got them a spindexer user
      //   yet (eg in the first seconds of opening the app for the first time)
      return (
        <Space m="m">
          <Text id="feed.noUser" style={{textAlign: 'center'}} />
        </Space>
      );
    }

    return (
      <>
        <Header
          titleId="feed.title"
          showBack
          scrollPosition={scrollPosition}
          mockTitle={true}
          rightActions={isLoading && <SpinLoader />}
        />

        <Feed userId={myUserId} onScroll={onScroll} />
      </>
    );
  };

  return <Screen>{renderContent()}</Screen>;
};

export default FeedScreen;
