import React from 'react';

import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {ILocalMessageFeedItem} from '@/types/feed';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';
import OnboardingFeedCard from './OnboardingFeedCard';
import RefillFeedCard from './RefillFeedCard';

interface ILocalMessageFeedCardProps {
  feedItem: ILocalMessageFeedItem;
  isActive: boolean;
  scrollToTop: () => void;
}

const LocalMessageFeedCard: React.FC<ILocalMessageFeedCardProps> = ({
  feedItem,
  scrollToTop,
}) => {
  if (feedItem.localMessageId === 'onboarding0') {
    return <RefillFeedCard onboarding={true} />;
  }
  if (feedItem.localMessageId === 'onboarding1') {
    return <OnboardingFeedCard />;
  }
  if (feedItem.localMessageId === 'refill' && scrollToTop) {
    return <RefillFeedCard scrollToTop={scrollToTop} />;
  }

  return null;
};

interface IGenericMessageFeedItemProps {
  iconProps?: IIconProps;
  headingTextId?: string;
  bodyTextId?: string;
}

export const GenericLocalMessageFeedItem: React.FC<
  IGenericMessageFeedItemProps
> = ({iconProps, headingTextId, bodyTextId}) => {
  const style = useThemedStyles(styles);

  return (
    <BaseFeedCard>
      <Space p="s" style={style.featureContainer}>
        {iconProps && (
          <Space pb="m">
            <Icon style={style.featureIcon} {...iconProps} size={48} />
          </Space>
        )}
        {headingTextId && (
          <Space mb="xxs">
            <Text id={headingTextId} weight="bold" style={style.centeredText} />
          </Space>
        )}
        {bodyTextId && <Text id={bodyTextId} style={style.centeredText} />}
      </Space>
    </BaseFeedCard>
  );
};

export default LocalMessageFeedCard;
