import React from 'react';

import {useTransferState} from '@/modules/Transfer/useTransferState';
import {
  WalletPicker,
  AddExternalWalletModal,
  AddPasskeyWalletModal,
} from '@/modules/Wallets';

const TransferModals = () => {
  const {
    user,
    transferParams,
    openedModal,
    setOpenedModal,
    closeModal,
    deliveryWallet,
    setDeliveryWalletAddress,
  } = useTransferState();

  if (openedModal === 'walletPicker') {
    return (
      <WalletPicker
        titleId="transfer.walletPicker.title"
        isOpen
        user={user}
        selectedWalletAddress={deliveryWallet?.address}
        excludedAddress={transferParams.from}
        onConfirm={setDeliveryWalletAddress}
        chainId={transferParams.chainId}
        onClose={closeModal}
        onAddExternalWallet={() => setOpenedModal('addExternalWallet')}
      />
    );
  }

  if (openedModal === 'addPasskeyWallet') {
    return (
      <AddPasskeyWalletModal
        isOpen
        onClose={() => setOpenedModal('walletPicker')}
        onSuccess={setDeliveryWalletAddress}
      />
    );
  }

  if (openedModal === 'addExternalWallet') {
    return (
      <AddExternalWalletModal
        isOpen
        onClose={() => setOpenedModal('walletPicker')}
        onSuccess={setDeliveryWalletAddress}
      />
    );
  }

  return null;
};

export default TransferModals;
