import React, {FC, useMemo} from 'react';
import {View, ViewStyle} from 'react-native';
import {Svg, Rect} from 'react-native-svg';

const Color = require('color');
const MersenneTwister = require('mersenne-twister');

const COLOR_SET = [
  '#01888C',
  '#FC7500',
  '#034F5D',
  '#F73F01',
  '#FC1960',
  '#C7144C',
  '#F3C100',
  '#1598F2',
  '#2465E1',
  '#F19E02',
];

const wobble = 30;
const shapeCount = 3;

interface IProps {
  address: string;
  size: number;
  style?: ViewStyle;
}

const Jazzicon: FC<IProps> = ({address, size, style}) => {
  const generators = useMemo(() => {
    const seed = parseInt(address.toLowerCase().slice(2, 10), 16);
    const generator = new MersenneTwister(seed);
    const amount = generator.random() * wobble - wobble / 2;
    const colors = COLOR_SET.map(hex => new Color(hex).rotate(amount).hex());

    const randomNumber = () => generator.random();
    const randomColor = () =>
      colors.splice(Math.floor(colors.length * randomNumber()), 1)[0];

    return {
      randomNumber,
      randomColor,
    };
  }, [address]);

  const svg = useMemo(
    () => (
      <Svg width={size} height={size}>
        {Array(shapeCount)
          .fill(0)
          .map((_, index) => {
            const center = size / 2;

            const firstRot = generators.randomNumber();
            const angle = Math.PI * 2 * firstRot;
            const velocity =
              (size / shapeCount) * generators.randomNumber() +
              (index * size) / shapeCount;

            const tx = Math.cos(angle) * velocity;
            const ty = Math.sin(angle) * velocity;

            const secondRot = generators.randomNumber();
            const rot = firstRot * 360 + secondRot * 180;

            return (
              <Rect
                key={`shape_${index}`}
                x={0}
                y={0}
                width={size}
                height={size}
                fill={generators.randomColor()}
                transform={`translate(${tx} ${ty}) rotate(${rot.toFixed(
                  1,
                )} ${center} ${center})`}
              />
            );
          })}
      </Svg>
    ),
    [generators, size],
  );

  return (
    <View
      style={[
        {
          overflow: 'hidden',
          width: size,
          height: size,
          backgroundColor: generators.randomColor(),
          borderRadius: size / 2,
        },
        style,
      ]}>
      {svg}
    </View>
  );
};

export default React.memo(Jazzicon);
