import {useCallback} from 'react';
import {
  useAnimatedScrollHandler,
  useSharedValue,
} from 'react-native-reanimated';

export const useAnimatedHeader = () => {
  const scrollPosition = useSharedValue(0);
  const onScroll = useAnimatedScrollHandler(event => {
    scrollPosition.value = event.contentOffset.y;
  });
  // that handler should be passed to DraggableFlatList
  const onScrollOffsetChange = useCallback(
    (y: number) => (scrollPosition.value = y),
    [],
  );

  return {
    scrollPosition,
    onScroll,
    onScrollOffsetChange,
  };
};

export type OnScroll = ReturnType<typeof useAnimatedHeader>['onScroll'];
