import {useIsFocused} from '@react-navigation/native';
import {useEffect} from 'react';

import {IHotkeyMap} from './types';

export const useHotkeys = (hotkeyMap: IHotkeyMap) => {
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    const listener = (event: KeyboardEvent) => {
      const hotkey = hotkeyMap.find(h => h.key === event.key);

      if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
        return;
      }

      hotkey?.onPress();
    };

    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, [hotkeyMap, isFocused]);
};
