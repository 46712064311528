import React from 'react';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import Space from '@/components/Space';
import {useThemedStyles} from '@/theme';
import Text from '@/components/Text';
import {styles} from './common.styles';
import BaseFeedCard from './BaseFeedCard';
import {useAppDispatch} from '@/hooks/useRedux';
import {setOnboardingInfo} from '@/store/user';
import FeedCardActions, {IAction} from './FeedCardActions';

interface IProps {}

const OnboardingFeedCard: React.FC<IProps> = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setOnboardingInfo({key: 'showFeedOnboarding1', value: false}));
  };

  const dummyActions: IAction[] = [
    {
      onPress: () => {},
      icon: {
        provider: 'custom',
        name: 'visibilityOff',
        size: 22,
      },
      disabled: true,
    },
    {
      onPress: () => {},
      icon: {
        provider: 'custom',
        name: 'play',
        size: 22,
      },
      disabled: true,
    },
    {
      onPress: onClose,
      icon: {
        name: 'heart',
        provider: 'custom',
        fill: false,
        color: 'textColor',
        size: 19,
      },
    },
  ];

  return (
    <BaseFeedCard>
      <View style={style.imageContainer}>
        <Space p="s" style={style.featureContainer}>
          <Icon
            name="excited"
            provider="custom"
            style={style.featureIcon}
            size={48}
          />
          <Space pb="m" />
          <Text
            id="feed.onboarding1.title"
            weight="bold"
            style={style.centeredText}
          />
          <Space mb="xxs" />
          <Text id="feed.onboarding1.body" style={style.centeredText} />
        </Space>
      </View>

      <View style={style.infoContainer}>
        <Space flex pt="xs" style={style.actionLabels}>
          <Text id="feed.hide" />
          <Text id="feed.play" />
          <Text id="feed.like" />
        </Space>

        <FeedCardActions actions={dummyActions} />
      </View>
    </BaseFeedCard>
  );
};

export default OnboardingFeedCard;
