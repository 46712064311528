import {useIsFocused} from '@react-navigation/native';
import {useEffect, useRef, useState} from 'react';

import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {initAdditionalAudio} from '@/services/additionalAudio';
import {IAdditionalAudio} from '@/services/additionalAudio/types';
import {selectShouldPlay as selectFeedShouldPlay} from '@/store/feed';
import {
  pause as pauseMainPlayer,
  setIsActive as setMainPlayerIsActive,
} from '@/store/player';
import {ITrack} from '@/types/common';

export const useMainPlayerWithFeed = () => {
  const dispatch = useAppDispatch();

  const isFocused = useIsFocused();
  const feedShouldPlay = useAppSelector(selectFeedShouldPlay);
  useEffect(() => {
    if (isFocused && feedShouldPlay) {
      dispatch(setMainPlayerIsActive(false));
      return () => {
        dispatch(setMainPlayerIsActive(true));
      };
    }
  }, [isFocused, feedShouldPlay]);
};

export const useFeedAudio = (
  track: ITrack | undefined,
  isActive: boolean,
  onEnded: () => void,
) => {
  const dispatch = useAppDispatch();
  const player = useRef<IAdditionalAudio>();
  const isFocused = useIsFocused();
  const feedShouldPlay = useAppSelector(selectFeedShouldPlay);
  const [isBuffering, setIsBuffering] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (track) {
      setCurrentTime(track.chorusStart || 0);
      player.current = initAdditionalAudio({
        uri: track.lossyAudioUrl,
        startTimeInSeconds: track.chorusStart,
        shouldLoop: false,
        setIsBuffering,
        setCurrentTime,
        setDuration,
        onEnded,
      });

      return () => {
        player.current?.destroy();
      };
    }
  }, [track?.lossyAudioUrl]);

  useEffect(() => {
    if (isActive) {
      player.current?.load();
    }
  }, [isActive, track?.id]);

  useEffect(() => {
    if (isActive && isFocused && feedShouldPlay) {
      dispatch(pauseMainPlayer());
      player.current?.play();
    } else {
      player.current?.pause();
    }
  }, [isActive, isFocused, feedShouldPlay, track?.id]);

  return {
    isPlaying: isActive && isFocused && feedShouldPlay && !!track,
    isBuffering,
    progress: {
      currentTime,
      duration,
      seekTo: player.current?.seekTo,
    },
  };
};
