import React from 'react';
import {useAppSelector} from '@/hooks/useRedux';
import {selectCurrentScreen} from '@/store/navigation';
import {useUpdateEffect} from '@/hooks/useUpdateEffect';
import {IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import ArtistModal from '../FeedCard/InfoLinks/Modals/ArtistModal';
import TrackModal from '../FeedCard/InfoLinks/Modals/TrackModal';

interface IDetailsModal {
  feedItem: IFeedItem | ILocalMessageFeedItem;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const DetailsModal: React.FC<IDetailsModal> = props => {
  const {feedItem, isOpen, setIsOpen} = props;

  const currentScreen = useAppSelector(selectCurrentScreen);
  useUpdateEffect(() => {
    setIsOpen(false);
  }, [currentScreen]);

  switch (feedItem.entityType) {
    case 'track':
      return (
        <TrackModal
          track={feedItem.track}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
    case 'artist':
      return (
        <ArtistModal
          artist={feedItem.artist}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
    default:
      return null;
  }
};

export default DetailsModal;
