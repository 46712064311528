import TransferABI from '@/abi/TransferABI.json';
import {ITransferParams} from '@/modules/Transfer';
import {AddressString} from '@/types/common';

export const areParamsValid = ({
  tokenId,
  tokenType,
  contract,
  chainId,
  from,
}: ITransferParams) => {
  return Boolean(
    from &&
      chainId &&
      contract &&
      tokenId &&
      ['erc721', 'erc1155'].includes(tokenType),
  );
};

export const getTransferWriteContractInput = (
  transferParams: ITransferParams,
) => {
  const tokenType = transferParams.tokenType as 'erc721' | 'erc1155';

  if (!['erc721', 'erc1155'].includes(tokenType)) {
    throw new Error('Token standard not supported ');
  }

  const address = transferParams.contract as AddressString;

  const params = {
    erc721: {
      abi: TransferABI.erc721,
      address,
      functionName: 'transferFrom',
      args: [
        transferParams.from,
        transferParams.to,
        BigInt(transferParams.tokenId),
      ],
    },
    erc1155: {
      abi: TransferABI.erc1155,
      address,
      functionName: 'safeTransferFrom',
      args: [
        transferParams.from,
        transferParams.to,
        BigInt(transferParams.tokenId),
        1,
        0x00,
      ],
    },
  };

  return params[tokenType];
};
