import {Slider} from '@miblanchard/react-native-slider';
import {useNavigation} from '@react-navigation/native';
import React, {ReactNode, useMemo, useState} from 'react';
import {View} from 'react-native';

import ImageLoader from '@/components/DynamicImage/ImageLoader';
import TransparentButton from '@/components/TransparentButton';
import {useAppSelector, useAppDispatch} from '@/hooks/useRedux';
import {
  selectShouldPlay,
  setShouldPlay as setFeedShouldPlay,
} from '@/store/feed';
import {useTheme, useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IFeedItem} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './AudioFeedCard.styles';
import BaseFeedCard from './BaseFeedCard';
import FeedCardActions, {IAction} from './FeedCardActions';
import {useFeedAudio} from './useFeedAudio';

export interface IProgress {
  currentTime: number;
  duration: number;
  seekTo: (time: number) => void;
}

interface IFeedCardProps {
  feature?: ReactNode;
  heading?: ReactNode;
  feedItem: IFeedItem;
  track: ITrack | undefined;
  isActive: boolean;
  toggleLike?: (feedItem: IFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

export const AudioFeedCard: React.FC<IFeedCardProps> = ({
  feature,
  heading,
  feedItem,
  track,
  isActive,
  toggleLike,
  toggleHide,
  next,
  setCarouselEnabled,
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const style = useThemedStyles(styles);
  const theme = useTheme();

  const isLiked = feedItem.userAction === 'like';
  const isHidden = feedItem.userAction === 'hide';

  const {isPlaying, isBuffering, progress} = useFeedAudio(
    track,
    isActive,
    next,
  );

  const likeAction = useMemo(
    () =>
      toggleLike
        ? ({
            onPress: () => toggleLike(feedItem),
            icon: {
              name: 'heart',
              provider: 'custom',
              fill: isLiked,
              color: isLiked ? 'favoritesColor' : 'textColor',
              size: 19,
            },
          } satisfies IAction)
        : null,
    [toggleLike, isLiked],
  );

  const showCollectButton = useMemo(() => {
    return isLiked && track?.id;
  }, [isLiked, track?.id]);

  const hideAction = useMemo(
    () =>
      toggleHide && !showCollectButton
        ? ({
            onPress: () => toggleHide(feedItem),
            icon: {
              provider: 'custom',
              name: isHidden ? 'visibility' : 'visibilityOff',
              size: 22,
            },
          } satisfies IAction)
        : null,
    [toggleHide, isHidden, showCollectButton],
  );

  const collectAction = useMemo(() => {
    if (showCollectButton) {
      return (
        <View style={style.collectButton}>
          <TransparentButton
            onPress={() =>
              navigation.navigate(Routes.Collect, {slug: track!.slug})
            }
            text={{id: 'buyButton.collect', size: 'xs'}}
            icon={{
              name: 'nftMint',
              provider: 'custom',
              size: 18,
            }}
          />
        </View>
      );
    }
  }, [showCollectButton, track?.id]);

  const feedShouldPlay = useAppSelector(selectShouldPlay);
  const dispatch = useAppDispatch();

  const playAction = useMemo(
    () =>
      ({
        onPress: () => {
          dispatch(setFeedShouldPlay(!feedShouldPlay));
        },
        icon: {
          provider: 'custom',
          name: isPlaying ? 'pause' : 'play',
          size: 22,
        },
        disabled: !track,
      } satisfies IAction),
    [feedShouldPlay, isPlaying],
  );

  const actions = [collectAction, hideAction, playAction, likeAction];

  const [draggingValue, setDraggingValue] = useState<number | undefined>(
    undefined,
  );
  const value =
    draggingValue !== undefined ? draggingValue : progress.currentTime;

  return (
    <BaseFeedCard>
      {feature && <View style={style.imageContainer}>{feature}</View>}
      {progress && (
        <View style={style.progressContainer}>
          <Slider
            trackStyle={style.progress}
            containerStyle={style.hitSlopContainer}
            minimumValue={0}
            maximumValue={progress.duration}
            minimumTrackTintColor={theme.colors.primary}
            value={value}
            onValueChange={([value]) => setDraggingValue(value)}
            renderThumbComponent={() => <View />}
            onSlidingStart={() => setCarouselEnabled(false)}
            onSlidingComplete={([value]) => {
              setCarouselEnabled(true);
              progress.seekTo?.(value);
              setDraggingValue(undefined);
            }}
            trackClickable={true}
          />
        </View>
      )}
      <ImageLoader isLoaded={!isBuffering} spinnerSize={30} />

      <View style={style.infoContainer}>
        {heading}
        <FeedCardActions actions={actions} />
      </View>
    </BaseFeedCard>
  );
};
