import React from 'react';
import {View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import ImageWithPreview from '@/components/ImageWithPreview';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {IFeedItem, IFeedItemWithTrack} from '@/types/feed';
import {ImageSize} from '@/types/media';
import {getTrackArtists} from '@/utils/tracks';

import {AudioFeedCard} from './AudioFeedCard';
import {styles} from './TrackFeedCard.styles';
import TrackTitle from './InfoLinks/TrackTitle';
import ArtistName from './InfoLinks/ArtistName';

interface IProps {
  feedItem: IFeedItemWithTrack;
  toggleLike?: (feedItem: IFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

const TrackFeedCard: React.FC<IProps> = ({
  feedItem,
  toggleHide,
  toggleLike,
  isActive,
  next,
  setCarouselEnabled,
}) => {
  const style = useThemedStyles(styles);
  const {track} = feedItem;

  const ArtistNames = (
    <View style={style.subtitle}>
      {getTrackArtists(track).map((a, _index) => (
        <React.Fragment key={_index}>
          {_index > 0 && (
            <View style={style.artistJoiner}>
              <Text size="m" numberOfLines={1}>
                &
              </Text>
            </View>
          )}
          <ArtistName artist={a} />
        </React.Fragment>
      ))}
    </View>
  );

  const Title = <TrackTitle track={track} />;

  return (
    <AudioFeedCard
      feature={
        <ImageWithPreview
          resizeWidth={ImageSize.feedCard}
          source={{uri: track.lossyArtworkUrl}}
          style={style.image}
          resizeMode="cover"
          hasBorderRadius
          fallback={<FadeInOut enabled style={style.imagePlaceholder} />}
        />
      }
      heading={
        <>
          {Title}
          {ArtistNames}
        </>
      }
      feedItem={feedItem}
      track={track}
      toggleHide={toggleHide}
      toggleLike={toggleLike}
      isActive={isActive}
      next={next}
      setCarouselEnabled={setCarouselEnabled}
    />
  );
};

export default TrackFeedCard;
