import {useEffect} from 'react';

import {useIsFocused} from '@react-navigation/native';

const useOnUnfocus = (onUnfocus: (isFocused: boolean) => void) => {
  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) {
      onUnfocus(isFocused);
    }
  }, [isFocused, onUnfocus]);
};

export default useOnUnfocus;
