import {PlayContextSource, PlayContextType} from '@/types/common';
import {IPlaybackEventPayload, TrackPlayedTrigger} from '@/types/events';
import {IFeedEntityType, IFeedUserAction} from '@/types/feed';
import {Routes} from '@/types/routes';
import mixpanel from '@/utils/mixpanel';

export const analytics = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  applicationLoaded: () => {
    mixpanel.track('App loaded');
  },
  platformLinkPressed: (screen: Routes, id: string) => {
    mixpanel.track('Platform link pressed', {
      screen,
      id,
    });
  },
  themeEditVisited: (
    screen: Routes.CollectorThemeEdit | Routes.ArtistThemeEdit,
    id: string,
  ) => {
    mixpanel.track('Theme edit visited', {
      screen,
      id,
    });
  },
  feedItemReacted: (
    id: string,
    userAction: IFeedUserAction,
    entityType: IFeedEntityType,
  ) => {
    mixpanel.track('feed_item_reacted', {
      id,
      userAction,
      entityType,
    });
  },
  feedGenerationRequested: () => {
    mixpanel.track('feed_generation_requested');
  },
  trackPlaybackStarted: (
    trackId: string,
    source: PlayContextSource,
    playContextType: PlayContextType,
    trigger?: TrackPlayedTrigger,
  ) => {
    mixpanel.track('track_playback_started', {
      trackId,
      source,
      playContextType,
      trigger,
    });
  },
  trackPlaybackCompleted: (
    trackId: string,
    source: PlayContextSource,
    playContextType: PlayContextType,
    trigger?: TrackPlayedTrigger,
  ) => {
    mixpanel.track('track_playback_completed', {
      trackId,
      source,
      playContextType,
      trigger,
    });
  },
  trackPlaylisted: (trackId: string, playlistId: string) => {
    mixpanel.track('track_playlisted', {trackId, playlistId});
  },
  trackUnplaylisted: (trackId: string, playlistId: string) => {
    mixpanel.track('track_unplaylisted', {trackId, playlistId});
  },
  trackFaved: (trackId: string) => {
    mixpanel.track('track_faved', {trackId});
  },
  trackUnfaved: (trackId: string) => {
    mixpanel.track('track_faved', {trackId});
  },
  artistFollowed: (artistId: string) => {
    mixpanel.track('artist_followed', {artistId});
  },
  artistUnfollowed: (artistId: string) => {
    mixpanel.track('artist_unfollowed', {artistId});
  },
  playlistFollowed: (playlistId: string) => {
    mixpanel.track('playlist_followed', {playlistId});
  },
  playlistUnfollowed: (playlistId: string) => {
    mixpanel.track('playlist_unfollowed', {playlistId});
  },
  collectionFollowed: (collectionAddress: string) => {
    mixpanel.track('collection_followed', {collectionAddress});
  },
  collectionUnfollowed: (collectionAddress: string) => {
    mixpanel.track('collection_unfollowed', {collectionAddress});
  },
  approvalTransactionSent: (transactionHash: string, trackId: string) => {
    mixpanel.track('approval_transaction_sent', {transactionHash, trackId});
  },
  mintTransactionSent: (
    transactionHash: string,
    trackId: string,
    value?: string,
  ) => {
    mixpanel.track('mint_transaction_sent', {transactionHash, trackId, value});
  },
  mintSuccess: (transactionHash: string, trackId: string, value?: string) => {
    mixpanel.track('mint_success', {transactionHash, trackId, value});
  },
  mintFailure: (transactionHash: string, trackId: string, value?: string) => {
    mixpanel.track('mint_failure', {transactionHash, trackId, value});
  },
};

export const trackPlaybackEvent = (playbackEvent: IPlaybackEventPayload) => {
  switch (playbackEvent.eventName) {
    case 'TrackPlayed': {
      analytics.trackPlaybackStarted(
        playbackEvent.trackId,
        playbackEvent.context.source,
        playbackEvent.context.type,
        playbackEvent.additionalData?.trigger,
      );
      break;
    }
    case 'TrackEnded': {
      analytics.trackPlaybackCompleted(
        playbackEvent.trackId,
        playbackEvent.context.source,
        playbackEvent.context.type,
        playbackEvent.additionalData?.trigger,
      );
      break;
    }
  }
};
