import React, {FC, ReactNode} from 'react';
import {createPortal} from 'react-dom';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './ToastContainer.style';

interface IProps {
  children: ReactNode;
}

const ToastContainer: FC<IProps> = ({children}) => {
  const style = useThemedStyles(styles);

  return createPortal(
    <View style={style.container}>{children}</View>,
    document.body,
  );
};

export default ToastContainer;
