import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    gap: spacing.l,
    flexGrow: 1,
  },
  header: {
    alignSelf: 'center',
    gap: spacing.xxs / 2,
  },
  sectionTitle: {
    marginBottom: spacing.xs,
  },
  deliveryWalletCardAction: {
    paddingHorizontal: spacing.xs * 1.5,
  },
  pendingTransaction: {
    flex: 1,
    minHeight: 100,
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
  },
  links: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.m,
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
  footer: {
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.xs * 1.5,
    gap: spacing.xs,
  },
  networkInfoContainer: {
    gap: spacing.xxs / 2,
  },
  networkRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
  },
  balance: {
    justifyContent: 'flex-end',
  },
  validationError: {
    marginVertical: spacing.xs,
  },
}));
