import {Link, StackActions} from '@react-navigation/native';
import React, {FC} from 'react';
import {FormattedRelativeTime} from 'react-intl';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import CollectorLink from '@/components/NftEvent/CollectorLink';
import NftEventAvatars from '@/components/NftEvent/NftEventAvatars';
import NftEventMessage from '@/components/NftEvent/NftEventMessage';
import {useEventInfo} from '@/components/NftEvent/useEventInfo';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';
import {INftEventWithTracksInfo} from '@/types/nfts';
import {Routes} from '@/types/routes';

import {AVATAR_SIZE, ICON_SIZE, styles} from './GlobalNftEvent.style';

interface IProps {
  event: INftEventWithTracksInfo;
}

const NftEvent: FC<IProps> = ({event}) => {
  const style = useThemedStyles(styles);
  const {sender, receiver, icon, relativeTimestamp} = useEventInfo(event);
  const eventMainUser = sender || receiver;
  const {track, totalCount} = event.tracksInfo;
  const trackLinkConfig = {
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Track,
        params: {slug: track?.slug},
      },
    },
    action: StackActions.push(Routes.Track, {slug: track?.slug}),
  };
  const artistLinkConfig = {
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Artist,
        params: {slugOrId: track?.artist.slug},
      },
    },
    action: StackActions.push(Routes.Artist, {slugOrId: track?.artist.slug}),
  };

  return (
    <Space style={style.container}>
      <NftEventAvatars size={AVATAR_SIZE} track={track} />
      <Space w="xs" />
      <Space style={style.content}>
        <View style={style.contentHeader}>
          {totalCount > 1 ? (
            <Text
              size="xs"
              id="nftEvent.artist.variousTracks"
              values={{count: totalCount}}
            />
          ) : track ? (
            <View style={style.trackInfo}>
              <Text
                size="xs"
                numberOfLines={1}
                id="nftEvent.collector.trackInfo"
                values={{
                  artist: (
                    <Link {...artistLinkConfig}>
                      <Text size="xs" weight="semibold">
                        {track.artist.name}
                      </Text>
                    </Link>
                  ),
                  track: <Link {...trackLinkConfig}>{track.title}</Link>,
                }}
              />
            </View>
          ) : (
            <Text size="xs" id="nftEvent.upcomingNft" />
          )}
          <Space w="s" />
          <Text size="xxs">
            <FormattedRelativeTime
              value={relativeTimestamp}
              updateIntervalInSeconds={10}
            />
          </Text>
        </View>
        {eventMainUser && <CollectorLink collector={eventMainUser} />}
        <Space h="xxs" />
        <View style={style.contentBody}>
          {icon && (
            <Space mr="xs" style={style.iconContainer}>
              <Icon size={ICON_SIZE} {...icon} />
            </Space>
          )}
          <View style={style.contentDescription}>
            <NftEventMessage event={event} counterparty={receiver} />
          </View>
        </View>
      </Space>
    </Space>
  );
};

export default React.memo(NftEvent);
