import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const BUTTON_SIZE = spacing.xl - spacing.xxs;

export const styles = createThemedStyles(() => ({
  listContainer: {
    flexGrow: 1,
    padding: spacing.s,
    paddingTop: spacing.xxs,
  },
  header: {
    flexDirection: 'row',
  },
  coverArtCard: {
    width: '33%',
    aspectRatio: 1,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  coverArt: {
    ...StyleSheet.absoluteFillObject,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.xs,
  },
  action: {
    height: BUTTON_SIZE,
    paddingHorizontal: spacing.xs,
  },
  actionIcon: {
    width: BUTTON_SIZE,
    paddingHorizontal: 0,
  },
  collectButtonWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  collectButton: {
    flex: 1,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filtersContainer: {
    marginHorizontal: -spacing.s,
  },
  filters: {
    paddingTop: spacing.xs,
    paddingBottom: spacing.s,
  },
}));
