import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContent: {
    flexGrow: 1,
  },
  content: {
    paddingVertical: spacing.l * 2,
    gap: spacing.l,
    flex: 1,
  },
  textsContainer: {
    gap: spacing.xxs,
    paddingHorizontal: spacing.s,
  },
  transactionLinks: {
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
  },
  links: {
    flexDirection: 'row',
    gap: spacing.m,
    alignItems: 'center',
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
}));
