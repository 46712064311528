import React, {useState} from 'react';
import Text from '@/components/Text';

import {ITrack} from '@/types/common';
import Animated from 'react-native-reanimated';
import {Pressable} from 'react-native';
import TrackModal from './Modals/TrackModal';
import {useThemedStyles} from '@/theme';
import {styles} from './common.styles';
const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

interface ITrackTitle {
  track: ITrack;
}

const TrackTitle: React.FC<ITrackTitle> = props => {
  const {track} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const style = useThemedStyles(styles);

  return (
    <>
      <AnimatedPressable
        onPress={() => setIsOpen(true)}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}>
        <Text
          size="m"
          weight="semibold"
          style={style.title}
          underline={isHovered}
          numberOfLines={2}
          selectable={false}>
          {track.title}
        </Text>
      </AnimatedPressable>
      {isOpen && (
        <TrackModal track={track} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default TrackTitle;
