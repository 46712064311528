import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.activeBorder,
    borderRadius: theme.box.borderRadius,
    paddingVertical: 0,

    maxWidth: 300,
    width: '100%',

    flex: 1,
  },
  flexContainer: {
    height: 400,
    width: '100%',

    flexDirection: 'column',
  },
  imageContainer: {
    height: '100%',
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden',

    borderTopRightRadius: Math.max(0, theme.box.borderRadius - 3),
    borderTopLeftRadius: Math.max(0, theme.box.borderRadius - 3),
  },
  imagePlaceholder: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: Math.max(0, theme.box.borderRadius - 3),
    backgroundColor: theme.colors.backgroundDark,
  },
  infoContainer: {
    paddingHorizontal: spacing.xs,
    paddingTop: spacing.s,
    alignItems: 'flex-start',

    borderTopWidth: theme.box.baseBorderWidth,
    borderColor: theme.colors.borderColor,
  },

  subtitle: {
    maxWidth: '100%',
    flexDirection: 'row',
  },
  artistJoiner: {
    marginHorizontal: spacing.xxs,
  },
  image: {
    maxHeight: '100%',
    flexGrow: 1,
  },
  actionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
  },
}));
