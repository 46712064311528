import API from '@/services/spinampApi';
import {ISigner} from '@/types/session';
import {getSignedRequestBody} from '@/utils/api';

export const saveDeviceToken = async (token: string, signer: ISigner) => {
  const body = await getSignedRequestBody({token}, signer);
  return API.post('/deviceTokens', body);
};

export const removeDeviceToken = async (token: string, signer: ISigner) => {
  const body = await getSignedRequestBody(
    {action: `delete deviceToken ${token}`},
    signer,
  );
  return API.delete(`/deviceTokens/${token}`, {data: body});
};
