import {isHex} from 'viem';

import {APPLE_REVIEWER_ACCOUNT} from '@/constants/appleReview';
import {TransactionError} from '@/modules/Collect/errors';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {analytics} from '@/utils/analytics';
import {
  areAddressesEqual,
  getChainClient,
  getTokenFromLogs,
} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

const getPasskeyPrivateKey = (credentialId?: string) => {
  if (credentialId === APPLE_REVIEWER_ACCOUNT.credentialId) {
    return Promise.resolve({privateKey: APPLE_REVIEWER_ACCOUNT.privateKey});
  }

  return Passkey.get(credentialId);
};

export const useCollectTransaction = (offer: IMintOfferInfo) => {
  const {
    paymentWallet,
    setTxHash,
    setApprovalTxHash,
    setUserOpHash,
    setApprovalUserOpHash,
    setTransactionError,
    setTransactionStep,
    setToken,
    collectInfoQuery,
    resetTxDetails,
    track,
  } = useCollectState();
  const {sendTransaction: sendExternalTransaction} = useExternalSigner();

  const collectWithPasskeyWallet = async (passkeyWallet: IAddress) => {
    setTransactionStep('waitingForPasskey');
    const {credentialId, signer} = passkeyWallet.metadata?.spinampWallet || {};
    const {privateKey} = await getPasskeyPrivateKey(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    const {mintTransaction, approvalTransaction, chainId} = offer;

    if (approvalTransaction) {
      setTransactionStep('approvalTransaction');
      const approvalUserOpHash = await sendTransaction({
        privateKey,
        chainId,
        transaction: {
          to: approvalTransaction.to,
          data: approvalTransaction.data,
        },
      });
      setApprovalUserOpHash(approvalUserOpHash);

      const {status, transactionHash: approvalTxHash} = await waitForReceipt({
        privateKey,
        chainId,
        hash: approvalUserOpHash,
      });

      setApprovalTxHash(approvalTxHash);

      analytics.approvalTransactionSent(approvalTxHash, track.id);

      if (status !== 'success') {
        throw new TransactionError('APPROVAL_TRANSACTION_REVERTED', {
          chainId,
          txHash: approvalTxHash,
        });
      }
    }

    setTransactionStep('mainTransaction');
    const userOpHash = await sendTransaction({
      privateKey,
      chainId,
      transaction: {
        to: mintTransaction.to,
        data: mintTransaction.data,
        value: BigInt(mintTransaction.value || 0),
      },
    });
    setUserOpHash(userOpHash);

    const receipt = await waitForReceipt({
      privateKey,
      chainId,
      hash: userOpHash,
    });
    const {status, transactionHash: txHash} = receipt;

    setTxHash(txHash);
    analytics.mintTransactionSent(txHash, track.id, mintTransaction.value);

    if (status !== 'success') {
      analytics.mintFailure(txHash, track.id, mintTransaction.value);
      throw new TransactionError('TRANSACTION_REVERTED', {chainId, txHash});
    }

    // receipt.logs as an because type incompatibilities between viem and permissionless
    setToken(getTokenFromLogs(receipt.logs as any, receipt.transactionHash));

    analytics.mintSuccess(txHash, track.id, mintTransaction.value);
  };

  const collectWithExternalWallet = async () => {
    if (!sendExternalTransaction) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    const {mintTransaction, approvalTransaction, chainId} = offer;

    if (approvalTransaction) {
      setTransactionStep('approvalTransaction');
      const approvalTxHash = await sendExternalTransaction({
        to: approvalTransaction.to,
        data: approvalTransaction.data,
      });
      setApprovalTxHash(approvalTxHash);

      analytics.approvalTransactionSent(approvalTxHash, track.id);
      const {status} = await getChainClient(chainId).waitForTransactionReceipt({
        hash: approvalTxHash,
      });

      if (status !== 'success') {
        throw new TransactionError('APPROVAL_TRANSACTION_REVERTED', {
          chainId,
          txHash: approvalTxHash,
        });
      }
    }

    setTransactionStep('mainTransaction');
    const txHash = await sendExternalTransaction({
      to: mintTransaction.to,
      data: mintTransaction.data,
      value: BigInt(mintTransaction.value || 0),
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    analytics.mintTransactionSent(txHash, track.id, mintTransaction.value);

    setTxHash(txHash);

    const receipt = await getChainClient(chainId).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      analytics.mintFailure(txHash, track.id, mintTransaction.value);
      throw new TransactionError('TRANSACTION_REVERTED', {chainId, txHash});
    }

    setToken(getTokenFromLogs(receipt.logs, receipt.transactionHash));

    analytics.mintSuccess(txHash, track.id, mintTransaction.value);
  };

  const executeTransaction = async () => {
    try {
      resetTxDetails();

      if (!paymentWallet) {
        throw new TransactionError('NO_WALLET_SELECTED');
      }

      setTransactionStep('validatingOffer');

      const {data: refetchedOfferInfo} = await collectInfoQuery.refetch({
        throwOnError: true,
      });
      const refetchedOffer = refetchedOfferInfo?.mintOffers.find(
        ({id}) => id === offer.id,
      );

      if (!refetchedOffer) {
        throw new TransactionError('OFFER_NOT_AVAILABLE');
      }

      if (refetchedOffer.price.value !== offer.price.value) {
        throw new TransactionError('OFFER_PRICE_CHANGED');
      }

      if (isPasskeyWallet(paymentWallet)) {
        await collectWithPasskeyWallet(paymentWallet);
      } else {
        await collectWithExternalWallet();
      }

      setTransactionStep('success');
    } catch (error: any) {
      console.log('ERROR', error);
      setTransactionStep('checkout');

      if (
        (error instanceof PasskeyError && error.status === 'USER_CANCELLED') ||
        (error instanceof TransactionError &&
          error.status === 'USER_CANCELLED') ||
        // Those are errors code for canceling transactions in different external wallets I managed to catch
        error?.code === 5000 ||
        error?.cause?.code === 4001
      ) {
        return;
      }

      Sentry.captureException(error, {
        tags: {
          collect: true,
          passkeyWallet: !!paymentWallet && isPasskeyWallet(paymentWallet),
        },
      });
      setTransactionError(error);
      collectInfoQuery.refetch();
    }
  };

  return {
    executeTransaction,
  };
};
