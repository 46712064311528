import {StackActions} from '@react-navigation/native';
import {getFeedItemId} from 'api-utils';

import {IFeedItem} from '@/types/feed';
import {Routes} from '@/types/routes';

export const getArtistLink = (slugOrId: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.Artist,
      params: {slugOrId},
    },
  },
  action: StackActions.push(Routes.Artist, {slugOrId}),
});

export const getTrackLink = (slug: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.Track,
      params: {slug: slug},
    },
  },
});

export const setFeedItemUserId = (
  userId: string,
  feedItem: IFeedItem,
): IFeedItem => {
  return {
    ...feedItem,
    userId,
    id: getFeedItemId({...feedItem, userId}),
  };
};
