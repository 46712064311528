import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import ScrollContainer from '@/components/ScrollContainer';
import Text from '@/components/Text';
import {
  OwnedTrackTile,
  EtherscanLink,
  JiffyscanLink,
} from '@/modules/Transactions';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {useThemedStyles} from '@/theme';
import {prettifyAddress} from '@/utils/ethereum';
import {shareTxSuccessOnTwitter} from '@/utils/share';

import {styles} from './SuccessfulTransfer.style';

interface IProps {}

const SuccessfulTransfer: FC<IProps> = ({}) => {
  const style = useThemedStyles(styles);
  const {
    user,
    track,
    deliveryWalletAddress,
    txHash,
    userOpHash,
    transferParams,
  } = useTransferState();

  return (
    <ScrollContainer contentStyle={style.scrollContent}>
      <OwnedTrackTile user={user} track={track} />
      <View style={style.content}>
        <Text
          weight="semibold"
          size="l"
          id="transfer.success.title"
          align="center"
        />

        <View style={style.textsContainer}>
          <Text
            id="transfer.success.description1"
            align="center"
            values={{
              track: (
                <Text weight="semibold" align="center">
                  {track.title}
                </Text>
              ),
              artist: track.artist.name,
              address:
                deliveryWalletAddress && prettifyAddress(deliveryWalletAddress),
            }}
          />
          <Text id="transfer.success.description2" align="center" />
        </View>

        <CardButton
          style={style.shareButton}
          onPress={() => shareTxSuccessOnTwitter(track)}
          text={{id: 'collect.success.share'}}
          icon={{name: 'share', provider: 'custom'}}
        />
      </View>

      <View style={style.transactionLinks}>
        {(txHash || userOpHash) && (
          <View style={style.links}>
            {userOpHash && (
              <View style={style.link}>
                <JiffyscanLink
                  userOpHash={userOpHash}
                  chainId={transferParams.chainId}
                />
              </View>
            )}
            {txHash && (
              <View style={style.link}>
                <EtherscanLink
                  txHash={txHash}
                  chainId={transferParams.chainId}
                />
              </View>
            )}
          </View>
        )}
      </View>
    </ScrollContainer>
  );
};

export default SuccessfulTransfer;
