import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {NavigatorScreenParams} from '@react-navigation/native';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';

import {ITransferParams} from '@/modules/Transfer';

export enum Routes {
  Player = 'Player',

  MainNavigation = 'MainNavigation',
  Track = 'Track',
  Artist = 'Artist',
  ArtistEdit = 'ArtistEdit',
  ArtistThemeEdit = 'ArtistThemeEdit',
  Collector = 'Collector',
  CollectorEdit = 'CollectorEdit',
  CollectorThemeEdit = 'CollectorThemeEdit',
  Collection = 'Collection',
  Playlist = 'Playlist',
  PlaylistCurators = 'PlaylistCurators',
  Platform = 'Platform',
  ActivityFeed = 'ActivityFeed',
  ThemesSettings = 'ThemesSettings',
  NotificationSettings = 'NotificationSettings',
  Wallets = 'Wallets',
  WalletsSettings = 'WalletsSettings',
  Wallet = 'Wallet',

  Tabs = 'Tabs',
  ExploreNavigation = 'ExploreNavigation',
  Explore = 'Explore',

  ReleasesForYou = 'ReleasesForYou',
  ImpersonatingReleasesForYou = 'ImpersonatingReleasesForYou',

  Feed = 'Feed',
  ImpersonatingFeed = 'ImpersonatingFeed',

  LatestTracks = 'LatestTracks',
  Artists = 'Artists',
  TrendingTracks = 'TrendingTracks',
  TrendingPlaylists = 'TrendingPlaylists',

  SearchNavigation = 'SearchNavigation',
  Search = 'Search',

  LibraryNavigation = 'LibraryNavigation',
  Library = 'Library',
  Favorites = 'Favorites',
  History = 'History',

  Queue = 'Queue',
  AddToPlaylist = 'AddToPlaylist',
  SuggestToPlaylist = 'SuggestToPlaylist',
  PlaylistCollaborators = 'PlaylistCollaborators',

  SpinampWrapped = 'SpinampWrapped',

  Collect = 'Collect',
  Transfer = 'Transfer',

  Login = 'Login',

  TopUp = 'TopUp',

  EmbedPlayer = 'EmbedPlayer',
  EmbedArtist = 'EmbedArtist',
  EmbedPlaylist = 'EmbedPlaylist',
  EmbedCollection = 'EmbedCollection',
  EmbedPlatform = 'EmbedPlatform',
}

// Root stack
export type RootStackParams = {
  [Routes.MainNavigation]: NavigatorScreenParams<MainStackParams>;
  [Routes.Player]: undefined;
  [Routes.Queue]: undefined;
  [Routes.AddToPlaylist]: {id: string};
  [Routes.SuggestToPlaylist]: {id: string};
  [Routes.PlaylistCollaborators]: {id: string};
  [Routes.SpinampWrapped]: {userSlugOrId?: string};
  [Routes.Collect]: {slug: string; referral?: string};
  [Routes.Transfer]: ITransferParams & {
    slug: string;
  };
  [Routes.Login]: undefined;
  [Routes.TopUp]: {
    address: string;
    chainId: number;
    amount?: string;
  };
};

export type RootStackNavigationParams =
  NativeStackNavigationProp<RootStackParams>;

// Main stack
export type MainStackParams = {
  [Routes.Tabs]: NavigatorScreenParams<TabsParams>;
  [Routes.Artist]: {
    slugOrId: string;
    tab?: string;
  };
  [Routes.ReleasesForYou]: undefined;
  [Routes.Feed]:
    | {
        tab?: string;
      }
    | undefined;
  [Routes.ArtistEdit]: {
    slugOrId: string;
  };
  [Routes.ArtistThemeEdit]: {
    slugOrId: string;
  };
  [Routes.Track]: {
    slug: string;
    referral?: string;
    autoplay?: boolean;
    redirect?: string;
  };
  [Routes.Collector]: {
    slug: string;
    tab?: string;
  };
  [Routes.ImpersonatingReleasesForYou]: {
    slug: string;
  };
  [Routes.ImpersonatingFeed]: {
    slug: string;
    tab?: string;
  };
  [Routes.CollectorEdit]: {
    slug: string;
  };
  [Routes.CollectorThemeEdit]: {
    slug: string;
  };
  [Routes.Collection]: undefined;
  [Routes.Playlist]: {id: string};
  [Routes.PlaylistCurators]: {id: string};
  [Routes.Platform]: {platformId: string};
  [Routes.ActivityFeed]: undefined;
  [Routes.ThemesSettings]: undefined;
  [Routes.Wallets]: undefined;
  [Routes.WalletsSettings]: undefined;
  [Routes.Wallet]: {
    id: string;
  };
  [Routes.NotificationSettings]: undefined;
};

export type MainStackNavigationParams =
  NativeStackNavigationProp<MainStackParams>;

export type TabsParams = {
  [Routes.LibraryNavigation]?: NavigatorScreenParams<LibraryStackParams>;
  [Routes.ExploreNavigation]?: NavigatorScreenParams<ExploreStackParams>;
  [Routes.SearchNavigation]?: NavigatorScreenParams<SearchStackParams>;
};

export type TabsNavigationParams = BottomTabNavigationProp<TabsParams>;

// Explore stack
export type ExploreStackParams = {
  [Routes.Explore]: undefined;
  [Routes.Artists]: undefined;
  [Routes.LatestTracks]: undefined;
  [Routes.TrendingTracks]: undefined;
  [Routes.TrendingPlaylists]: undefined;
};

export type ExploreStackNavigationParams =
  NativeStackNavigationProp<ExploreStackParams>;

// Library stack
export type LibraryStackParams = {
  [Routes.Library]: {category?: string; subcategory?: string} | undefined;
};

export type LibraryStackNavigationParams =
  NativeStackNavigationProp<LibraryStackParams>;

// Search stack
export type SearchStackParams = {
  [Routes.Search]:
    | {
        query?: string;
        category?: string;
      }
    | undefined;
};

export type SearchStackNavigationParams =
  NativeStackNavigationProp<SearchStackParams>;

// Embedded routes
export type EmbedStackParams = {
  [Routes.EmbedPlayer]: {
    id: string;
    colors?: string;
    theme?: string;
    collect?: string;
    referral?: string;
  };
  [Routes.EmbedArtist]: {id: string; colors?: string; theme?: string};
  [Routes.EmbedPlaylist]: {id: string; colors?: string; theme?: string};
  [Routes.EmbedPlatform]: {id: string; colors?: string; theme?: string};
  [Routes.EmbedCollection]: {
    slug: string;
    colors?: string;
    theme?: string;
    platform?: string;
    grid?: boolean;
  };
};

export type EmbedStackNavigationParams =
  NativeStackNavigationProp<EmbedStackParams>;
