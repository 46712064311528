import {IIconProps} from '@/components/Icon';

import {IArtistWithTracks, ITrack} from './common';

export interface IGeneratorConfig {
  name: string;
  args: string[];
}

export enum IFeedGenerator {
  followedArtistsCollaborativeFiltering = 'followed-artists-collaborative-filtering',
  followedArtistTracks = 'followed-artist-tracks',
}

export enum IFeedEntityType {
  'track' = 'track',
  'artist' = 'artist',
  'message' = 'message',
  'localMessage' = 'localMessage',
}

export type IFeedUserAction = 'like' | 'hide' | null;

export interface IFeedItemRaw {
  id: string;
  entityId: string;
  entityType: IFeedEntityType;
  userId: string;
  insertionId: number;
  userAction?: IFeedUserAction;
  generator?: IFeedGenerator;
  generatorMetadata?: unknown;
  updatedAtTime: string;
  createdAtTime: string;
  message?: IFeedMessage;
  pageCursor: string;
}

export type IFeedItem =
  | IFeedItemWithTrack
  | IFeedItemWithArtist
  | IMessageFeedItem;

export interface IFeedItemWithTrack extends IFeedItemRaw {
  entityType: IFeedEntityType.track;
  track: ITrack;
}

export interface IFeedItemWithArtist extends IFeedItemRaw {
  entityType: IFeedEntityType.artist;
  artist: IArtistWithTracks;
}

export interface IMessageFeedItem extends IFeedItemRaw {
  entityType: IFeedEntityType.message;
  message: IFeedMessage;
}

export interface ILocalMessageFeedItem {
  id: string;
  entityType: IFeedEntityType.localMessage;
  localMessageId: 'refill' | 'onboarding0' | 'onboarding1';
  generator?: IFeedItemRaw['generator'];
  // no message here; we store ILocalMessageFeedItem's messages locally and can find them just by localMessageId
}

interface IFeedMessage {
  iconProps: IIconProps;
  title: string;
  body: string;
  actionText: string;
  actionLink: string;
}

export const feedItemFilterableProperties = [
  'id',
  'entityId',
  'entityType',
  'userId',
  'userAction',
  'generator',
] as const;

export type IFilterableFeedItem = Pick<
  IFeedItem,
  (typeof feedItemFilterableProperties)[number]
>;
