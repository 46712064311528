import React, {FC} from 'react';
import {View} from 'react-native';

import TxArrow from '@/assets/images/tx-arrow.svg';
import Avatar from '@/components/Avatar';
import CardButton from '@/components/CardButton';
import ScrollContainer from '@/components/ScrollContainer';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text/Text';
import spacing from '@/constants/spacing';
import {useActiveUser} from '@/hooks/useActiveUser';
import TrackSummary from '@/modules/Collect/components/TrackSummary';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useTheme, useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {noop} from '@/utils/functions';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles} from './PendingTransaction.style';

interface IProps {
  chainId: number;
  type: 'main' | 'approval';
  txHash: string | undefined;
  userOpHash: string | undefined;
}

const PendingTransaction: FC<IProps> = ({
  type,
  txHash,
  userOpHash,
  chainId,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {track, paymentWallet} = useCollectState();
  const user = useActiveUser();

  const isInternalWallet = paymentWallet && isPasskeyWallet(paymentWallet);

  return (
    <ScrollContainer contentStyle={style.container}>
      <TrackSummary track={track} />

      <TxArrow
        // @ts-ignore
        style={{color: theme.colors.textColor}}
        height={80}
      />

      {user && (
        <View style={style.collectorCard}>
          <Avatar
            id={user.id}
            url={getUserAvatar(user)}
            size={36}
            resizeWidth={ImageSize.avatar}
          />
          <Text weight="semibold" flex>
            {getUserDisplayName(user)}
          </Text>
          <SpinLoader size={spacing.l} />
        </View>
      )}

      <View style={style.textsContainer}>
        {txHash || isInternalWallet ? (
          <Text
            weight="semibold"
            size="s"
            id={`collect.pending.${type}.waitingForReceipt`}
            align="center"
          />
        ) : (
          <Text
            weight="semibold"
            size="s"
            id={`collect.pending.${type}.waitingForSignature`}
            align="center"
          />
        )}

        {(txHash || userOpHash) && (
          <View style={style.links}>
            {userOpHash && (
              <View style={style.link}>
                <JiffyscanLink userOpHash={userOpHash} chainId={chainId} />
              </View>
            )}
            {txHash && (
              <View style={style.link}>
                <EtherscanLink txHash={txHash} chainId={chainId} />
              </View>
            )}
          </View>
        )}
      </View>

      <CardButton
        style={style.processingButton}
        onPress={noop}
        text={{id: 'collect.processing'}}
        isLoading
        loaderProps={{textId: 'collect.processing'}}
      />
    </ScrollContainer>
  );
};

export default PendingTransaction;
