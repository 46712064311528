import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';

import {IIconProps} from '@/components/Icon';
import HotkeyButton from '@/components/Key/HotkeyButton';
import {useHotkeys} from '@/hooks/useHotkeys';
import {useThemedStyles} from '@/theme';
import {identity} from '@/utils/functions';

import {styles} from './FeedHotkeys.styles';
import {type IProps, type IHotkeyableFeedItem} from './shared';
import DetailsModal from '../DetailsModal/DetailsModal';

type HotkeyHandler = () => void;

interface IHotkeyConfig {
  key: string;
  keyText: string;
  labelTextId: string;
  labelIcon?: IIconProps;
  onPress: HotkeyHandler;
}

type GroupedHotkeyMap = readonly (readonly IHotkeyConfig[] | IHotkeyConfig)[];

// A group allows us to have one or more hotkeys that will always appear next to each other.
// Hotkeys that aren't in a group can wrap between any hotkey.
const getHotkeyMap = ({
  toggleHide,
  onPlay,
  toggleLike,
  onShowDetail,
  onCollect,
  onMoveLeft,
  onMoveRight,
}: {
  toggleHide: HotkeyHandler | null;
  onPlay: HotkeyHandler;
  toggleLike: HotkeyHandler | null;
  onShowDetail: HotkeyHandler;
  onCollect: HotkeyHandler | null;
  onMoveLeft: HotkeyHandler;
  onMoveRight: HotkeyHandler;
}): GroupedHotkeyMap => {
  const hideHotkey: IHotkeyConfig | null = toggleHide && {
    key: 'z',
    keyText: 'Z',
    labelTextId: 'feed.hide',
    labelIcon: {name: 'visibilityOff', provider: 'custom'},
    onPress: toggleHide,
  };

  const likeHotkey: IHotkeyConfig | null = toggleLike && {
    key: 'c',
    keyText: 'C',
    labelTextId: 'feed.like',
    labelIcon: {
      name: 'heart',
      provider: 'custom',
      size: 19,
    },
    onPress: toggleLike,
  };

  const collectHotkey: IHotkeyConfig | null = onCollect && {
    key: 'c',
    keyText: 'C',
    labelTextId: 'feed.collect',
    labelIcon: {
      name: 'nftMint',
      provider: 'custom',
    },
    onPress: onCollect,
  };

  return [
    [
      {
        key: 'ArrowLeft',
        keyText: '<',
        labelTextId: 'feed.previous',
        onPress: onMoveLeft,
      },
      {
        key: 'ArrowRight',
        keyText: '>',
        labelTextId: 'feed.next',
        onPress: onMoveRight,
      },
    ],
    ...(hideHotkey ? [hideHotkey] : []),
    {
      key: 'x',
      keyText: 'X',
      labelTextId: 'feed.detail',
      labelIcon: {
        name: 'heart',
        provider: 'custom',
      },
      onPress: onShowDetail,
    },
    ...(likeHotkey ? [likeHotkey] : []),
    ...(collectHotkey ? [collectHotkey] : []),
    {
      key: 'p',
      keyText: 'P',
      labelTextId: 'play',
      labelIcon: {
        name: 'play',
        provider: 'custom',
      },
      onPress: onPlay,
    },
  ] as const;
};

const FeedHotkeys: React.FC<IProps> = ({
  activeFeedItem,
  onArtistTrackPlay,
  onTrackPlay,
  toggleHide,
  toggleLike,
  onCollect,
  onMoveLeft,
  onMoveRight,
}) => {
  const style = useThemedStyles(styles);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);

  const onPlay = useCallback(
    (feedItem: IHotkeyableFeedItem) => {
      if (feedItem.entityType === 'track') {
        onTrackPlay(feedItem.track);
      } else if (feedItem.entityType === 'artist') {
        const firstTrack = feedItem.artist.tracks[0];
        if (firstTrack != null) {
          onArtistTrackPlay(feedItem.artist)(firstTrack);
        }
      }
    },
    [onTrackPlay, onArtistTrackPlay],
  );

  const {groupedHotkeyMap, flattenedHotkeyMap} = useMemo(() => {
    const shouldShowCollect =
      'userAction' in activeFeedItem && activeFeedItem.userAction === 'like';
    const shouldShowLike = !shouldShowCollect;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const groupedHotkeyMap = getHotkeyMap({
      onPlay: () => onPlay(activeFeedItem),
      toggleHide: toggleHide ? () => toggleHide(activeFeedItem) : null,
      toggleLike:
        shouldShowLike && toggleLike ? () => toggleLike(activeFeedItem) : null,
      onShowDetail: () => setShowDetailsModal(val => !val),
      onCollect: shouldShowCollect ? () => onCollect(activeFeedItem) : null,
      onMoveLeft: onMoveLeft,
      onMoveRight: onMoveRight,
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const flattenedHotkeyMap = groupedHotkeyMap.flatMap(identity);

    return {
      groupedHotkeyMap,
      flattenedHotkeyMap,
    };
  }, [
    activeFeedItem,
    onArtistTrackPlay,
    onTrackPlay,
    toggleHide,
    toggleLike,
    setShowDetailsModal,
    onCollect,
    onMoveLeft,
    onMoveRight,
  ]);

  useHotkeys(flattenedHotkeyMap);

  return (
    <View style={style.container}>
      {groupedHotkeyMap.map(hotkeyOrGroup => {
        if ('length' in hotkeyOrGroup) {
          return (
            <View style={style.group}>
              {hotkeyOrGroup.map(props => (
                <HotkeyButton {...props} />
              ))}
            </View>
          );
        }

        return <HotkeyButton {...hotkeyOrGroup} />;
      })}
      {showDetailsModal && (
        <DetailsModal
          isOpen={showDetailsModal}
          setIsOpen={setShowDetailsModal}
          feedItem={activeFeedItem}
        />
      )}
    </View>
  );
};

export default FeedHotkeys;
