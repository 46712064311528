import {useEffect, useState} from 'react';

import {IWalletsSettings} from '@/modules/Wallets/types';
import {IBaseUser, IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {getUserDeliveryWallet, getUserPaymentWallet} from '@/utils/user';

const getWalletById = (user?: IBaseUser, address?: string) =>
  user?.addresses.find(a => areAddressesEqual(a.address, address));

const getUserWallets = (user?: IUser): IWalletsSettings | undefined => {
  const paymentAddress = user && getUserPaymentWallet(user)?.address;

  if (!paymentAddress) {
    return undefined;
  }

  return {
    paymentAddress,
    deliveryAddress: getUserDeliveryWallet(user)?.address || paymentAddress,
  };
};

export const useWalletsSettings = (user?: IUser) => {
  const [walletsSettings, setWalletsSettings] = useState(getUserWallets(user));

  const paymentWallet = getWalletById(user, walletsSettings?.paymentAddress);
  const deliveryWallet = getWalletById(user, walletsSettings?.deliveryAddress);

  // set wallets after user signed in
  useEffect(() => {
    if (user && !walletsSettings) {
      setWalletsSettings(getUserWallets(user));
    }
  }, [user?.addresses, walletsSettings]);

  return {
    walletsSettings,
    setWalletsSettings,
    paymentWallet,
    deliveryWallet,
    transferEnabled:
      walletsSettings?.paymentAddress !== walletsSettings?.deliveryAddress,
  };
};
