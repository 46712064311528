import SPACES from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const KEY_CONTAINER_WIDTH = 38;

const LABEL_CONTAINER_WIDTH = 70;

export const styles = createThemedStyles(theme => ({
  keyContainer: {
    borderStyle: 'solid',
    borderColor: theme.colors.borderColor,
    borderWidth: theme.box.baseBorderWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
    borderRadius: 5,
    width: KEY_CONTAINER_WIDTH,
    aspectRatio: 1 / 1,
    padding: SPACES.xs,
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    margin: SPACES.xs,
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    gap: SPACES.xxs,
    width: LABEL_CONTAINER_WIDTH,
    justifyContent: 'center',
  },
}));
