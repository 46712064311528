import {createThemedStyles} from '@/theme';

export const ARROW_ICON_SIZE = 28;

export const styles = createThemedStyles(() => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
}));
