import {
  connectorsForWallets,
  RainbowKitProvider,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  zerionWallet,
  metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';
import React, {FC, ReactNode} from 'react';
import {createConfig, WagmiConfig} from 'wagmi';

import {
  chains,
  publicClient,
  projectId,
  walletConnectConnector,
} from '@/modules/ExternalWallet/ExternalWalletProvider/shared';
import '@rainbow-me/rainbowkit/styles.css';
import {useTheme} from '@/theme';
import {isMobileBrowser} from '@/utils/platform';

const rainbowConnectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      injectedWallet({chains}),
      metaMaskWallet({projectId, chains}),
      rainbowWallet({projectId, chains}),
      zerionWallet({projectId, chains}),
      coinbaseWallet({appName: 'Spinamp', chains}),
      walletConnectWallet({projectId, chains}),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: isMobileBrowser ? [walletConnectConnector] : rainbowConnectors,
  publicClient,
});

const WalletMobileProvider: FC<{children: ReactNode}> = ({children}) => {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};

const WalletDesktopProvider: FC<{children: ReactNode}> = ({children}) => {
  const theme = useTheme();
  const rainbowTheme = lightTheme();
  rainbowTheme.colors.modalBackground = theme.colors.backgroundLight;
  rainbowTheme.colors.modalBorder = theme.colors.borderColor;
  rainbowTheme.colors.modalText = theme.colors.textColor;
  rainbowTheme.colors.modalTextSecondary = theme.colors.textColor;
  rainbowTheme.colors.closeButton = theme.colors.textColor;
  rainbowTheme.fonts.body = theme.font.light;
  rainbowTheme.radii.modal = theme.box.borderRadius.toString() + 'px';

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        appInfo={{appName: 'Spinamp'}}
        theme={rainbowTheme}
        coolMode
        chains={chains}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default isMobileBrowser ? WalletMobileProvider : WalletDesktopProvider;
