import API from '@/services/spinampApi';
import {ISigner} from '@/types/session';
import {formatFirebaseId, getSignedRequestBody} from '@/utils/api';

interface IHistoryTrack {
  trackId: string;
  timestamp: string;
}

export const fetchHistory = async (
  signer: ISigner,
  latestTimestamp?: string,
): Promise<IHistoryTrack[]> => {
  const body = await getSignedRequestBody('get-my-events', signer);

  const config = {
    headers: {
      auth: JSON.stringify(body),
    },
    params: {
      latestTimestamp,
    },
  };

  const response: IHistoryTrack[] = await API.get('history', config);
  return response.map(historyTrack => ({
    ...historyTrack,
    trackId: formatFirebaseId(historyTrack.trackId),
  }));
};
