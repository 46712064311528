import React, {FC} from 'react';

import {useLoginError} from '../../useLoginError';
import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './LoginError.style';

const LoginError: FC = () => {
  const style = useThemedStyles(styles);
  const {loginError, resetLoginError} = useLoginError();

  return (
    <Space style={style.content}>
      <Space style={style.title}>
        <Icon name="warning" provider="custom" />
        <Text weight="semibold" id="login.error.title" size="m" />
      </Space>
      {loginError && <Text id={loginError} size="xs" />}

      <CardButton
        onPress={resetLoginError}
        text={{id: 'login.error.tryAgain'}}
      />
    </Space>
  );
};

export default LoginError;
