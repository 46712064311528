import React, {useState} from 'react';
import {View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import ImageWithPreview from '@/components/ImageWithPreview';
import Text from '@/components/Text';
import TrackCard from '@/components/TrackCard';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IFeedItem, IFeedItemWithArtist} from '@/types/feed';
import {ImageSize} from '@/types/media';

import {styles} from './ArtistFeedCard.styles';
import {AudioFeedCard} from './AudioFeedCard';
import ArtistName from './InfoLinks/ArtistName';

interface IArtistFeedCardProps {
  feedItem: IFeedItemWithArtist;
  toggleHide?: (feedItem: IFeedItem) => void;
  toggleLike?: (feedItem: IFeedItem) => void;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

const ArtistFeedCard: React.FC<IArtistFeedCardProps> = ({
  feedItem,
  toggleHide,
  toggleLike,
  isActive,
  next,
  setCarouselEnabled,
}) => {
  const style = useThemedStyles(styles);

  const {artist} = feedItem;
  const tracks = artist.tracks.slice(0, 5);
  const firstTrack = tracks[0];
  const [feedCardTrack, setFeedCardTrack] = useState<ITrack | undefined>(
    firstTrack,
  );

  const Title = (
    <Text numberOfLines={2}>
      <View style={style.artistTitle}>
        {artist.avatarUrl && (
          <ImageWithPreview
            resizeWidth={ImageSize.feedCard}
            source={{uri: artist.avatarUrl}}
            style={style.artistTitleAvatar}
            resizeMode="cover"
            hasBorderRadius
            fallback={<FadeInOut enabled style={style.avatarPlaceholder} />}
          />
        )}
        <ArtistName artist={artist} />
      </View>
    </Text>
  );

  const artistNext = () => {
    const trackIndex = tracks.findIndex(
      track => track.id === feedCardTrack?.id,
    );
    if (trackIndex === tracks.length - 1) {
      setFeedCardTrack(firstTrack);
      next();
    } else {
      if (trackIndex !== -1) {
        setFeedCardTrack(tracks[trackIndex + 1]);
      }
    }
  };

  return (
    <AudioFeedCard
      feature={
        <View style={style.tracks}>
          {/* No need for a scrollable list because tracks has 4 or less elements */}
          {tracks.map(track => (
            <TrackCard
              key={track.id}
              onPlay={() => {
                setFeedCardTrack(track);
              }}
              track={track}
              trackStyle={style.track}
              artworkContainerStyle={style.artworkContainer}
              artworkhasBorderRadius
              isActive={track.id === feedCardTrack?.id}
            />
          ))}
          {tracks.length === 0 && (
            <Text style={style.empty} id="feed.artistFeedCard.empty" />
          )}
        </View>
      }
      heading={Title}
      feedItem={feedItem}
      track={feedCardTrack}
      toggleHide={toggleHide}
      toggleLike={toggleLike}
      isActive={isActive}
      next={artistNext}
      setCarouselEnabled={setCarouselEnabled}
    />
  );
};

export default ArtistFeedCard;
