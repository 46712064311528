import React, {FC, ReactNode, useEffect, useState} from 'react';
import {StyleProp, TouchableWithoutFeedback} from 'react-native';
import Animated, {
  FadeIn,
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import TooltipOverlay from '@/components/Tooltip/TooltipOverlay';
import spacing from '@/constants/spacing';
import {useGlobalPressHandler} from '@/hooks/useGlobalPressHandler';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './Tooltip.style';

interface IRenderProps {
  open: () => void;
  close: () => void;
}
interface IProps {
  content: ReactNode;
  children: (props: IRenderProps) => ReactNode;
  containerStyle?: StyleProp<any>;
  tooltipStyle?: StyleProp<any>;
  // more positions can be added when needed
  position?: 'bottom' | 'right' | 'top-left' | 'bottom-left';
  offset?: number;
}

const Tooltip: FC<IProps> = ({
  content,
  children,
  containerStyle,
  tooltipStyle,
  position = 'right',
  offset = spacing.xs,
}) => {
  const ref = useAnimatedRef();
  const containerMeasurement = useSharedValue<MeasuredDimensions | null>(null);
  const tooltipSize = useSharedValue<{
    width: number;
    height: number;
  } | null>(null);

  const style = useThemedStyles(styles);
  const [isVisible, setIsVisible] = useState(false);

  const globalPressHandler = useGlobalPressHandler();

  const onOpen = () => {
    if (!isVisible) {
      runOnUI(() => {
        containerMeasurement.value = measure(ref);
        runOnJS(setIsVisible)(true);
      })();
    }
  };

  const onClose = () => {
    setIsVisible(false);
    containerMeasurement.value = null;
    tooltipSize.value = null;
  };

  useEffect(() => {
    if (isVisible) {
      return globalPressHandler.addEventListener(onClose);
    }
  }, [isVisible]);

  const tooltipAnimatedStyle = useAnimatedStyle(() => {
    if (!tooltipSize.value || !containerMeasurement.value) {
      return {
        opacity: 0,
      };
    }

    const opacity = withTiming(1, {duration: 150});
    const {
      pageX,
      pageY,
      height: containerHeight,
      width: containerWidth,
    } = containerMeasurement.value;
    const {height: tooltipHeight, width: tooltipWidth} = tooltipSize.value;

    if (position === 'right') {
      return {
        top: pageY + containerHeight / 2 - tooltipHeight / 2,
        left: pageX + containerWidth + offset,
        opacity,
      };
    }

    if (position === 'top-left') {
      return {
        top: pageY - tooltipHeight - offset,
        left: pageX,
        opacity,
      };
    }

    if (position === 'bottom-left') {
      return {
        top: pageY + containerHeight + offset,
        left: pageX,
        opacity,
      };
    }

    if (position === 'bottom') {
      return {
        top: pageY + containerHeight + offset,
        left: pageX + containerWidth / 2 - tooltipWidth / 2,
        opacity,
      };
    }

    return {};
  }, [position, offset]);

  return (
    <Animated.View style={[containerStyle]} ref={ref}>
      {children({open: onOpen, close: onClose})}
      {isVisible && (
        <TooltipOverlay>
          {!isWeb && (
            <TouchableWithoutFeedback>
              <Animated.View
                // @ts-ignore
                style={style.backdropWrapper}
                entering={FadeIn.duration(150)}>
                <Animated.View
                  // @ts-ignore
                  style={style.backdrop}
                />
              </Animated.View>
            </TouchableWithoutFeedback>
          )}
          <Animated.View
            onLayout={({nativeEvent}) => {
              tooltipSize.value = {
                width: nativeEvent.layout.width,
                height: nativeEvent.layout.height,
              };
            }}
            style={[
              style.tooltipContainer,
              tooltipStyle,
              tooltipAnimatedStyle,
            ]}>
            {content}
          </Animated.View>
        </TooltipOverlay>
      )}
    </Animated.View>
  );
};

export default Tooltip;
