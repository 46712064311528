import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {TabRouter, useNavigationBuilder} from '@react-navigation/native';
import React, {FC, useContext, useEffect, useRef} from 'react';
import {StyleSheet, View} from 'react-native';

import {DrawerMenuProvider} from '@/components/DrawerMenu';
import {IIconProps} from '@/components/Icon';
import ExploreNavigation from '@/navigation/ExploreNavigation/ExploreNavigation';
import LibraryNavigation from '@/navigation/LibraryNavigation/LibraryNavigation';
import SearchNavigation from '@/navigation/SearchNavigation/SearchNavigation';
import {TabsContext} from '@/navigation/TabsNavigation/TabsContext';
import {Routes, TabsParams} from '@/types/routes';

const Tab = createBottomTabNavigator<TabsParams>();

interface ITabConfig {
  name: keyof TabsParams;
  component: FC;
  titleId: string;
  path: string;
  icon: IIconProps;
}

export const tabsConfig: {[route: string]: ITabConfig} = {
  [Routes.ExploreNavigation]: {
    name: Routes.ExploreNavigation,
    component: ExploreNavigation,
    titleId: 'explore.title',
    path: 'explore',
    icon: {
      name: 'globe',
      provider: 'custom',
    },
  },
  [Routes.SearchNavigation]: {
    name: Routes.SearchNavigation,
    component: SearchNavigation,
    titleId: 'search.title',
    path: 'search',
    icon: {
      name: 'search',
      provider: 'custom',
    },
  },
  [Routes.LibraryNavigation]: {
    name: Routes.LibraryNavigation,
    component: LibraryNavigation,
    titleId: 'library.title',
    path: 'library',
    icon: {
      name: 'library',
      provider: 'custom',
    },
  },
};

export const tabsConfigOrdered = [
  Routes.ExploreNavigation,
  Routes.LibraryNavigation,
  Routes.SearchNavigation,
].map(route => tabsConfig[route]);

const TabsNavigationScreens = (
  <>
    {tabsConfigOrdered.map(tab => (
      <Tab.Screen
        key={tab.name}
        name={tab.name}
        component={tab.component}
        options={
          {
            titleId: tab.titleId,
            icon: tab.icon,
          } as BottomTabNavigationOptions
        }
      />
    ))}
  </>
);

const TabsNavigation = () => {
  const {state, navigation, descriptors, NavigationContent} =
    useNavigationBuilder(TabRouter, {
      children: TabsNavigationScreens,
      screenOptions: {headerShown: false},
      initialRouteName: Routes.Explore,
      backBehavior: 'none',
    });
  const {setTabsContext} = useContext(TabsContext);

  const loadedIndexes = useRef<{[index: number]: boolean}>({});
  loadedIndexes.current[state.index] = true;

  useEffect(() => {
    setTabsContext({
      state,
      // @ts-ignore
      descriptors,
      navigation,
    });
  }, [state, descriptors, navigation]);

  return (
    <DrawerMenuProvider>
      <NavigationContent>
        {state.routes.map((route, index) => {
          if (index !== state.index && !loadedIndexes.current[index]) {
            return null;
          }

          return (
            <View
              key={route.key}
              style={[
                StyleSheet.absoluteFillObject,
                {display: index === state.index ? 'flex' : 'none'},
              ]}>
              {descriptors[route.key].render()}
            </View>
          );
        })}
      </NavigationContent>
    </DrawerMenuProvider>
  );
};

export default TabsNavigation;
