import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const INPUT_PADDING = spacing.xs;
const FONT_SIZE = 14;
const MULTILINE_LINES = 5;

export const styles = createThemedStyles(theme => ({
  inputWrapper: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    padding: INPUT_PADDING,
    paddingHorizontal: theme.box.cardHorizontalPadding,
    gap: spacing.xs,
  },
  input: {
    flex: 1,
    color: theme.colors.textColor,
    fontSize: FONT_SIZE,
    fontFamily: theme.font.light,
    opacity: 0.4,
  },
  inputActive: {
    fontFamily: theme.font.semibold,
    opacity: 1,
  },
  inputMultiline: {
    height: INPUT_PADDING * 2 + FONT_SIZE * MULTILINE_LINES,
  },
}));
