import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectFeed = (state: RootState) => state.feed;

export const selectShouldPlay = createSelector(
  selectFeed,
  feed => feed.shouldPlay,
);

export const selectActiveIndex = createSelector(
  selectFeed,
  feed => feed.activeIndex,
);
