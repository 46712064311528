import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  removeTransaction,
  selectPendingTransactions,
} from '@/store/transactions';

export const useTransactionToasts = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(selectPendingTransactions);

  const closeTransactionToast = (id: string) => {
    dispatch(removeTransaction({id}));
  };

  return {
    transactions,
    closeTransactionToast,
  };
};
