import {Link, StackActions} from '@react-navigation/native';
import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import Avatar from '@/components/Avatar';
import SpinLogo from '@/components/SpinLogo';
import StaticImage from '@/components/StaticImage';
import {useUserLink} from '@/hooks/useUserLink';
import {useTheme, useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {Routes} from '@/types/routes';
import {IBaseUser} from '@/types/user';

import {styles} from './NftEventAvatars.style';

interface IProps {
  size: number;
  participants?: (IBaseUser | undefined)[];
  track?: Pick<ITrack, 'slug' | 'lossyArtworkUrl'>;
}

const NftEventAvatars: FC<IProps> = ({
  size: baseSize,
  participants = [],
  track,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {cacheUser, getUserLinkConfig} = useUserLink();

  const users = participants.filter(p => !!p) as IBaseUser[];
  const showBothUsers = users.length > 1;
  const showTrack = !!track && !showBothUsers;
  const showPlaceholder = users.length === 0 && !track;
  const size =
    showBothUsers || (showTrack && users.length > 0)
      ? (baseSize * 2) / 3
      : baseSize;

  const getTrackLink = (slug: string) => ({
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Track,
        params: {slug},
      },
    },
    action: StackActions.push(Routes.Track, {slug}),
  });

  return (
    <View
      style={[
        style.container,
        {
          height: baseSize,
          width: baseSize,
        },
      ]}>
      {showTrack && (
        <Link {...getTrackLink(track.slug)}>
          <StaticImage
            source={{uri: track.lossyArtworkUrl}}
            // use the same width as for track cards so it can reuse the same cached sources
            resizeWidth={ImageSize.thumbnail}
            style={[
              style.artwork,
              {
                height: size,
                width: size,
                borderRadius: Math.min(3, theme.box.borderRadius),
              },
            ]}
          />
        </Link>
      )}
      {users.slice(0, 2).map((user, index) => (
        <Pressable
          key={index}
          // @ts-ignore
          style={({hovered}) => [
            (index > 0 || showTrack) && style.secondaryImage,
            hovered && style.secondaryImage_hovered,
          ]}>
          <Link
            onPress={() => cacheUser(user)}
            // @ts-ignore
            onContextMenu={cacheUser}
            {...getUserLinkConfig(user)}>
            <Avatar
              size={size}
              resizeWidth={Math.round(baseSize * 1.5)}
              url={user!.avatarUrl || undefined}
              id={user.id}
            />
          </Link>
        </Pressable>
      ))}
      {showPlaceholder && <SpinLogo size={size} showThemeMask={false} />}
    </View>
  );
};

export default NftEventAvatars;
