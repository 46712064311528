import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const TRACK_CARD_HEIGHT = 53;

export const styles = createThemedStyles(theme => ({
  trackCardContainer: {
    flexDirection: 'row',
  },
  trackTouchableCardContainer: {
    alignSelf: 'stretch',
    flex: 1,
    overflow: 'hidden',
  },
  track: {
    flexDirection: 'row',
    padding: 0,
    height: TRACK_CARD_HEIGHT,
    overflow: 'hidden',
    alignSelf: 'stretch',
  },
  artworkContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: 50,
    flexShrink: 0,
    flexGrow: 0,
    position: 'relative',
    overflow: 'hidden',
    // heavily tweaked to make sure image fits inside a rounded border on mobile
    // otherwise there is a white space showing
    borderBottomLeftRadius: Math.max(0, theme.box.borderRadius - 3),
    borderTopLeftRadius: Math.max(0, theme.box.borderRadius - 1),
  },
  artwork: {
    width: '100%',
    height: '100%',
  },
  dragToggler: {
    paddingLeft: spacing.xxs,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
    borderTopRightRadius: theme.box.borderRadius,
    borderBottomRightRadius: theme.box.borderRadius,
  },
  trackSpace: {
    paddingLeft: theme.box.cardHorizontalPadding,
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  titleRow: {
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  title: {
    flexShrink: 1,
  },
  artistLinkWrapper: {
    minWidth: 0,
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  artistJoiner: {
    marginHorizontal: spacing.xxs,
    alignSelf: 'flex-end',
  },
  embedAction: {
    width: spacing.xl - spacing.xxs,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallbackIcon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
