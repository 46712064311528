import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flex: 1,
  },
  scrollContent: {
    gap: spacing.m,
    flexGrow: 1,
  },
  editionHeader: {
    marginBottom: spacing.xs,
  },
  actionsContainer: {
    paddingTop: spacing.s,
    paddingBottom: spacing.m,
    paddingHorizontal: spacing.m,
    gap: spacing.m,
  },
}));
