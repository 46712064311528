import React, {FC, useState} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Modal, {IModalProps} from '@/components/Modal';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import {WalletSelectItem} from '@/modules/Wallets';
import AddWalletButton from '@/modules/Wallets/components/AddWalletButton';
import {useThemedStyles} from '@/theme';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {sortUserWallets} from '@/utils/user';

import {styles} from './WalletPicker.style';

interface IProps extends IModalProps {
  user: IUser;
  selectedWalletAddress: string | undefined;
  onConfirm: (address: string) => void;
  onAddExternalWallet?: () => void;
  chainId?: number;
  excludedAddress?: string;
}

const WalletPicker: FC<IProps> = ({
  user,
  selectedWalletAddress,
  onConfirm,
  onAddExternalWallet,
  chainId,
  excludedAddress,
  ...modalProps
}) => {
  const style = useThemedStyles(styles);
  const [selectedAddress, setSelectedAddress] = useState(selectedWalletAddress);

  const sortedWallets = sortUserWallets(user);
  const wallets = excludedAddress
    ? sortedWallets.filter(a => !areAddressesEqual(a.address, excludedAddress))
    : sortedWallets;

  const confirm = () => {
    if (selectedAddress) {
      onConfirm(selectedAddress);
      modalProps.onClose();
    }
  };

  return (
    <Modal {...modalProps}>
      <ScrollContainer contentStyle={style.content}>
        {wallets.length > 0 && (
          <View style={style.header}>
            <Text size="s" id="transfer.walletPicker.header" />
          </View>
        )}

        {wallets.length === 0 && (
          <Text id="transfer.walletPicker.noWallets" size="xs" align="center" />
        )}

        {wallets.map(address => (
          <WalletSelectItem
            key={address.address}
            wallet={address}
            selectedWalletAddress={selectedAddress}
            onSelect={setSelectedAddress}
            chainId={chainId}
          />
        ))}

        {onAddExternalWallet && (
          <AddWalletButton onPress={onAddExternalWallet} />
        )}
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <CardButton
          onPress={confirm}
          disabled={!selectedAddress}
          text={{id: 'confirm'}}
        />
      </ShadowFooter>
    </Modal>
  );
};

export default WalletPicker;
