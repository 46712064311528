import {useCallback, useMemo, useState} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {selectActiveUserId, selectOnboardingInfo} from '@/store/user';
import {IFeedItem, IFeedEntityType, ILocalMessageFeedItem} from '@/types/feed';
import {sortFeedItems} from '@/utils/feed';
import {debounce} from 'throttle-debounce';

const onboarding0: ILocalMessageFeedItem = {
  id: 'onboarding0',
  localMessageId: 'onboarding0',
  entityType: IFeedEntityType.localMessage,
};

const onboarding1: ILocalMessageFeedItem = {
  id: 'onboarding1',
  localMessageId: 'onboarding1',
  entityType: IFeedEntityType.localMessage,
};

const refillBase: Omit<ILocalMessageFeedItem, 'id'> = {
  localMessageId: 'refill',
  entityType: IFeedEntityType.localMessage,
};

export const useFeedItemsWithArtificialCards = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[] | null,
  userId: string,
) => {
  const {showFeedOnboarding1} = useAppSelector(selectOnboardingInfo);
  const activeUserId = useAppSelector(selectActiveUserId);

  return useMemo(() => {
    if (!feedItems) {
      return null;
    }
    const feedItemsWithArtificialCards = [...feedItems];

    if (showFeedOnboarding1) {
      if (feedItems.length === 0) {
        feedItemsWithArtificialCards.unshift(onboarding0);
      } else {
        feedItemsWithArtificialCards.unshift(onboarding1);
      }
    }

    if (activeUserId === userId) {
      // insert refill card after every change of feedItem.pageCursor
      let lastPageCursor = '';
      for (let i = 0; i < feedItemsWithArtificialCards.length; i++) {
        const item = feedItemsWithArtificialCards[i];
        if ('pageCursor' in item && item.pageCursor !== lastPageCursor) {
          // don't want one at the beginning
          if (i > (showFeedOnboarding1 ? 1 : 0)) {
            const refill = {...refillBase, id: `refill-${item.pageCursor}`};
            feedItemsWithArtificialCards.splice(i, 0, refill);
          }
          lastPageCursor = item.pageCursor;
        }
      }
      // add one to the end of the feed if there isn't a oonboarding refill card
      if (!(showFeedOnboarding1 && feedItems.length === 0)) {
        feedItemsWithArtificialCards.push({...refillBase, id: `refill-end`});
      }
    }

    return feedItemsWithArtificialCards;
  }, [feedItems, showFeedOnboarding1, activeUserId]);
};

/**
 * Custom hook to manage feed items which need to persist in the feed throughout feed query refreshes.
 * This is useful for when we want to keep a liked card in the feed temporarily,
 * to allow the user a chance to undo the action, to continue listening to the song, etc.
 */
export const useFeedItemsWithPinnedCards = (feedItems: IFeedItem[] | null) => {
  const [additions, setAdditions] = useState<IFeedItem[]>([]);
  const pinFeedItem = (feedItem: IFeedItem) => {
    setAdditions(_additions => {
      if (_additions.some(item => item.id === feedItem.id)) {
        return _additions;
      }
      return [..._additions, feedItem];
    });
  };

  /**
   * Removes a feed item from the additions list after a debounce time.
   * Debounce is used to prevent the feed item from flickering back into the feed after the query refresh.
   */
  const unpinFeedItem = useCallback(
    debounce(5, (feedItem: IFeedItem) => {
      setAdditions(_additions =>
        _additions.filter(item => item.id !== feedItem.id),
      );
    }),
    [setAdditions],
  );

  // Applies additions to feedItems. They will be applied in the correct place
  // as long as their updatedAt times have not been changed since they were in the
  // right place (in other words make sure to call addFeedItem() before feedItemMutation.mutate)
  const feedItemsWithActiveCard = useMemo(() => {
    if (feedItems === null) {
      return null;
    }

    const newFeedItems = [
      ...feedItems,
      ...additions.filter(
        addition => !feedItems.some(item => item.id === addition.id),
      ),
    ];
    newFeedItems.sort(sortFeedItems);
    return newFeedItems;
  }, [feedItems, additions]);

  return {feedItemsWithActiveCard, pinFeedItem, unpinFeedItem};
};
