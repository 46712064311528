import {gql} from 'graphql-request';

import {fetchUserBySlug} from '@/api/user';
import {pipelineApi} from '@/services/pipelineApi';
import {IApiResponseArtist, IApiResponseTrack} from '@/types/api';
import {IArtist} from '@/types/common';
import {
  ARTIST_FRAGMENT,
  parseApiArtist,
  parseApiTrack,
  TRACK_FRAGMENT,
} from '@/utils/apiModelParsers';

export const fetchYearlyCollectionSummary = async (
  userId: string,
): Promise<{
  totalNftsCollected: number;
  supportedArtists: IArtist[];
}> => {
  const response = await pipelineApi.request(
    gql`
      query ArtistsSupported($userId: String!) {
        userById(id: $userId) {
          addressesByUserId {
            nodes {
              id
              nftTransfersByTo(
                filter: {
                  createdAtTime: {
                    greaterThanOrEqualTo: "2023-01-01T00:00:00+00:00"
                  }
                }
              ) {
                totalCount
                nodes {
                  nftByNftId {
                    nftsProcessedTracksByNftId {
                      nodes {
                        processedTrackByProcessedTrackId {
                          artistByArtistId {
                            ...ArtistDetails
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ${ARTIST_FRAGMENT}
    `,
    {userId},
  );

  let totalNftsCollected = 0;
  const artistsById: {[id: string]: IApiResponseArtist} = {};

  response.userById.addressesByUserId.nodes.forEach((addressNode: any) => {
    totalNftsCollected += addressNode.nftTransfersByTo.totalCount;

    addressNode.nftTransfersByTo.nodes.forEach((transferNode: any) => {
      transferNode.nftByNftId.nftsProcessedTracksByNftId.nodes.forEach(
        (trackNode: any) => {
          const artist =
            trackNode.processedTrackByProcessedTrackId.artistByArtistId;
          artistsById[artist.id] = artist;
        },
      );
    });
  });

  return {
    totalNftsCollected,
    supportedArtists: Object.values(artistsById).map(parseApiArtist),
  };
};

export const fetchSpinampWrappedData = async (userId: string) => {
  const response = await pipelineApi.request(
    gql`
      query SpinampWrapped($userId: String) {
        getUserFavouriteTracks(userid: $userId) {
          nodes {
            trackId
            count
            processedTrackByTrackId {
              ...TrackDetails
            }
          }
        }
        getUserFavouriteArtists(userid: $userId) {
          nodes {
            artistId
            count
            artistByArtistId {
              ...ArtistDetails
            }
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {userId},
  );

  const favTracks: {
    count: number;
    processedTrackByTrackId: IApiResponseTrack;
  }[] = response.getUserFavouriteTracks.nodes;

  const favArtists: {count: number; artistByArtistId: IApiResponseArtist}[] =
    response.getUserFavouriteArtists.nodes;

  return {
    tracks: favTracks.map(t => ({
      count: t.count,
      track: parseApiTrack(t.processedTrackByTrackId),
    })),
    artists: favArtists.map(a => ({
      count: a.count,
      artist: parseApiArtist(a.artistByArtistId),
    })),
  };
};

export const fetchSpinampWrappedSummary = async (userSlugOrId: string) => {
  const user = await fetchUserBySlug(userSlugOrId!);

  if (!user) {
    throw new Error('User not found');
  }

  const userId = user.id;

  const [listen, collection] = await Promise.all([
    fetchSpinampWrappedData(userId!),
    fetchYearlyCollectionSummary(userId!),
  ]);

  return {
    user,
    listen,
    collection,
  };
};
