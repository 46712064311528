import {createThemedStyles} from '@/theme';

const getLineHeight = (fontSize: number) => Math.round(1.2 * fontSize);

export const styles = createThemedStyles(theme => ({
  HEADER_L: {
    fontSize: 30,
    lineHeight: 35,
  },
  HEADER_M: {
    fontSize: 24,
    lineHeight: 40,
  },
  HEADER_S: {
    fontSize: 18,
    lineHeight: 22,
  },
  HEADER_XS: {
    fontSize: 12,
    lineHeight: 20,
  },
  TEXT: {
    fontFamily: theme.font.regular,
  },
  TEXT_XXL: {
    fontSize: 30,
    lineHeight: getLineHeight(30),
  },
  TEXT_XL: {
    fontSize: 22,
    lineHeight: getLineHeight(22),
  },
  TEXT_L: {
    fontSize: 18,
    lineHeight: getLineHeight(18),
  },
  TEXT_M: {
    fontSize: 16,
    lineHeight: getLineHeight(16),
  },
  TEXT_S: {
    fontSize: 14,
    lineHeight: getLineHeight(14),
  },
  TEXT_XS: {
    fontSize: 12,
    lineHeight: getLineHeight(12),
  },
  TEXT_XXS: {
    fontSize: 10,
    lineHeight: getLineHeight(10),
  },
  TEXT_REGULAR: {
    fontFamily: theme.font.regular,
  },
  TEXT_BOLD: {
    fontFamily: theme.font.bold,
  },
  TEXT_LIGHT: {
    fontFamily: theme.font.light,
  },
  TEXT_SEMIBOLD: {
    fontFamily: theme.font.semibold,
  },
  TEXT_UPPERCASE: {
    textTransform: 'uppercase',
  },
  TEXT_UNDERLINE: {
    textDecorationLine: 'underline',
  },
  lineThrough: {
    textDecorationLine: 'line-through',
  },
  center: {
    textAlign: 'center',
  },
}));
