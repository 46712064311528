import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  actionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    gap: spacing.xs,
  },
  action: {
    flex: 1,
    justfiyContent: 'center',
    alignItems: 'center',
  },
}));
