import {CSSProperties} from 'react';
import {Platform, ViewProps} from 'react-native';

// es6 import not supported
export const webPlatform = require('platform');

export const checkIframe = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const checkEmbeddedRoute = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.location.pathname.startsWith('/embed/');
};

export const OS = Platform.OS;
export const isAndroid = OS === 'android';
export const isIOS = OS === 'ios';
export const isWeb = OS === 'web';

export const isNative = !isWeb;

export const isEmbedded = isWeb && checkEmbeddedRoute();

export const isLocalDev = isWeb
  ? process.env.NODE_ENV === 'development'
  : __DEV__;

export const isTouchScreen =
  isWeb && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const isIOSBrowser = isWeb && webPlatform.os.family === 'iOS';
export const isAndroidBrowser = isWeb && webPlatform.os.family === 'Android';
export const isMobileBrowser = isIOSBrowser || isAndroidBrowser;
export const isDesktopBrowser = isWeb && !isMobileBrowser;

export const isOSXBrowser = isWeb && webPlatform.os.family === 'OS X';

export const isPWA =
  isWeb &&
  (window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: fullscreen)').matches ||
    // @ts-ignore
    window.navigator.standalone ||
    document.referrer.includes('android-app://'));

export type CommonProps = Omit<
  ViewProps & React.FC,
  | 'onLayout'
  | 'style'
  | 'onTouchCancel'
  | 'onTouchEnd'
  | 'onTouchEndCapture'
  | 'onTouchMove'
  | 'onTouchStart'
> & {
  style: ViewProps['style'] & CSSProperties;
};
