import {useNavigation} from '@react-navigation/native';
import {UseQueryResult} from '@tanstack/react-query';
import React, {createContext, FC, ReactNode, useEffect, useState} from 'react';
import {useBalance} from 'wagmi';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useWalletsSettings} from '@/modules/Collect/components/useWalletsSettings';
import {useTrackCollectInfoQuery} from '@/modules/Collect/queries/collect';
import {
  IMintOfferInfo,
  ITrackCollectInfo,
  ITransactionToken,
  TransactionStep,
} from '@/modules/Collect/types';
import {useGasEstimation} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {IWalletsSettings} from '@/modules/Wallets/types';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {addGasToPrice} from '@/utils/ethereum';
import {getLastItem} from '@/utils/functions';

export type CollectRoute =
  | 'collect'
  | 'walletsPicker'
  | 'addExternalWallet'
  | 'addPasskeyWallet';

export interface ICollectStateContext {
  track: ITrack;
  user: IUser | undefined;
  referralAddress: string | undefined;
  route: CollectRoute;
  setRoute: (route: CollectRoute) => void;
  goBack: () => void;
  walletsSettings: IWalletsSettings | undefined;
  setWalletsSettings: (walletsSettings: IWalletsSettings) => void;
  paymentWallet: IAddress | undefined;
  deliveryWallet: IAddress | undefined;
  transferEnabled: boolean;
  quantity: number;
  setQuantity: (quantity: number) => void;
  collectInfo: ITrackCollectInfo | undefined;
  collectInfoQuery: UseQueryResult<ITrackCollectInfo>;
  selectedOffer: IMintOfferInfo | undefined;
  setSelectedOfferId: (offerId: string | undefined) => void;
  selectedOfferTotalPrice: bigint | undefined;
  txHash: string | undefined;
  setTxHash: (txHash: string | undefined) => void;
  approvalTxHash: string | undefined;
  setApprovalTxHash: (txHash: string | undefined) => void;
  transactionError: any | undefined;
  setTransactionError: (error: any | undefined) => void;
  userOpHash: string | undefined;
  setUserOpHash: (hash: string | undefined) => void;
  approvalUserOpHash: string | undefined;
  setApprovalUserOpHash: (hash: string | undefined) => void;
  transactionStep: TransactionStep;
  setTransactionStep: (step: TransactionStep) => void;
  token: ITransactionToken | undefined;
  setToken: (token: ITransactionToken | undefined) => void;
  balance?: IBalance;
  gasEstimation: IGasEstimation;
  resetTxDetails: () => void;
  closeCollectModal: () => void;
}

export const CollectStateContext = createContext<
  ICollectStateContext | undefined
>(undefined);

interface IProps {
  children: ReactNode;
  track: ITrack;
  referralAddress?: string;
}

const CollectStateProvider: FC<IProps> = ({
  track,
  referralAddress,
  children,
}) => {
  const user = useActiveUser();

  const navigation = useNavigation<RootStackNavigationParams>();

  const [routeHistory, setRouteHistory] = useState<CollectRoute[]>(['collect']);

  const [transactionStep, setTransactionStep] =
    useState<TransactionStep>('checkout');

  const [quantity, setQuantity] = useState(1);

  const [txHash, setTxHash] = useState<string>();
  const [approvalTxHash, setApprovalTxHash] = useState<string>();
  const [userOpHash, setUserOpHash] = useState<string>();
  const [approvalUserOpHash, setApprovalUserOpHash] = useState<string>();

  const [token, setToken] = useState<ITransactionToken>();

  const [transactionError, setTransactionError] = useState<any>();

  const {
    walletsSettings,
    setWalletsSettings,
    paymentWallet,
    deliveryWallet,
    transferEnabled,
  } = useWalletsSettings(user);

  const {collectInfo, query} = useTrackCollectInfoQuery(
    {
      trackId: track.id,
      userAddress: walletsSettings?.paymentAddress,
      quantity,
      referralAddress,
    },
    true,
  );

  const [selectedOfferId, setSelectedOfferId] = useState<string>();
  const selectedOffer =
    collectInfo?.mintOffers.find(({id}) => id === selectedOfferId) ||
    // pick first offer when it's available before auto-select is triggered
    collectInfo?.mintOffers[0];

  // auto-select first offer on mount or when offers are updated
  useEffect(() => {
    if (!collectInfo) {
      return;
    }

    if (
      selectedOfferId &&
      collectInfo.mintOffers.some(({id}) => id === selectedOfferId)
    ) {
      return;
    }

    setSelectedOfferId(collectInfo.mintOffers[0]?.id);
  }, [collectInfo?.mintOffers, selectedOfferId]);

  const {data: balance} = useBalance({
    address: paymentWallet?.address,
    chainId: selectedOffer?.chainId,
    enabled: !!paymentWallet && !!selectedOffer?.chainId,
    watch: true,
  });

  const gasEstimation = useGasEstimation({
    chainId: selectedOffer?.chainId,
    wallet: paymentWallet,
    transaction: selectedOffer && {
      to: selectedOffer.mintTransaction.to,
      data: selectedOffer.mintTransaction.data,
      value: selectedOffer.mintTransaction.value,
    },
    predefinedGasLimit: selectedOffer?.mintTransaction?.gasLimit,
  });

  useEffect(() => {
    setQuantity(1);
  }, [selectedOfferId]);

  const route = getLastItem(routeHistory);
  const setRoute = (newRoute: CollectRoute) => {
    setRouteHistory(currentHistory => {
      if (getLastItem(currentHistory) === newRoute) {
        return currentHistory;
      }

      return [...currentHistory, newRoute];
    });
  };

  const goBack = () => {
    setRouteHistory(currentHistory => {
      if (currentHistory.length > 1) {
        return currentHistory.slice(0, -1);
      }

      return ['collect'];
    });
  };

  const resetTxDetails = () => {
    setTxHash(undefined);
    setApprovalTxHash(undefined);
    setUserOpHash(undefined);
    setApprovalUserOpHash(undefined);
    setTransactionError(undefined);
    setToken(undefined);
  };

  return (
    <CollectStateContext.Provider
      value={{
        track,
        user,
        referralAddress,

        route,
        setRoute,
        goBack,

        walletsSettings,
        setWalletsSettings,
        paymentWallet,
        deliveryWallet,
        transferEnabled,

        quantity,
        setQuantity,

        collectInfo,
        collectInfoQuery: query,

        selectedOffer,
        setSelectedOfferId,
        selectedOfferTotalPrice:
          selectedOffer &&
          addGasToPrice(selectedOffer.price.value, gasEstimation.totalGas),

        txHash,
        setTxHash,
        approvalTxHash,
        setApprovalTxHash,
        userOpHash,
        setUserOpHash,
        approvalUserOpHash,
        setApprovalUserOpHash,

        transactionError,
        setTransactionError,

        token,
        setToken,

        balance,
        gasEstimation,

        transactionStep,
        setTransactionStep,

        resetTxDetails,

        closeCollectModal: navigation.goBack,
      }}>
      {children}
    </CollectStateContext.Provider>
  );
};

export default CollectStateProvider;
