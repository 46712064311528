import React, {useState} from 'react';
import Text from '@/components/Text';

import {IArtist} from '@/types/common';
import Animated from 'react-native-reanimated';
import {Pressable, View} from 'react-native';
import ArtistModal from './Modals/ArtistModal';
import {useThemedStyles} from '@/theme';
import {styles} from './common.styles';
const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

interface IArtistName {
  artist: IArtist;
}

const ArtistName: React.FC<IArtistName> = props => {
  const {artist} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const style = useThemedStyles(styles);
  return (
    <>
      <AnimatedPressable
        onPress={() => setIsOpen(true)}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        style={style.artistName}>
        <View style={style.artistTitleTextContainer}>
          <Text
            size="m"
            numberOfLines={2}
            selectable={false}
            underline={isHovered}>
            {artist.name}
          </Text>
        </View>
      </AnimatedPressable>
      {isOpen && (
        <ArtistModal artist={artist} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default ArtistName;
