import {Dimensions} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 40;
export const AVATAR_SMALL_SIZE = 24;
const SCREEN_HEIGHT = Dimensions.get('window').height;

export const styles = createThemedStyles(theme => ({
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePicker: {
    backgroundColor: theme.colors.backgroundDark,
    maxHeight: SCREEN_HEIGHT / 2,
    flex: 1,
    overflow: 'hidden',
  },
  profilePickerContainer: {
    flexGrow: 1,
    paddingVertical: spacing.xxs,
  },
  profilePickerItem: {
    padding: spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginButton: {
    paddingVertical: spacing.s,
    paddingHorizontal: spacing.s,
    marginTop: spacing.xxs,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    width: spacing.m,
    marginRight: spacing.xs,
  },
}));
