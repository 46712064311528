import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  card: {
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    borderColor: theme.colors.background,
    shadowColor: theme.colors.primary,
    shadowOpacity: 0.2,
    shadowRadius: Platform.select({
      ios: spacing.s / 2,
      default: spacing.s,
    }),
    shadowOffset: Platform.select({
      ios: {height: spacing.s / 4, width: spacing.s / 4},
      default: undefined,
    }),
    backgroundColor: theme.colors.backgroundLight,
    padding: spacing.m,
  },
}));
