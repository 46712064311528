import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {FC} from 'react';

import Screen from '@/components/Screen/Screen';
import {useArtistBySlugQuery} from '@/queries/artists';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {ThemedScreen, useThemedScreenStyles} from '@/theme';
import {
  MainStackNavigationParams,
  MainStackParams,
  Routes,
} from '@/types/routes';

import {styles} from './Artist.style';
import ArtistScreenContents from './ArtistScreenContents';
import Header from '@/components/Header';
import {TouchableOpacity} from 'react-native';
import Space from '@/components/Space';
import Icon from '@/components/Icon';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useCanEditArtist} from '@/hooks/useCanEdit';
import AsyncContent from '@/components/AsyncContent';
import ArtistInfo from './components/ArtistInfo/ArtistInfo';

const ArtistScreen: FC<{}> = () => {
  const {
    params: {slugOrId, tab = 'tracks'},
  } = useRoute<RouteProp<MainStackParams, Routes.Artist>>();
  const navigation = useNavigation<MainStackNavigationParams>();

  const {
    artist,
    query: {isLoading: isArtistLoading},
  } = useArtistBySlugQuery(slugOrId);
  const {artistThemes} = useCustomThemesConfigQuery();
  const {theme} = useThemedScreenStyles(styles, {
    customTheme: artist?.customTheme,
    predefinedThemeName:
      artist && (artist.predefinedThemeName || artistThemes[artist.id]),
  });

  const changeTab = (newTab: string) => {
    navigation.setParams({tab: newTab});
  };
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const goToThemeEdit = () =>
    navigation.navigate(Routes.ArtistThemeEdit, {slugOrId});
  const isOwnProfile = useCanEditArtist(artist);

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header
          title={artist?.name}
          showBack
          rightActions={
            isOwnProfile && (
              <TouchableOpacity onPress={goToThemeEdit}>
                <Space p="xs">
                  <Icon name="palette" provider="custom" size={20} />
                </Space>
              </TouchableOpacity>
            )
          }
          scrollPosition={scrollPosition}
          backgroundColor={artist ? 'backgroundDark' : undefined}
        />
        <AsyncContent
          data={artist}
          isLoading={isArtistLoading}
          isError={!artist}
          renderContent={loadedArtist => (
            <ArtistScreenContents
              artist={loadedArtist}
              tabControl={{tab, changeTab}}
              onScroll={onScroll}
              artistInfo={
                <ArtistInfo
                  artist={loadedArtist}
                  isOwnProfile={isOwnProfile}
                  linkEnabled={false}
                />
              }
            />
          )}
        />
      </Screen>
    </ThemedScreen>
  );
};

export default ArtistScreen;
