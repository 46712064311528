import React from 'react';
import Modal from '@/components/Modal/Modal';

import {LocalThemeProvider, useThemedStyles} from '@/theme';

import {styles} from './common.styles';
import {ITrack} from '@/types/common';
import TrackScreenContents from '@/screens/Track/TrackScreenContents';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import useOnUnfocus from '@/hooks/useOnUnfocus';
import TrackInfo from '@/screens/Track/TrackInfo';

interface ITrackModalProps {
  track: ITrack;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const TrackModal: React.FC<ITrackModalProps> = props => {
  const {track, isOpen, setIsOpen} = props;
  const style = useThemedStyles(styles);
  const {artistThemes} = useCustomThemesConfigQuery();
  useOnUnfocus(setIsOpen);

  return (
    <LocalThemeProvider
      customTheme={track.artist.customTheme}
      predefinedThemeName={
        track.artist.predefinedThemeName || artistThemes[track.artist.id]
      }>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        contentStyle={style.modal}>
        <TrackScreenContents
          track={track}
          trackInfo={
            <TrackInfo
              key={track.id}
              track={track}
              linkEnabled={true}
              showLoader={false}
              description={track.description}
            />
          }
        />
      </Modal>
    </LocalThemeProvider>
  );
};

export default TrackModal;
