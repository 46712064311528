import {useQuery} from '@tanstack/react-query';

import {estimateGas} from '@/modules/Wallets/passkeyWallet';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {
  estimateGasLimit as estimateEOAGasLimit,
  getChainClient,
} from '@/utils/ethereum';

interface IUseGasEstimationInput {
  chainId?: number;
  wallet?: IAddress;
  transaction?: {
    to: string;
    data: string;
    value?: string;
  };
  predefinedGasLimit?: bigint | string;
}

export const useGasEstimation = (
  {
    chainId,
    wallet,
    transaction,
    predefinedGasLimit: _predefinedGasLimit,
  }: IUseGasEstimationInput,
  enabled = true,
) => {
  const query = useQuery({
    queryKey: [QueryKeys.gasPrice, chainId, transaction?.data, wallet?.address],
    queryFn: async () => {
      if (!transaction || !chainId) {
        return Promise.reject(
          'Transaction params not provided to useGasEstimation query function',
        );
      }

      if (!wallet || !wallet.isPasskey) {
        const client = getChainClient(chainId);
        const from =
          wallet?.address || '0x3470b2cb9Ae4a1147fd1beBA60410C587045D319';
        const predefinedGasLimit = _predefinedGasLimit
          ? BigInt(_predefinedGasLimit)
          : undefined;

        const [gasLimit, {maxFeePerGas = BigInt(1)}] = await Promise.all([
          predefinedGasLimit ||
            estimateEOAGasLimit(chainId, {
              from,
              to: transaction.to,
              data: transaction.data,
            }),
          client.estimateFeesPerGas(),
        ]);

        return maxFeePerGas * gasLimit;
      } else {
        if (!wallet.metadata?.spinampWallet?.signer) {
          const missingSignerError = new Error(
            `signer metadata missing on wallet: ${wallet.address}`,
          );
          Sentry.captureException(missingSignerError);
          throw missingSignerError;
        }

        return estimateGas({
          address: wallet.address,
          signer: wallet.metadata.spinampWallet.signer,
          chainId: chainId,
          transaction: {
            to: transaction.to,
            data: transaction.data,
            value: transaction.value,
          },
        });
      }
    },
    enabled: enabled && !!transaction && !!chainId,
    refetchInterval: 10000,
    keepPreviousData: false,
    cacheTime: 0,
  });

  return {
    totalGas: query.data,
    isFetching: query.isFetching,
  };
};
