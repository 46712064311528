import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  contentContainerStyle: {
    flexGrow: 1,
    paddingHorizontal: spacing.s,
    paddingVertical: spacing.xs,
    gap: spacing.xs,
  },
  link: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.s,
  },
}));
