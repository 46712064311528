import React, {FC, ReactNode} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import Card from '@/components/Card/Card';
import Icon from '@/components/Icon/Icon';
import {getHitSlop} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {isAndroid} from '@/utils/platform';

import {styles} from './Toast.style';

interface IProps {
  children: ReactNode;
  onClose: () => void;
  closeOnPress?: boolean;
}

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity);

const getLayoutAnimation = <AnimationType,>(animation: AnimationType) => {
  if (isAndroid) {
    return undefined;
  }

  return animation;
};

const Toast: FC<IProps> = ({children, onClose, closeOnPress}) => {
  const style = useThemedStyles(styles);

  return (
    <AnimatedTouchableOpacity
      onPress={onClose}
      disabled={!closeOnPress}
      activeOpacity={1}
      entering={getLayoutAnimation(FadeIn)}
      exiting={getLayoutAnimation(FadeOut)}>
      <Card inverted style={style.toast}>
        <View style={style.content}>{children}</View>

        <TouchableOpacity onPress={onClose} hitSlop={getHitSlop('s')}>
          <Icon name="close" color="invertedTextColor" size={14} />
        </TouchableOpacity>
      </Card>
    </AnimatedTouchableOpacity>
  );
};

export default Toast;
