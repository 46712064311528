import {useNavigation} from '@react-navigation/native';
import React, {FC, useMemo, useState} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';

import Avatar from '@/components/Avatar';
import Collapse from '@/components/Collapse';
import {useDrawer} from '@/components/DrawerMenu';
import Icon from '@/components/Icon/Icon';
import RotatedView from '@/components/RotatedView';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {getHitSlop} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getUserAvatar, getUserDisplayName, getUserSlug} from '@/utils/user';

import {styles, AVATAR_SMALL_SIZE, AVATAR_SIZE} from './UsersMenu.style';
import {useUsersMenu} from './useUsersMenu';

const UsersMenu: FC = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const {onDrawerNavigation, closeDrawer} = useDrawer();
  const [isProfilePickerOpen, setIsProfilePickerOpen] = useState(false);

  const {activeUser, users, switchUser, openLoginModal, signOut} =
    useUsersMenu();

  const thumbnailUsers = useMemo(
    () => users.filter(u => u.id !== activeUser?.id).slice(0, 2),
    [activeUser, users],
  );

  const openCollectorProfile = () => {
    if (activeUser) {
      onDrawerNavigation(() =>
        navigation.navigate(Routes.MainNavigation, {
          screen: Routes.Collector,
          params: {
            slug: getUserSlug(activeUser),
          },
        }),
      );
    }
  };

  if (activeUser) {
    return (
      <>
        <Space ph="xs" pt="xs" pb="s">
          <View style={style.avatarContainer}>
            <TouchableOpacity
              onPress={openCollectorProfile}
              activeOpacity={0.8}>
              <Avatar
                id={activeUser.id}
                url={getUserAvatar(activeUser)}
                size={AVATAR_SIZE}
                resizeWidth={ImageSize.avatar}
              />
            </TouchableOpacity>
            <Space flex />
            {thumbnailUsers.map((thumbnailUser, index) => (
              <React.Fragment key={thumbnailUser.id}>
                {index > 0 && <Space w="s" />}
                <TouchableOpacity
                  hitSlop={getHitSlop('xxs')}
                  onPress={() => switchUser(thumbnailUser.id)}
                  activeOpacity={0.8}>
                  <Avatar
                    id={thumbnailUser.id}
                    url={getUserAvatar(thumbnailUser)}
                    size={0.6 * AVATAR_SIZE}
                    resizeWidth={ImageSize.avatar}
                  />
                </TouchableOpacity>
              </React.Fragment>
            ))}
            <Space ml="xs" />
            <TouchableOpacity onPress={() => setIsProfilePickerOpen(v => !v)}>
              <RotatedView isRotated={isProfilePickerOpen} angle={-90}>
                <Space p="xs">
                  <Icon name="options-vertical" size={14} />
                </Space>
              </RotatedView>
            </TouchableOpacity>
          </View>
          <Space mt="s" />
          <TouchableOpacity onPress={openCollectorProfile} activeOpacity={0.8}>
            <Text weight="semibold" size="s">
              {getUserDisplayName(activeUser)}
            </Text>
          </TouchableOpacity>
        </Space>
        <Collapse isOpen={isProfilePickerOpen}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            bounces={false}
            style={style.profilePicker}
            contentContainerStyle={style.profilePickerContainer}>
            {users.map(user => {
              const isActive = user.id === activeUser?.id;

              return (
                <TouchableOpacity
                  disabled={isActive}
                  onPress={() => {
                    switchUser(user.id);
                    setIsProfilePickerOpen(false);
                  }}
                  activeOpacity={0.6}
                  style={style.profilePickerItem}
                  key={user.id}>
                  <Avatar
                    url={getUserAvatar(user)}
                    id={user.id}
                    size={AVATAR_SMALL_SIZE}
                    resizeWidth={ImageSize.avatar}
                  />
                  <Space ml="xs" />
                  <Text size="xs" weight={isActive ? 'semibold' : 'regular'}>
                    {getUserDisplayName(user)}
                  </Text>
                  {isActive && (
                    <>
                      <Space flex ml="xs" />
                      <Icon name="check" size={14} />
                    </>
                  )}
                </TouchableOpacity>
              );
            })}
            <TouchableOpacity
              onPress={openLoginModal}
              activeOpacity={0.6}
              style={style.profilePickerItem}>
              <Text
                size="xs"
                weight="semibold"
                id="login.addAnotherAccount"
                numberOfLines={1}
                flex
              />
              <Space w="xs" />
              <Icon name="add" provider="custom" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                signOut();
                closeDrawer();
              }}
              activeOpacity={0.6}
              style={style.profilePickerItem}>
              <Text
                size="xs"
                weight="semibold"
                id="menu.signOut"
                values={{
                  user: getUserDisplayName(activeUser),
                }}
                numberOfLines={1}
                flex
              />
              <Space w="xs" />
              <Icon name="signout" provider="custom" />
            </TouchableOpacity>
          </ScrollView>
        </Collapse>
      </>
    );
  }

  return (
    <TouchableOpacity style={style.loginButton} onPress={openLoginModal}>
      <View style={style.iconWrapper}>
        <Icon provider="custom" name="user" size={18} />
      </View>
      <Text size="s" weight="semibold" id="login.login" />
    </TouchableOpacity>
  );
};

export default UsersMenu;
