import spacing from '@/constants/spacing';
import {createThemedStyles, isThemeMonochrome} from '@/theme';

export const FEED_CARD_WIDTH = 300;
export const FEED_CARD_HEIGHT = 400;

export const styles = createThemedStyles(theme => ({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.activeBorder,
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    paddingVertical: 0,

    flex: 1,
    flexBasis: FEED_CARD_HEIGHT,

    maxHeight: FEED_CARD_HEIGHT,
    width: FEED_CARD_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flexContainer: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
