import {useQueries} from '@tanstack/react-query';

import {config} from '@/constants/config';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {fetchTopUpStatus} from '@/modules/TopUp/ramp/shared';
import {IPurchaseDetails} from '@/modules/TopUp/types';
import {removeTopUpTransaction, selectTopUpTransactions} from '@/store/topUp';
import {QueryKeys} from '@/types/queryKeys';
import {isNotNil} from '@/utils/functions';

export const useTopUpTransactionsQuery = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(selectTopUpTransactions);

  const results = useQueries({
    queries: transactions.map(transaction => ({
      queryKey: [QueryKeys.topUpStatus, transaction.id],
      queryFn: async () => {
        const topUpDetails = await fetchTopUpStatus({
          id: transaction.id,
          token: transaction.token,
        });

        if (
          topUpDetails.status === 'EXPIRED' ||
          topUpDetails.status === 'CANCELLED'
        ) {
          dispatch(removeTopUpTransaction({id: transaction.id}));
        }

        const purchaseDetails: IPurchaseDetails = {
          chainId: transaction.chainId,
          url: `${config.RAMP_TX_URL}/${transaction.id}?secret=${transaction.token}`,
          ...topUpDetails,
        };

        return purchaseDetails;
      },
      staleTime: 5 * 1000,
      refetchInterval: 5 * 1000,
    })),
  });

  return results
    .map(result => result.data)
    .filter(isNotNil)
    .filter(({status}) => status !== 'INITIALIZED');
};
