import {useRoute} from '@react-navigation/native';
import React, {FC} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import Share from '@/components/Share';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useCreatePlaylist} from '@/queries/ownedPlaylists';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {selectUserPlaylists} from '@/store/playlists';
import {selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {ImageSize} from '@/types/media';
import {PlaylistType} from '@/types/playlists';
import {RootStackParams, Routes} from '@/types/routes';
import {getSpinampWrappedUrl} from '@/utils/share';
import {getUserDisplayName} from '@/utils/user';

const Summary: FC = () => {
  const theme = useTheme();
  const {showToast} = useToast();
  const {createPlaylistMutation} = useCreatePlaylist();
  const {close, tracks, activePageId, user} = useSpinampWrapped();
  const {params} = useRoute<RootStackParams, Routes.SpinampWrapped>();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const userPlaylists = useAppSelector(selectUserPlaylists);

  useSpinampWrappedAudio(activePageId === 'summary', tracks[0].track);

  const saveAsPlaylist = () => {
    if (!user) {
      return;
    }
    const title = `${getUserDisplayName(user)} Wrapped 2023`;

    if (userPlaylists.some(playlist => playlist.title === title)) {
      showToast({textId: 'spinampWrapped.summary.alreadyInLibrary'});
      return;
    }

    createPlaylistMutation({
      title: `${getUserDisplayName(user)} Wrapped 2023`,
      trackIds: tracks.map(({track}) => track.id),
      type: PlaylistType.custom,
    });
    showToast({textId: 'spinampWrapped.summary.saved'});
  };

  return (
    <ScrollView
      bounces={false}
      showsVerticalScrollIndicator={false}
      style={{flex: 1}}
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
        paddingHorizontal: spacing.m,
        paddingBottom: spacing.l,
      }}>
      <View style={{height: '15%'}} />
      {user && user.id !== activeUserId ? (
        <Text
          align="center"
          weight="bold"
          size="l"
          id="spinampWrapped.summary.title"
          values={{user: getUserDisplayName(user)}}
        />
      ) : (
        <Text
          align="center"
          weight="bold"
          size="l"
          id="spinampWrapped.summary.title.own"
        />
      )}

      <Space h="xs" />

      <Text align="center" id="spinampWrapped.summary.description" />

      <Space h="l" />

      <View
        style={{
          flexDirection: 'row',
          width: '80%',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
        {tracks.map(({track}, index) => (
          <View
            key={track.id}
            style={{flexBasis: '20%', maxWidth: 75, aspectRatio: 1}}>
            <FadeInOut
              maxOpacity={0.8}
              delay={index * 150}
              style={{
                ...StyleSheet.absoluteFillObject,
                backgroundColor: theme.colors.backgroundDark,
              }}
            />
            <StaticImage
              source={{uri: track.lossyArtworkUrl}}
              resizeWidth={ImageSize.thumbnail}
              style={{width: '100%', height: '100%'}}
            />
          </View>
        ))}
      </View>

      <Space h="m" />

      <CardButton
        style={{height: spacing.xl, paddingHorizontal: spacing.s}}
        onPress={saveAsPlaylist}
        text={{
          id: 'spinampWrapped.summary.save',
          size: 'xs',
        }}
      />

      <Space h="xxl" />
      <Share url={getSpinampWrappedUrl(params?.userSlugOrId || activeUserId)}>
        {({onPress, icon}) => (
          <TransparentButton
            onPress={onPress}
            icon={{...icon, size: 18}}
            text={{id: 'spinampWrapped.summary.share', size: 'm'}}
          />
        )}
      </Share>
      <Space mv="s">
        <Text size="xs" id="spinampWrapped.summary.or" />
      </Space>
      <TransparentButton
        onPress={close}
        text={{id: 'spinampWrapped.summary.close', size: 's'}}
      />
    </ScrollView>
  );
};

export default Summary;
