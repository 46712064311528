import React, {FC} from 'react';

import {IIconProps} from '@/components/Icon';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {
  WalletsPicker,
  AddExternalWalletModal,
  AddPasskeyWalletModal,
} from '@/modules/Wallets';

const modalCloseIcon: IIconProps = {
  name: 'arrowLeft',
  provider: 'custom',
};

const CollectModalsRouter: FC = () => {
  const {
    route,
    setRoute,
    goBack,

    user,
    selectedOffer,
    walletsSettings,
    setWalletsSettings,
  } = useCollectState();

  if (route === 'walletsPicker' && user && walletsSettings) {
    return (
      <WalletsPicker
        isOpen
        onClose={goBack}
        user={user}
        settings={walletsSettings}
        chainId={selectedOffer?.chainId}
        onAddExternal={() => setRoute('addExternalWallet')}
        onAddPasskey={() => setRoute('addPasskeyWallet')}
        onConfirm={settings => {
          setWalletsSettings(settings);
          goBack();
        }}
      />
    );
  }

  if (route === 'addPasskeyWallet') {
    return (
      <AddPasskeyWalletModal
        isOpen
        onClose={goBack}
        closeIcon={modalCloseIcon}
      />
    );
  }

  if (route === 'addExternalWallet') {
    return (
      <AddExternalWalletModal
        isOpen
        onClose={goBack}
        closeIcon={modalCloseIcon}
      />
    );
  }

  return null;
};

export default CollectModalsRouter;
