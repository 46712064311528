import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import Touchable from '@/components/Touchable/Touchable';
import {IUserMenuProps} from '@/components/UsersMenu';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getUserAvatar, getUserDisplayName, getUserSlug} from '@/utils/user';

import {styles} from './ProfilePicker.style';

interface IProps extends IUserMenuProps {
  isProfileMenuOpen: boolean;
  openProfileMenu: () => void;
}

const ProfilePicker: FC<IProps> = ({
  activeUser,
  openLoginModal,
  isProfileMenuOpen,
  openProfileMenu,
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const {isSmallDesktop} = useResponsive();
  const style = useThemedStyles(styles);

  const pickerOpenerStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate: withTiming(`${Number(isProfileMenuOpen) * 90}deg`, {
            duration: 200,
          }),
        },
      ],
    }),
    [isProfileMenuOpen],
  );

  if (!activeUser) {
    return (
      <>
        <Touchable
          style={style.profilePicker}
          hoveredStyle={style.profilePicker_hover}
          pressedStyle={style.profilePicker_pressed}
          onPress={openLoginModal}>
          <View style={style.profilePickerIcon}>
            <Icon provider="custom" name="user" size={18} />
          </View>
          <Text weight="semibold" id="login.login" />
        </Touchable>
      </>
    );
  }

  return (
    <>
      <Touchable
        style={style.profilePicker}
        hoveredStyle={style.profilePicker_hover}
        pressedStyle={style.profilePicker_pressed}
        onPress={() => {
          if (isSmallDesktop) {
            openProfileMenu();
          } else {
            navigation.navigate(Routes.MainNavigation, {
              screen: Routes.Collector,
              params: {
                slug: getUserSlug(activeUser),
              },
            });
          }
        }}>
        <View style={style.avatar}>
          <Avatar
            id={activeUser.id}
            url={getUserAvatar(activeUser)}
            size={32}
            resizeWidth={ImageSize.avatar}
          />
        </View>
        <Text weight="semibold">{getUserDisplayName(activeUser)}</Text>
        <Space ml="xs" flex />
        <Touchable
          onPress={openProfileMenu}
          style={style.profileMenuOpener}
          hoveredStyle={style.profileMenuOpener_hover}
          pressedStyle={style.profileMenuOpener_press}>
          <Animated.View style={pickerOpenerStyle}>
            <Icon name="options-vertical" size={16} />
          </Animated.View>
        </Touchable>
      </Touchable>
    </>
  );
};

export default ProfilePicker;
