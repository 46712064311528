import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PillSize = spacing.l + spacing.xs;
const subcategorySpacing = spacing.xxs / 2;

export const styles = createThemedStyles(theme => ({
  scrollContainer: {
    flexGrow: 0,
    width: '100%',
  },
  scrollContent: {
    paddingHorizontal: spacing.xxs,
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs + spacing.xxs,
    marginHorizontal: 0,
    flexGrow: 1,
  },
  category: {
    marginHorizontal: spacing.xxs,
    flexDirection: 'row',
  },
  categoryBackground: {
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,
    left: -1,
    backgroundColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
  },
  subcategories: {
    flexDirection: 'row',
    marginLeft: subcategorySpacing,
    marginRight: -subcategorySpacing,
  },
  subcategory: {
    marginHorizontal: subcategorySpacing,
  },
  pill: {
    height: PillSize,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundLight,
  },
  closeIcon: {
    width: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
