import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  centeredText: {
    textAlign: 'center',
  },
  featureContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  actionLabels: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  actionLink: {
    textAlign: 'center',
  },
  featureIcon: {
    alignSelf: 'center',
  },
  refillButton: {
    minWidth: 105,
  },
  genreContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: spacing.xs,
    gap: spacing.xs,
    paddingBottom: spacing.l,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -spacing.l,
    height: spacing.l,
    pointerEvents: 'none',
  },
  gradientContainer: {
    height: spacing.l,
  },
  infoContainer: {
    padding: spacing.xs,
    alignItems: 'flex-start',
    borderTopWidth: 0,
    borderColor: theme.colors.borderColor,
  },
  imageContainer: {
    height: '100%',
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden',
    borderTopRightRadius: Math.max(0, theme.box.borderRadius - 3),
    borderTopLeftRadius: Math.max(0, theme.box.borderRadius - 3),
  },
}));
