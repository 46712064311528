import {
  createSmartAccountClient,
  isSmartAccountDeployed,
  waitForUserOperationReceipt,
  ENTRYPOINT_ADDRESS_V06,
  bundlerActions,
  getAccountNonce,
} from 'permissionless';
import {KernelEcdsaSmartAccount} from 'permissionless/accounts';
import {pimlicoBundlerActions} from 'permissionless/actions/pimlico';
import {ENTRYPOINT_ADDRESS_V06_TYPE} from 'permissionless/types';
import {
  Address,
  Client,
  concatHex,
  createClient,
  encodeFunctionData,
  Hex,
  http,
  SendTransactionParameters,
} from 'viem';
import {privateKeyToAccount} from 'viem/accounts';

import CreateAccountABI from '@/abi/CreateAccountABI.json';
import GasEstimationABI from '@/abi/GasEstimationABI.json';
import KernelInitABI from '@/abi/KernelInitABI.json';
import {chainsByCode, getChainById} from '@/constants/chains';
import {config} from '@/constants/config';
import {AddressString} from '@/types/common';
import {IInternalSigner} from '@/types/session';
import {getChainClient} from '@/utils/ethereum';

// There is an issue in permissionless which breaks importing functions from nested directories:
// https://github.com/pimlicolabs/permissionless.js/issues/61
// We added custom wrapper which exports it as usual on web (with proper types support) and export compiled version on native.
import {signerToEcdsaKernelSmartAccount} from './permissionless';
export const KERNEL_ADDRESSES: {
  ACCOUNT_V2_2_LOGIC: AddressString;
  FACTORY_ADDRESS: AddressString;
  ECDSA_VALIDATOR: Address;
} = {
  ACCOUNT_V2_2_LOGIC: '0x0DA6a956B9488eD4dd761E59f52FDc6c8068E6B5',
  FACTORY_ADDRESS: '0x5de4839a76cf55d0c90e2061ef4386d962E15ae3',
  ECDSA_VALIDATOR: '0xd9AB5096a832b9ce79914329DAEE236f8Eea0390',
};

const getTransportUrl = (chainId: number) =>
  `${config.PIMLICO_API_URL}/${chainId}/rpc?apikey=${config.PIMLICO_API_KEY}`;

const getKernelAccountFromPrivateKey = async (
  privateKey: string,
  chainId: number,
) => {
  const publicClient = getChainClient(chainId);
  const signer = privateKeyToAccount(privateKey as AddressString);

  return signerToEcdsaKernelSmartAccount(publicClient, {
    entryPoint: ENTRYPOINT_ADDRESS_V06,
    factoryAddress: KERNEL_ADDRESSES.FACTORY_ADDRESS,
    accountLogicAddress: KERNEL_ADDRESSES.ACCOUNT_V2_2_LOGIC,
    signer: signer,
  });
};

export const predictSpinampWallet = async (
  privateKey: string,
): Promise<IInternalSigner> => {
  // generated wallet has the same address across chains in like 99% of cases
  const account = await getKernelAccountFromPrivateKey(
    privateKey,
    chainsByCode.optimism.id,
  );

  return {
    address: account.address,
    signMessage: message => account.signMessage({message}),
    sessionKey: privateKey,
  };
};

export const getSmartAccountClient = async ({
  privateKey,
  chainId,
}: {
  privateKey: string;
  chainId: number;
}) => {
  const chainConfig = getChainById(chainId);

  const account = await getKernelAccountFromPrivateKey(privateKey, chainId);

  return createSmartAccountClient<
    KernelEcdsaSmartAccount<ENTRYPOINT_ADDRESS_V06_TYPE>
  >({
    account: account,
    chain: chainConfig.chain,
    bundlerTransport: http(chainConfig.rpcUrl),
  });
};

interface ISendTransactionParams {
  privateKey: string;
  chainId: number;
  transaction: Pick<SendTransactionParameters, 'to' | 'value' | 'data'>;
}

export const sendTransaction = async ({
  privateKey,
  chainId,
  transaction,
}: ISendTransactionParams) => {
  const smartAccount = await getSmartAccountClient({privateKey, chainId});

  return smartAccount.sendUserOperation({
    userOperation: {
      callData: await smartAccount.account!.encodeCallData({
        to: transaction.to!,
        value: transaction.value || 0n,
        data: transaction.data || '0x',
      }),
    },
  });
};

export const waitForReceipt = async ({
  privateKey,
  chainId,
  hash,
}: {
  privateKey: string;
  chainId: number;
  hash: AddressString;
}) => {
  const smartAccount = await getSmartAccountClient({privateKey, chainId});

  const {receipt, logs} = await waitForUserOperationReceipt(smartAccount, {
    hash,
  });

  return {
    ...receipt,
    logs,
  };
};

//Get the account initialization code for a kernel smart account
const getAccountInitCode = async ({
  owner,
  index,
  accountLogicAddress,
  ecdsaValidatorAddress,
}: {
  owner: Address;
  index: bigint;
  accountLogicAddress: Address;
  ecdsaValidatorAddress: Address;
}): Promise<Hex> => {
  if (!owner) {
    throw new Error('Owner account not found');
  }

  // Build the account initialization data
  const initialisationData = encodeFunctionData({
    abi: KernelInitABI.abi,
    functionName: 'initialize',
    args: [ecdsaValidatorAddress, owner],
  });

  // Build the account init code
  return encodeFunctionData({
    abi: CreateAccountABI.abi,
    functionName: 'createAccount',
    args: [accountLogicAddress, initialisationData, index],
  });
};

// Helper to generate the init code for the smart account
const generateInitCode = (address: Address) =>
  getAccountInitCode({
    owner: address,
    index: 0n,
    accountLogicAddress: KERNEL_ADDRESSES.ACCOUNT_V2_2_LOGIC,
    ecdsaValidatorAddress: KERNEL_ADDRESSES.ECDSA_VALIDATOR,
  });

export async function getInitCode(
  signerAddress: Address,
  deployedAddress: Address,
  client: Client,
) {
  const smartAccountDeployed = await isSmartAccountDeployed(
    client,
    deployedAddress,
  );

  if (smartAccountDeployed) {
    return '0x';
  }

  return concatHex([
    KERNEL_ADDRESSES.FACTORY_ADDRESS,
    await generateInitCode(signerAddress),
  ]);
}

interface IEstimateGasInput {
  address: string;
  signer: string;
  chainId: number;
  transaction: {
    to: string;
    data: string;
    value?: string;
  };
}
export const estimateGas = async ({
  address,
  signer,
  chainId,
  transaction,
}: IEstimateGasInput) => {
  const client = getChainClient(chainId);

  const bundlerClient = createClient({
    chain: getChainById(chainId).chain,
    transport: http(getTransportUrl(chainId)),
  })
    .extend(bundlerActions(ENTRYPOINT_ADDRESS_V06))
    .extend(pimlicoBundlerActions(ENTRYPOINT_ADDRESS_V06));

  const [nonce, initCode, gasPricesResult] = await Promise.all([
    getAccountNonce(client, {
      sender: address as AddressString,
      entryPoint: ENTRYPOINT_ADDRESS_V06,
    }),
    getInitCode(signer as AddressString, address as AddressString, client),
    bundlerClient.getUserOperationGasPrice(),
  ]);

  const gasPrices = gasPricesResult.fast;

  const userOperation = {
    nonce,
    sender: address as AddressString,
    callData: encodeFunctionData({
      abi: GasEstimationABI.abi,
      functionName: 'execute',
      args: [
        transaction.to as AddressString,
        BigInt(transaction.value || 0),
        transaction.data as AddressString,
        0,
      ],
    }),
    initCode,
    paymasterAndData: '0x',
    preVerificationGas: '0x0',
    // this is a fake signature. the data doesn't matter but the shape does
    signature:
      '0x00000000fffffffffffffffffffffffffffffff0000000000000000000000000000000007aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa1c' as any,
    maxFeePerGas: gasPrices.maxFeePerGas,
    maxPriorityFeePerGas: gasPrices.maxPriorityFeePerGas,
  };

  const {callGasLimit, preVerificationGas, verificationGasLimit} =
    await bundlerClient.estimateUserOperationGas({
      userOperation: userOperation as any, // as any so it doesn't complain about the missing maxFeePerGas and maxPriorityFeePerGas
    });

  const gasRequired = callGasLimit + preVerificationGas + verificationGasLimit;

  return gasRequired * gasPrices.maxFeePerGas;
};
