import React from 'react';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './BaseFeedCard.styles';

interface IBaseFeedCardProps {
  children?: React.ReactNode;
}

const BaseFeedCard: React.FC<IBaseFeedCardProps> = ({children}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <View style={style.flexContainer}>{children}</View>
    </View>
  );
};

export default BaseFeedCard;
