import {useQuery} from '@tanstack/react-query';

import {useAppSelector} from '@/hooks/useRedux';
import {fetchCollectInfoForTrack} from '@/modules/Collect/api/collect';
import {selectActiveUserSigner} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';

export interface ICollectOfferParams {
  trackId: string;
  userAddress?: string;
  quantity?: number;
  referralAddress?: string;
}

export const useTrackCollectInfoQuery = (
  {trackId, userAddress, referralAddress, quantity = 1}: ICollectOfferParams,
  keepPreviousData = false,
) => {
  const internalAddress = useAppSelector(selectActiveUserSigner)?.address;

  // fallback to internal address to show offer data even before signing in
  const address = userAddress || internalAddress;

  const query = useQuery({
    queryKey: [QueryKeys.collectInfoForTrack, trackId, address, quantity],
    queryFn: () =>
      fetchCollectInfoForTrack(trackId, address!, quantity, referralAddress),
    enabled: !!address,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 1000 * 60,
    staleTime: 0,
    keepPreviousData,
  });

  return {
    collectInfo: query.data,
    query,
  };
};
