import {useNavigation} from '@react-navigation/native';
import {UseInfiniteQueryResult} from '@tanstack/react-query';
import React, {useCallback, useEffect, useMemo} from 'react';
import {View} from 'react-native';

import {setFeedItemUserId} from '../utils';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton/IconButton';
import InfinityList from '@/components/InfinityList';
import {useInfinityList} from '@/components/InfinityList/useInfinityList';
import Loader from '@/components/Loader';
import OpacityGradient from '@/components/OpacityGradient';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {MINUTE, SECOND} from '@/constants/time';
import {OnScroll} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useFeedItemMutation, useRegeneratingFeedQuery} from '@/queries/feed';
import {Sentry} from '@/services/sentry';
import {setShouldPlay as setFeedShouldPlay} from '@/store/feed';
import {
  playNewQueue,
  playNextTrack,
  selectCurrentTrackId,
  removeTrackFromQueueByTrackId,
  selectIsPlaying,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IArtistWithTracks, ITrack, PlayContextType} from '@/types/common';
import {IFeedEntityType, IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {isTruthy} from '@/utils/functions';

import {styles} from './Feed.styles';
import {GenericLocalMessageFeedItem} from './FeedCard/LocalMessageFeedCard';
import FeedCardInline, {FEED_CARD_INLINE_HEIGHT} from './FeedCardInline';
import FeedCarousel, {CAROUSEL_HEIGHT} from './FeedCarousel/FeedCarousel';
import {
  useFeedItemsWithPinnedCards,
  useFeedItemsWithArtificialCards,
} from './useFeedItemsWithArtificialCards';
import {useTabs} from './useTabs';

interface IFeedProps {
  userId: string;
  onScroll: OnScroll;
  titleId?: string;
  hideActions?: boolean;
}

const newFeedConditions = {userAction: null} as const;
const hidesFeedConditions = {userAction: 'hide'} as const;
const likesFeedConditions = {userAction: 'like'} as const;

const getTrackIds = (feedItems: IFeedItem[] | null) =>
  feedItems
    ?.map(item => item.entityType === 'track' && item.entityId)
    .filter(isTruthy);

const Feed: React.FC<IFeedProps> = ({
  userId,
  onScroll,
  titleId = 'feed.title',
  hideActions = false,
}) => {
  const dispatch = useAppDispatch();

  const navigation = useNavigation<MainStackNavigationParams>();

  const style = useThemedStyles(styles);
  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);

  useEffect(() => {
    if (isMainPlayerPlaying) {
      dispatch(setFeedShouldPlay(false));
    }
  }, [isMainPlayerPlaying]);

  const {
    feedItems,
    trackFeedItems,
    artistFeedItems,
    query: feedQuery,
  } = useRegeneratingFeedQuery(userId, newFeedConditions, {
    staleTime: 10 * SECOND,
  });

  const {onEndReached} = useInfinityList({
    fetchNextPage: feedQuery.fetchNextPage,
    isFetchingNextPage: feedQuery.isFetchingNextPage,
    hasNextPage: feedQuery.hasNextPage,
  });

  const {feedItems: hiddenFeedItems, query: hiddenFeedQuery} =
    useRegeneratingFeedQuery(userId, hidesFeedConditions, {
      staleTime: 5 * MINUTE,
    });

  const {feedItems: likedFeedItems, query: likedFeedQuery} =
    useRegeneratingFeedQuery(userId, likesFeedConditions, {
      staleTime: 5 * MINUTE,
    });

  const {tab, changeTab} = useTabs();

  // used only for hides and likes
  const onPlay = useCallback(
    (playContext: PlayContextType, queueTrackIds?: string[]) =>
      (track: ITrack) => {
        if (!queueTrackIds || queueTrackIds.length === 0) {
          return;
        }

        dispatch(
          playNewQueue({
            trackIds: queueTrackIds,
            trackId: track.id,
            context: {
              source: 'Feed',
              type: playContext,
              titleId,
            },
          }),
        );
      },
    [trackFeedItems],
  );

  const onArtistTrackPlay = useCallback(
    (artist: IArtistWithTracks) => (track: ITrack) => {
      if (!artist.tracks.find(t => t.id === track.id)) {
        Sentry.captureException(
          `Could not play track with ID ${track.id} onArtistTrackPlay`,
        );
      }

      if (!artist.tracks) {
        return;
      }
      dispatch(
        playNewQueue({
          trackId: track.id,
          trackIds: artist.tracks.map(t => t.id),
          context: {
            source: 'Feed',
            type: PlayContextType.feedArtist,
            titleId,
          },
        }),
      );
    },
    [artistFeedItems],
  );

  const toast = useToast();

  const feedItemMutation = useFeedItemMutation({
    onError: _error => {
      console.error('feedItemMutation', _error);
      toast.showToast({textId: 'feed.genericError'});
    },
  });

  const {feedItemsWithActiveCard, pinFeedItem, unpinFeedItem} =
    useFeedItemsWithPinnedCards(feedItems);

  const feedItemsWithOnboarding = useFeedItemsWithArtificialCards(
    feedItemsWithActiveCard,
    userId,
  );
  const currentTrackId = useAppSelector(selectCurrentTrackId);

  const toggleLike = useCallback(
    (feedItem: IFeedItem | ILocalMessageFeedItem) => {
      if (feedItem.entityType === 'localMessage') {
        return;
      }

      const action = feedItem.userAction === 'like' ? null : 'like';

      // We want to keep the card in the feed so the user can keep listening
      // after liking it. We add it here as an additional card, so when it gets removed as
      // per feedItemMutation.mutate(), it stays in the user's feed.
      if (action === 'like') {
        feedItem.userAction = action;
        pinFeedItem(feedItem);
      } else {
        unpinFeedItem(feedItem);
      }

      feedItemMutation.mutate({
        ...setFeedItemUserId(userId, feedItem),
        userAction: action,
      });

      analytics.feedItemReacted(feedItem.id, action, feedItem.entityType);
    },
    [feedItemMutation],
  );
  const toggleHide = useCallback(
    (feedItem: IFeedItem | ILocalMessageFeedItem) => {
      if (feedItem.entityType === 'localMessage') {
        return;
      }
      unpinFeedItem(feedItem);

      const action = feedItem.userAction === 'hide' ? null : 'hide';

      feedItemMutation.mutate({
        ...setFeedItemUserId(userId, feedItem),
        userAction: action,
      });

      analytics.feedItemReacted(feedItem.id, action, feedItem.entityType);

      if (feedItem.entityType === IFeedEntityType.track) {
        if (feedItem.entityId === currentTrackId) {
          dispatch(playNextTrack());
        }
        dispatch(removeTrackFromQueueByTrackId({trackId: feedItem.entityId}));
      }
    },
    [feedItemMutation, currentTrackId],
  );

  const keyExtractor = useCallback(
    (item: IFeedItem | ILocalMessageFeedItem) => {
      if ('localMessageId' in item) {
        return item.localMessageId;
      } else {
        return item.id;
      }
    },
    [],
  );

  const ListHeader = useMemo(
    () => <Space mh="m" mb="s" style={style.listHeader} />,
    [style],
  );

  const Tabs = useMemo(
    () => (
      <View style={style.tabs}>
        <IconButton
          onPress={() => changeTab('hides')}
          icon={{
            provider: 'custom',
            name: 'feedHides',
            size: 30,
          }}
          style={{opacity: tab === 'hides' ? 1 : 0.3}}
        />
        <IconButton
          onPress={() => changeTab('new')}
          icon={{
            provider: 'custom',
            name: 'feedVinyl',
            size: 70,
          }}
          style={{opacity: tab === 'new' ? 1 : 0.3}}
        />
        <IconButton
          onPress={() => changeTab('likes')}
          icon={{
            provider: 'custom',
            name: 'feedLikes',
            size: 30,
          }}
          style={{opacity: tab === 'likes' ? 1 : 0.3}}
        />
      </View>
    ),
    [style, tab, changeTab, feedItems?.length],
  );

  const commonProps = {
    itemSize: FEED_CARD_INLINE_HEIGHT,
    keyExtractor: keyExtractor,
    extraData: [onPlay, onArtistTrackPlay],
    contentContainerStyle: style.scrollContent,
    onScroll: onScroll,
    ListFooterComponentStyle: style.listFooterContainer,
  } as const;

  if (tab === 'hides') {
    return (
      <>
        {ListHeader}
        <View style={{height: CAROUSEL_HEIGHT}}>
          <View style={style.scrollContainer}>
            <InfinityList
              data={hiddenFeedItems}
              renderItem={({item}) => (
                <FeedCardInline
                  feedItem={item}
                  toggleLike={hideActions ? undefined : toggleLike}
                  toggleHide={hideActions ? undefined : toggleHide}
                  onTrackPlay={onPlay(
                    PlayContextType.feedHides,
                    getTrackIds(hiddenFeedItems),
                  )}
                  playContext={PlayContextType.feedHides}
                />
              )}
              fetchNextPage={() => hiddenFeedQuery.fetchNextPage()}
              hasNextPage={hiddenFeedQuery.hasNextPage}
              isFetchingNextPage={
                hiddenFeedQuery.isFetchingNextPage || hiddenFeedQuery.isLoading
              }
              ListEmptyComponent={
                // Error
                hiddenFeedQuery.isError ? (
                  <InlineErrorState
                    isFetching={hiddenFeedQuery.isFetching}
                    onRefetch={() => hiddenFeedQuery.refetch()}
                  />
                ) : hiddenFeedQuery.isLoading ? (
                  // Loading
                  <Space pv="s">
                    <Loader />
                  </Space>
                ) : (
                  // Genuinely empty
                  <GenericLocalMessageFeedItem bodyTextId="feed.empty.hides.body" />
                )
              }
              refresh={hiddenFeedQuery.refetch}
              ListFooterComponent={
                <ListFooter items={hiddenFeedItems} query={hiddenFeedQuery} />
              }
              {...commonProps}
            />
            <View style={style.gradientContainer}>
              <OpacityGradient
                color={'backgroundLight'}
                style={style.gradient}
                vertical={true}
              />
            </View>
          </View>
          {Tabs}
        </View>
      </>
    );
  }

  if (tab === 'likes') {
    return (
      <>
        {ListHeader}
        <View style={{height: CAROUSEL_HEIGHT}}>
          <View style={style.scrollContainer}>
            <InfinityList
              data={likedFeedItems}
              renderItem={({item}) => (
                <FeedCardInline
                  feedItem={item}
                  toggleLike={hideActions ? undefined : toggleLike}
                  toggleHide={hideActions ? undefined : toggleHide}
                  onTrackPlay={onPlay(
                    PlayContextType.feedLikes,
                    getTrackIds(likedFeedItems),
                  )}
                  playContext={PlayContextType.feedLikes}
                />
              )}
              fetchNextPage={() => likedFeedQuery.fetchNextPage()}
              hasNextPage={likedFeedQuery.hasNextPage}
              isFetchingNextPage={
                likedFeedQuery.isFetchingNextPage || likedFeedQuery.isLoading
              }
              ListEmptyComponent={
                // Error
                likedFeedQuery.isError ? (
                  <InlineErrorState
                    isFetching={likedFeedQuery.isFetching}
                    onRefetch={() => likedFeedQuery.refetch()}
                  />
                ) : likedFeedQuery.isLoading ? (
                  // Loading
                  <Space pv="s">
                    <Loader />
                  </Space>
                ) : (
                  // Genuinely empty
                  <GenericLocalMessageFeedItem bodyTextId="feed.empty.likes.body" />
                )
              }
              refresh={likedFeedQuery.refetch}
              ListFooterComponent={
                <ListFooter items={likedFeedItems} query={likedFeedQuery} />
              }
              {...commonProps}
            />
            <View style={style.gradientContainer}>
              <OpacityGradient
                color={'backgroundLight'}
                style={style.gradient}
                vertical={true}
              />
            </View>
          </View>
          {Tabs}
        </View>
      </>
    );
  }

  if (feedQuery.isLoading || feedItemsWithOnboarding == null) {
    return <ScreenLoader />;
  }

  if (feedQuery.isError) {
    return (
      <InlineErrorState
        isFetching={feedQuery.isFetching}
        onRefetch={() => feedQuery.refetch()}
      />
    );
  }

  return (
    <>
      {ListHeader}
      <View style={{height: CAROUSEL_HEIGHT}}>
        <FeedCarousel
          feedItems={feedItemsWithOnboarding}
          toggleHide={hideActions ? undefined : toggleHide}
          toggleLike={hideActions ? undefined : toggleLike}
          onArtistTrackPlay={onArtistTrackPlay}
          onTrackPlay={onPlay(PlayContextType.feed, getTrackIds(feedItems))}
          onEndReached={onEndReached}
        />
        {Tabs}
      </View>
    </>
  );
};

export default Feed;

interface IInlineErrorStateProps {
  isFetching: boolean;
  onRefetch: () => void;
}

const InlineErrorState: React.FC<IInlineErrorStateProps> = ({
  isFetching,
  onRefetch,
}) => {
  const style = useThemedStyles(styles);

  return (
    <Space pv="s" style={style.inlineError}>
      <ScreenError textId="error.description" textProps={{size: 's'}} />
      <Space pb="s" />
      <Button
        isLoading={isFetching}
        textId="error.reload"
        onPress={onRefetch}
      />
    </Space>
  );
};

interface IListFooter {
  items: IFeedItem[] | null;
  query: UseInfiniteQueryResult;
}

const ListFooter: React.FC<IListFooter> = ({items, query}) => {
  const style = useThemedStyles(styles);

  if (!query.isLoading && !query.isError && !(items?.length === 0)) {
    return (
      <Space style={style.listFooter}>
        <Button
          style={style.moreButton}
          isLoading={query.isFetchingNextPage}
          disabled={!query.isError && !query.hasNextPage}
          text={query.hasNextPage ? 'Load More' : 'Nothing more to load'}
          onPress={() => query.fetchNextPage()}
        />
      </Space>
    );
  }

  // The contentful error: if there is an error but data is not falsy/empty, the ListEmptyComponent
  // where the error UI is defined is not rendered.
  // This is where we show the error in this case.
  if (items && items.length !== 0 && query.isError) {
    return (
      <Space style={style.listFooter}>
        <Button
          isLoading={query.isFetching}
          onPress={query.refetch}
          textId="error.reload"
        />
        <Space pv="xxs" />
        <Text id="error.description" />
      </Space>
    );
  }
};
