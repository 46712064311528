import {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, {useMemo, useState} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {
  WalletScreen,
  WalletListScreen,
  WalletsSettingsScreen,
} from '@/modules/Wallets';
import {TabsContext} from '@/navigation/TabsNavigation/TabsContext';
import TabsNavigation from '@/navigation/TabsNavigation/TabsNavigation';
import TabsNavigationBar from '@/navigation/TabsNavigation/TabsNavigationBar';
import ActivityFeed from '@/screens/ActivityFeed/ActivityFeed';
import ArtistScreen from '@/screens/Artist/ArtistScreen';
import ArtistEdit from '@/screens/ArtistEdit/ArtistEdit';
import ArtistThemeEdit from '@/screens/ArtistThemeEdit/ArtistThemeEdit';
import Collector from '@/screens/Collector/Collector';
import CollectorEdit from '@/screens/CollectorEdit/CollectorEdit';
import CollectorThemeEdit from '@/screens/CollectorThemeEdit/CollectorThemeEdit';
import Feed from '@/screens/Feed/FeedScreen';
import ImpersonatingFeed from '@/screens/Feed/ImpersonatingFeedScreen';
import NotificationSettings from '@/screens/NotificationSettings/NotificationSettings';
import Platform from '@/screens/Platform/Platform';
import Playlist from '@/screens/Playlist/Playlist';
import PlaylistCurators from '@/screens/PlaylistCurators/PlaylistCurators';
import ImpersonatingReleasesForYou from '@/screens/ReleasesForYou/ImpersonatingReleasesForYouScreen';
import ReleasesForYou from '@/screens/ReleasesForYou/ReleasesForYouScreen';
import ThemesSettings from '@/screens/ThemesSettings/ThemesSettings';
import TrackScreen from '@/screens/Track/TrackScreen';
import {selectSelectedUserId} from '@/store/user';
import {MainStackParams, Routes} from '@/types/routes';
import {isWeb} from '@/utils/platform';

const Stack = createNativeStackNavigator<MainStackParams>();

const MainNavigation = () => {
  const {isMobile} = useResponsive();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const [tabsContext, setTabsContext] = useState<Omit<
    BottomTabBarProps,
    'insets'
  > | null>(null);
  const contextValue = useMemo(() => ({setTabsContext}), [setTabsContext]);

  return (
    <TabsContext.Provider value={contextValue}>
      <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen name={Routes.Tabs} component={TabsNavigation} />
        <Stack.Screen name={Routes.ReleasesForYou} component={ReleasesForYou} />
        <Stack.Screen name={Routes.Feed} component={Feed} />
        <Stack.Screen
          name={Routes.ImpersonatingReleasesForYou}
          component={ImpersonatingReleasesForYou}
        />
        <Stack.Screen
          name={Routes.ImpersonatingFeed}
          component={ImpersonatingFeed}
        />
        <Stack.Screen name={Routes.Artist} component={ArtistScreen} />
        <Stack.Screen name={Routes.Track} component={TrackScreen} />
        <Stack.Screen name={Routes.Collector} component={Collector} />
        {/* Fallback for legacy route */}
        <Stack.Screen name={Routes.Collection} component={Collector} />
        <Stack.Screen name={Routes.Playlist} component={Playlist} />
        <Stack.Screen
          name={Routes.PlaylistCurators}
          component={PlaylistCurators}
        />
        <Stack.Screen name={Routes.Platform} component={Platform} />
        <Stack.Screen name={Routes.ActivityFeed} component={ActivityFeed} />
        <Stack.Screen name={Routes.ThemesSettings} component={ThemesSettings} />
        <Stack.Screen name={Routes.Wallet} component={WalletScreen} />
        {activeUserId && !isWeb && (
          <Stack.Screen
            name={Routes.NotificationSettings}
            component={NotificationSettings}
          />
        )}

        {activeUserId && (
          <Stack.Group screenOptions={{gestureEnabled: false}}>
            <Stack.Screen name={Routes.ArtistEdit} component={ArtistEdit} />
            <Stack.Screen
              name={Routes.CollectorEdit}
              component={CollectorEdit}
            />
            <Stack.Screen
              name={Routes.CollectorThemeEdit}
              component={CollectorThemeEdit}
            />
            <Stack.Screen
              name={Routes.ArtistThemeEdit}
              component={ArtistThemeEdit}
            />
          </Stack.Group>
        )}
        {activeUserId && (
          <Stack.Screen name={Routes.Wallets} component={WalletListScreen} />
        )}
        {activeUserId && (
          <Stack.Screen
            name={Routes.WalletsSettings}
            component={WalletsSettingsScreen}
          />
        )}
      </Stack.Navigator>
      {isMobile && tabsContext && <TabsNavigationBar {...tabsContext} />}
    </TabsContext.Provider>
  );
};

export default MainNavigation;
