import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {useLoginState, useLoginError} from '@/modules/Login';
import {Passkey} from '@/services/passkey';
import {selectSignInProcessStep, loginWithPasskey} from '@/store/user';
import {useThemedStyles} from '@/theme';

import {styles} from './LoginInitial.style';

const LoginInitial: FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {closeLoginModal, setLoginRoute} = useLoginState();
  const {connect, isConnected} = useExternalSigner();
  const signInProcessStep = useAppSelector(selectSignInProcessStep);
  const isPasskeyLoading = ![
    'idle',
    'requestingPasskey',
    'waitingForSignature',
  ].includes(signInProcessStep);
  const {setLoginError, handleLoginError, resetLoginError} = useLoginError();

  const onSignUpWithPasskey = async () => {
    resetLoginError();

    if (!Passkey.isBlobSupported) {
      setLoginError('passkey.error.blobNotSupported');
      return;
    }
    setLoginRoute('signUp');
  };

  const onLoginWithPasskey = async () => {
    resetLoginError();

    if (!Passkey.isBlobSupported) {
      setLoginError('passkey.error.blobNotSupported');
      return;
    }

    try {
      await dispatch(loginWithPasskey());
      closeLoginModal();
    } catch (error) {
      handleLoginError(error);
    }
  };

  const onConnectWallet = async () => {
    resetLoginError();
    setLoginRoute('linkWallet');

    if (isConnected) {
      return;
    }

    connect();
  };

  return (
    <Space style={style.content}>
      <Icon style={style.icon} provider="custom" name="login" size={76} />

      <Text weight="semibold" id="login.description" align="center" />

      {Passkey.isSupported && (
        <Space mb="l" style={style.topActions}>
          <CardButton
            onPress={onSignUpWithPasskey}
            text={{id: 'login.signup'}}
          />

          <View style={style.separatorContainer}>
            <View style={style.separator} />
            <Text id="login.or" />
            <View style={style.separator} />
          </View>

          <CardButton
            onPress={onLoginWithPasskey}
            text={{id: 'login.passkey'}}
            icon={{provider: 'custom', name: 'key'}}
            isLoading={isPasskeyLoading}
          />
        </Space>
      )}

      {!Passkey.isSupported && (
        <Text id="login.connectWallet.description" align="center" size="xs" />
      )}

      <View style={style.bottomActions}>
        {Passkey.isSupported && (
          <Text id="login.web3user" align="center" size="xs" />
        )}
        <CardButton
          onPress={onConnectWallet}
          text={{id: 'login.connectWallet'}}
          icon={{
            provider: 'custom',
            name: 'ethereum',
          }}
        />
      </View>
    </Space>
  );
};

export default LoginInitial;
