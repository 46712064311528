import React, {FC} from 'react';

import TransparentButton from '@/components/TransparentButton';
import {useThemedStyles} from '@/theme';

import {styles} from './AddWalletButton.style';

interface IProps {
  onPress: () => void;
}

const AddWalletButton: FC<IProps> = ({onPress}) => {
  const style = useThemedStyles(styles);

  return (
    <TransparentButton
      onPress={onPress}
      rippleDisabled
      style={style.button}
      text={{
        id: 'wallets.linkNewWallet',
      }}
    />
  );
};

export default AddWalletButton;
