export enum TransactionStep {
  Checkout = 'Checkout',
  ApprovalTransaction = 'ApprovalTransaction',
  MainTransaction = 'MainTransaction',
  Success = 'Success',
  Error = 'Error',
}

export interface ITxError {
  textId?: string;
  text?: string;
}

export type TransactionType = 'mint' | 'buy';
