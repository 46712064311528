import React from 'react';

import ArtistFeedCard from '../FeedCard/ArtistFeedCard';
import LocalMessageFeedCard from '../FeedCard/LocalMessageFeedCard';
import MessageFeedCard from '../FeedCard/MessageFeedCard';
import TrackFeedCard from '../FeedCard/TrackFeedCard';
import {IArtistWithTracks, ITrack} from '@/types/common';
import {IFeedEntityType, IFeedItem, ILocalMessageFeedItem} from '@/types/feed';

export interface IFeedCarouselProps {
  feedItems: (IFeedItem | ILocalMessageFeedItem)[];
  onTrackPlay: (track: ITrack) => void;
  onArtistTrackPlay: (artist: IArtistWithTracks) => (track: ITrack) => void;
  toggleLike?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  onEndReached?: () => void;
}

interface IRenderFeedItemParams {
  item: IFeedItem | ILocalMessageFeedItem;
  isActive: boolean;
  toggleHide?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  toggleLike?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  scrollToTop?: () => void;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

export const renderFeedItem = ({
  item,
  isActive,
  toggleHide,
  toggleLike,
  scrollToTop,
  next,
  setCarouselEnabled,
}: IRenderFeedItemParams) => {
  switch (item.entityType) {
    case IFeedEntityType.track:
      return (
        <TrackFeedCard
          feedItem={item}
          toggleLike={toggleLike}
          toggleHide={toggleHide}
          isActive={isActive}
          next={next}
          setCarouselEnabled={setCarouselEnabled}
        />
      );
    case IFeedEntityType.artist:
      return (
        <ArtistFeedCard
          feedItem={item}
          toggleLike={toggleLike}
          toggleHide={toggleHide}
          isActive={isActive}
          next={next}
          setCarouselEnabled={setCarouselEnabled}
        />
      );
    case IFeedEntityType.localMessage:
      return (
        <LocalMessageFeedCard
          scrollToTop={scrollToTop}
          feedItem={item}
          isActive={isActive}
        />
      );
    case IFeedEntityType.message:
      return <MessageFeedCard feedItem={item} />;
  }
};
