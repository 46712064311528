import {configureChains} from 'wagmi';
import {
  base,
  goerli,
  mainnet,
  optimism,
  polygon,
  sepolia,
  zora,
} from 'wagmi/chains';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {alchemyProvider} from 'wagmi/providers/alchemy';
import {publicProvider} from 'wagmi/providers/public';

import {config} from '@/constants/config';
import {isWeb} from '@/utils/platform';

const metadata = {
  name: config.APP_NAME,
  url: config.WEB_PAGE_URL,
  description: 'Spinamp',
  icons: ['https://app.spinamp.xyz/spin-logo.png'],
};

const projectId = config.WALLETCONNECT_PROJECT_ID;

const {publicClient, chains} = configureChains(
  [mainnet, goerli, polygon, optimism, base, zora, sepolia],
  [alchemyProvider({apiKey: config.ALCHEMY_API_KEY}), publicProvider()],
);

const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId,
    showQrModal: isWeb,
    metadata,
  },
});

export {metadata, publicClient, chains, projectId, walletConnectConnector};
