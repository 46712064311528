import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {useCallback} from 'react';

import {
  MainStackNavigationParams,
  MainStackParams,
  Routes,
} from '@/types/routes';

export type ITab = 'likes' | 'hides' | 'new';

export const parseTab = (tab: string | undefined): ITab => {
  if (tab === 'likes' || tab === 'hides') {
    return tab;
  }

  return 'new';
};

export const useTabs = () => {
  const {params} = useRoute<RouteProp<MainStackParams, Routes.Feed>>();
  const tab = parseTab(params?.tab);

  const navigation = useNavigation<MainStackNavigationParams>();

  const changeTab = useCallback(
    (newTab: ITab) => {
      navigation.setParams({tab: newTab});
    },
    [navigation],
  );

  return {
    tab,
    changeTab,
  };
};
