import React, {ReactNode, useEffect, useRef} from 'react';
import {StyleProp} from 'react-native';
import Animated, {FadeIn} from 'react-native-reanimated';

import ScreenError, {IScreenErrorProps} from '@/components/ScreenError';
import ScreenLoader, {IScreenLoaderProps} from '@/components/ScreenLoader';
import {isWeb} from '@/utils/platform';

// This component is designed to simplify rendering data received asynchronously, e.g using react-query.
// It takes mandatory isLoading prop, responsible for switching between loading screen and actual content,
// allowing also for content fade in animation once data is fetched.
//
// There are two ways to render actual content: using "children" or "renderContent" props.
// 1. "renderContent" prop must be provided together with "data" and it guarantees that data passed to "renderContent" is defined.
//    It will only be used if data is defined and it takes precedence over "isLoading" and "isError" props.
// 2. "children" can be used when we don't need data type narrowing.
//    It can be both ReactNode or render function and it doesn't require "data" prop.
//    It will be only rendered if "isLoading" and "isError" are falsy.

interface IProps<DataType> {
  data?: DataType | null;
  isLoading: boolean;
  renderContent?: (data: NonNullable<DataType>) => ReactNode;
  children?: ReactNode | (() => ReactNode);
  isError?: boolean;
  loaderProps?: IScreenLoaderProps;
  errorProps?: IScreenErrorProps;
  errorComponent?: ReactNode;
  loaderComponent?: ReactNode;
  style?: StyleProp<any>;
}

const AsyncContent = <DataType,>({
  data,
  renderContent,
  children,
  isLoading,
  isError = false,
  loaderProps = {},
  errorProps = {},
  loaderComponent,
  errorComponent,
  style,
}: IProps<DataType>) => {
  const wasLoading = useRef(isLoading);

  useEffect(() => {
    wasLoading.current = isLoading;
  }, [isLoading]);

  const enableFadeIn = !isWeb && wasLoading.current;

  if (data && renderContent) {
    return (
      <Animated.View
        style={[{flex: 1}, style]}
        entering={enableFadeIn ? FadeIn.duration(300) : undefined}>
        {renderContent(data)}
      </Animated.View>
    );
  }

  if (isLoading) {
    return <>{loaderComponent || <ScreenLoader {...loaderProps} />}</>;
  }

  if (isError) {
    return (
      <>{errorComponent || <ScreenError textId="notFound" {...errorProps} />}</>
    );
  }

  return (
    <Animated.View
      style={[{flex: 1}, style]}
      entering={enableFadeIn ? FadeIn.duration(300) : undefined}>
      {typeof children === 'function' ? children() : children}
    </Animated.View>
  );
};

export default AsyncContent;
