import add from './add.svg';
import arrowDown from './arrow-down.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import arrowUp from './arrow-up.svg';
import artist from './artist.svg';
import bandcamp from './bandcamp.svg';
import catalog from './catalog.svg';
import chain_base from './chain-base.svg';
import chain_ethereum from './chain-ethereum.svg';
import chain_optimism from './chain-optimism.svg';
import chain_polygon from './chain-polygon.svg';
import chain_zora from './chain-zora.svg';
import check from './check.svg';
import checked from './checked.svg';
import close from './close.svg';
import connected from './connected.svg';
import copy from './copy.svg';
import creditCard from './credit-card.svg';
import curatorAdd from './curator-add.svg';
import curators from './curators.svg';
import diamondSuccess from './diamond-success.svg';
import diamond from './diamond.svg';
import discord from './discord.svg';
import edit from './edit.svg';
import ethereum from './ethereum.svg';
import excited from './excited.svg';
import externalLink from './external-link.svg';
import externalPlatform from './external-platform.svg';
import facebook from './facebook.svg';
import feedEmpty from './feed-empty.svg';
import feedHides from './feed-hides.svg';
import feedLikes from './feed-likes.svg';
import feedVinyl from './feed-vinyl.svg';
import fork from './fork.svg';
import forward from './forward.svg';
import globe from './globe.svg';
import heart from './heart.svg';
import heart_fill from './heart_fill.svg';
import hey from './hey.svg';
import info from './info.svg';
import instagram from './instagram.svg';
import itunes from './itunes.svg';
import key from './key.svg';
import library from './library.svg';
import lock from './lock.svg';
import login from './login.svg';
import logoutModal from './logout-modal.svg';
import minus from './minus.svg';
import nftInfo from './nft-info.svg';
import nftLoading from './nft-loading.svg';
import nftMint from './nft-mint.svg';
import nftOwned from './nft-owned.svg';
import nftUnavailable from './nft-unavailable.svg';
import nina from './nina.svg';
import noizd from './noizd.svg';
import notConnected from './not-connected.svg';
import owner from './owner.svg';
import palette from './palette.svg';
import pause from './pause.svg';
import play from './play.svg';
import playlistAdd from './playlist-add.svg';
import playlistFree from './playlist-free.svg';
import playlistSolo from './playlist-solo.svg';
import privateIcon from './private.svg';
import publicIcon from './public.svg';
import queueAdd from './queue-add.svg';
import queue from './queue.svg';
import remove from './remove.svg';
import repeatOne from './repeat-one.svg';
import repeat from './repeat.svg';
import rewind from './rewind.svg';
import search from './search.svg';
import seekBackward15 from './seek-backward-15.svg';
import seekForward15 from './seek-forward-15.svg';
import settings from './settings.svg';
import sharePlaylist from './share-playlist.svg';
import share from './share.svg';
import shuffle from './shuffle.svg';
import signout from './signout.svg';
import sound from './sound.svg';
import soundcloud from './soundcloud.svg';
import spotify from './spotify.svg';
import switchArrows from './switch-arrows.svg';
import tidal from './tidal.svg';
import tiktok from './tiktok.svg';
import transfer from './transfer.svg';
import twitch from './twitch.svg';
import twitter from './twitter.svg';
import upvote from './upvote.svg';
import upvote_fill from './upvote_fill.svg';
import userMusic from './user-music.svg';
import user from './user.svg';
import visibilityOff from './visibility-off.svg';
import visibility from './visibility.svg';
import wallet from './wallet.svg';
import walletconnect from './walletconnect.svg';
import warning from './warning.svg';
import warpcast from './warpcast.svg';
import wrongNetwork from './wrong-network.svg';
import youtube from './youtube.svg';
import zora from './zora.svg';

export default {
  add,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  artist,
  bandcamp,
  catalog,
  chain_base,
  chain_ethereum,
  chain_optimism,
  chain_polygon,
  chain_zora,
  check,
  checked,
  close,
  connected,
  copy,
  creditCard,
  curatorAdd,
  curators,
  diamond,
  diamondSuccess,
  discord,
  edit,
  ethereum,
  excited,
  externalLink,
  externalPlatform,
  facebook,
  feedEmpty,
  feedHides,
  feedLikes,
  feedVinyl,
  fork,
  forward,
  heart,
  heart_fill,
  hey,
  globe,
  info,
  instagram,
  itunes,
  key,
  library,
  lock,
  login,
  logoutModal,
  minus,
  nftLoading,
  nftInfo,
  nftMint,
  nftOwned,
  nftUnavailable,
  nina,
  noizd,
  notConnected,
  owner,
  palette,
  play,
  playlistAdd,
  playlistFree,
  playlistSolo,
  privateIcon,
  publicIcon,
  pause,
  queue,
  queueAdd,
  remove,
  repeat,
  repeatOne,
  rewind,
  search,
  seekBackward15,
  seekForward15,
  settings,
  share,
  sharePlaylist,
  shuffle,
  sound,
  soundcloud,
  spotify,
  switchArrows,
  tidal,
  tiktok,
  transfer,
  twitch,
  twitter,
  signout,
  upvote,
  upvote_fill,
  userMusic,
  user,
  visibility,
  visibilityOff,
  wallet,
  walletconnect,
  warning,
  warpcast,
  wrongNetwork,
  youtube,
  zora,
};
