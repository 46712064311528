import {encodeFunctionData, isHex} from 'viem';
import {useWalletClient} from 'wagmi';

import {TransferError} from '@/modules/Transfer/errors';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {AddressString} from '@/types/common';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

export const useTransferTransaction = () => {
  const {
    transferParams,
    senderWallet,
    setTransferStep,
    setTxHash,
    setUserOpHash,
    setTransactionError,
    clearTransactionDetails,
  } = useTransferState();
  const {data: walletClient} = useWalletClient();

  const transferFromPasskey = async () => {
    setTransferStep('waitingForPasskey');
    const {credentialId, signer} = senderWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);
    console.log({privateKey});
    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransferError('WRONG_PASSKEY_SIGNER');
    }

    setTransferStep('executingTransaction');

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: transferParams.chainId,
      transaction: {
        to: transferParams.contract as AddressString,
        data: encodeFunctionData(getTransferWriteContractInput(transferParams)),
      },
    });

    setUserOpHash(userOpHash);

    setTransferStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: transferParams.chainId,
      hash: userOpHash,
    });

    setTxHash(transactionHash);

    if (status !== 'success') {
      throw new TransferError('TRANSACTION_REVERTED');
    }
  };

  const transferFromExternal = async () => {
    if (!walletClient) {
      throw new TransferError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== transferParams.from) {
      throw new TransferError('WRONG_WALLET_CONNECTED');
    }

    setTransferStep('executingTransaction');
    const txHash = await walletClient.writeContract(
      getTransferWriteContractInput(transferParams),
    );

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransferError('USER_CANCELLED');
    }

    setTxHash(txHash);

    setTransferStep('waitingForReceipt');

    const receipt = await getChainClient(
      transferParams.chainId,
    ).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      throw new TransferError('TRANSACTION_REVERTED');
    }
  };

  const executeTransfer = async () => {
    try {
      clearTransactionDetails();

      if (isPasskeyWallet(senderWallet)) {
        await transferFromPasskey();
      } else {
        await transferFromExternal();
      }

      setTransferStep('success');
    } catch (error: any) {
      setTransferStep('checkout');
      console.log('ERROR');
      if (
        (error instanceof PasskeyError && error.status === 'USER_CANCELLED') ||
        (error instanceof TransferError && error.status === 'USER_CANCELLED') ||
        // Those are errors code for canceling transactions in different external wallets I managed to catch
        error?.code === 5000 ||
        error?.cause?.code === 4001
      ) {
        console.log('USER CANCELED');
        clearTransactionDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          transfer: true,
          passkeyWallet: isPasskeyWallet(senderWallet),
        },
      });

      setTransactionError(error);
    }
  };

  return {
    executeTransfer,
  };
};
