import React, {FC, ReactNode} from 'react';

import Text from '@/components/Text/Text';
import {GlobalToastContext} from '@/modules/Toasts/GlobalToastContext';
import Toast from '@/modules/Toasts/Toast';
import ToastContainer from '@/modules/Toasts/ToastContainer';
import TopUpToast, {useTopUpToasts} from '@/modules/Toasts/TopUpToasts';
import TransactionToast, {
  useTransactionToasts,
} from '@/modules/Toasts/TransactionToasts';
import {useGlobalToastsConfig} from '@/modules/Toasts/useGlobalToastsConfig';

const ToastProvider: FC<{children: ReactNode}> = ({children}) => {
  const {globalToast, globalToastActions} = useGlobalToastsConfig();

  const {topUpTransactions, closeTopUpToast} = useTopUpToasts();

  const {transactions, closeTransactionToast} = useTransactionToasts();

  return (
    <GlobalToastContext.Provider value={globalToastActions}>
      {(globalToast ||
        transactions.length > 0 ||
        topUpTransactions.length > 0) && (
        <ToastContainer>
          {globalToast && (
            <Toast onClose={globalToastActions.hideToast} closeOnPress>
              <Text
                size="xs"
                color="invertedTextColor"
                align="center"
                id={globalToast.textId}
                values={globalToast.values}>
                {globalToast.text}
              </Text>
            </Toast>
          )}

          {topUpTransactions.map(transaction => (
            <TopUpToast
              key={transaction.id}
              topUpTransaction={transaction}
              onClose={() => closeTopUpToast(transaction.id)}
            />
          ))}

          {transactions.map(transaction => (
            <TransactionToast
              key={transaction.id}
              transaction={transaction}
              onClose={() => closeTransactionToast(transaction.id)}
            />
          ))}
        </ToastContainer>
      )}
      {children}
    </GlobalToastContext.Provider>
  );
};

export default ToastProvider;
