import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const HEIGHT = spacing.s * 2;

export const styles = createThemedStyles(() => ({
  button: {
    overflow: 'visible',
    paddingHorizontal: spacing.m,
    justifyContent: 'center',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content_disabled: {
    opacity: 0.2,
  },
  pressIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
