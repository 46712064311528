import {IIconProps} from '@/components/Icon';
import {Color} from '@/types/theme';

export interface IAction {
  onPress: () => void;
  icon: IIconProps;
}

export interface ICardMenuProps {
  exposedActions?: IAction[];
  collapsedActions: IAction[];
  iconColor?: Color;
  backgroundColor?: Color;
  menuIcon?: IIconProps;
}

export const defaultProps = {
  iconColor: 'primary' as Color,
  backgroundColor: 'background' as Color,
  menuIcon: {
    name: 'options-vertical',
    size: 14,
  } as IIconProps,
};
