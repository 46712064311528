import {RouteProp, useRoute} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import CollectModalsRouter from '@/modules/Collect/components/CollectModalsRouter';
import CollectScreenContent from '@/modules/Collect/components/CollectScreenContent';
import {useTrackBySlugQuery} from '@/queries/tracks';
import {useThemedStyles} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';

import {styles} from './CollectScreen.style';
import CollectStateProvider from './CollectStateProvider';

export const CollectScreen: FC = () => {
  const style = useThemedStyles(styles);

  const {
    params: {slug, referral},
  } = useRoute<RouteProp<RootStackParams, Routes.Collect>>();

  const {track, query} = useTrackBySlugQuery(slug);

  return (
    <ModalScreenWrapper
      titleId="collect.title"
      isFullscreenOnMobile
      disableClickOutside
      desktopSize="l">
      <AsyncContent
        data={track}
        isLoading={query.isLoading}
        isError={!track}
        renderContent={loadedTrack => (
          <CollectStateProvider track={loadedTrack} referralAddress={referral}>
            <CollectScreenContent />
            <CollectModalsRouter />
          </CollectStateProvider>
        )}
        loaderComponent={
          <View style={style.container}>
            <SpinLoader />
          </View>
        }
        errorComponent={
          <View style={style.container}>
            <Text id="notFound" weight="semibold" />
          </View>
        }
      />
    </ModalScreenWrapper>
  );
};

export default CollectScreen;
