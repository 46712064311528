import {QueryClient} from '@tanstack/react-query';

import {upsertFeedItem} from '@/api/feed';
import {type AppStore} from '@/store';
import {selectSignerByUserId} from '@/store/user';
import {IFeedItemRaw} from '@/types/feed';
import {QueryKeys} from '@/types/queryKeys';

export const setMutationDefaults = (
  queryClient: QueryClient,
  store: AppStore,
) => {
  queryClient.setMutationDefaults([QueryKeys.feed], {
    mutationFn: async (feedItem: IFeedItemRaw) => {
      const {userId} = feedItem;
      const signer = selectSignerByUserId(store.getState(), userId);

      if (!signer) {
        return Promise.reject(new Error('Missing signer'));
      }

      return upsertFeedItem(feedItem, signer);
    },
  });
};
