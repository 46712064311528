import {useLoginState} from '@/modules/Login';
import {parsePasskeyError, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';

export const useLoginError = () => {
  const {loginError, setLoginError} = useLoginState();

  return {
    loginError,
    setLoginError,
    handleLoginError: (error?: any) => {
      const errorMessageId = parsePasskeyError(error);

      if (!errorMessageId) {
        return;
      }

      Sentry.captureException(error, {
        tags: {
          login: true,
          passkey: error instanceof PasskeyError,
        },
      });

      setLoginError(errorMessageId);
    },
    resetLoginError: () => {
      if (loginError) {
        setLoginError(null);
      }
    },
  };
};
