import React, {useMemo} from 'react';

import {ArtistCard} from '@/components/ArtistCard';
import {IAction} from '@/components/CardMenu';
import TrackCard from '@/components/TrackCard/TrackCard';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {ITrack, PlayContextType} from '@/types/common';
import {IFeedItem, IFeedEntityType} from '@/types/feed';

export const FEED_CARD_INLINE_HEIGHT = 30;

interface IProps {
  feedItem: IFeedItem;
  onTrackPlay: (track: ITrack) => void;
  toggleLike?: (feedItem: IFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  playContext: PlayContextType;
}

const FeedCardInline: React.FC<IProps> = ({
  feedItem,
  onTrackPlay,
  toggleLike,
  toggleHide,
  playContext,
}): JSX.Element | null => {
  const {isTrackActive} = useIsTrackActive(playContext);

  const isLiked = feedItem.userAction === 'like';
  const isHidden = feedItem.userAction === 'hide';

  const actions = useMemo(() => {
    if (isHidden && toggleLike) {
      return [
        {
          onPress: () => toggleLike(feedItem),
          icon: {
            name: 'heart',
            provider: 'custom',
            fill: isLiked,
            color: isLiked ? 'favoritesColor' : 'textColor',
            size: 18,
          },
        } satisfies IAction,
      ];
    }

    if (isLiked && toggleHide) {
      return [
        {
          onPress: () => toggleHide(feedItem),
          icon: {
            provider: 'custom',
            name: isHidden ? 'visibility' : 'visibilityOff',
            size: 22,
          },
        } satisfies IAction,
      ];
    }
  }, [toggleLike, toggleHide, isLiked, isHidden]);

  switch (feedItem.entityType) {
    case IFeedEntityType.track:
      return (
        <TrackCard
          track={feedItem.track}
          onPlay={onTrackPlay}
          showArtist
          isActive={isTrackActive(feedItem.track.id)}
          exposedActions={actions}
        />
      );

    case IFeedEntityType.artist:
      return <ArtistCard artist={feedItem.artist} actions={actions} />;
    case IFeedEntityType.message:
      // don't show messages once they've been read
      return null;
  }
};

export default FeedCardInline;
