import {RouteProp, useRoute} from '@react-navigation/native';
import {useIsFetching, useIsMutating} from '@tanstack/react-query';
import React from 'react';

import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import SpinLoader from '@/components/SpinLoader';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useUserQuery} from '@/queries/user';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';
import {MainStackParams, Routes} from '@/types/routes';
import {getUserDisplayName} from '@/utils/user';

import Feed from './components/Feed';

const FeedScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const {params} =
    useRoute<RouteProp<MainStackParams, Routes.ImpersonatingFeed>>();
  const {slug} = params || {};

  const {user: impersonatingUser, query} = useUserQuery(slug);

  const isGeneratingFeed = useIsMutating({
    mutationKey: [MutationKeys.feedGeneration],
  });
  const isFetchingFeed = useIsFetching({
    queryKey: [QueryKeys.feed, impersonatingUser?.id],
  });

  const isLoading = !!(isGeneratingFeed || isFetchingFeed);

  const renderContent = () => {
    if (query.isLoading) {
      return (
        <>
          <Header showBack />
          <ScreenLoader />;
        </>
      );
    }

    if (query.isError) {
      return (
        <>
          <Header showBack />
          <ScreenError textId="error.title" />
        </>
      );
    }

    if (!impersonatingUser) {
      return (
        <>
          <Header showBack />
          <ScreenError textId="collector.notFound" />;
        </>
      );
    }

    return (
      <>
        <Header
          titleId="feed.impersonating.title"
          titleValues={{
            impersonatingUserName: getUserDisplayName(impersonatingUser),
          }}
          showBack
          scrollPosition={scrollPosition}
          mockTitle={true}
          rightActions={isLoading && <SpinLoader />}
        />

        <Feed
          userId={impersonatingUser.id}
          titleId="feed.impersonating.title"
          onScroll={onScroll}
          hideActions={true}
        />
      </>
    );
  };

  return <Screen>{renderContent()}</Screen>;
};

export default FeedScreen;
