import {InitializeAudio} from '@/services/additionalAudio/types';

export const initAdditionalAudio: InitializeAudio = props => {
  const audio = new Audio();
  audio.preload = 'auto';
  audio.autoplay = false;
  audio.loop = props.shouldLoop || false;

  let isLoaded = false;
  let intervalId: NodeJS.Timer;
  audio.onwaiting = () => props.setIsBuffering?.(true);
  audio.onplaying = () => props.setIsBuffering?.(false);
  audio.oncanplay = () => props.setIsBuffering?.(false);
  audio.onended = () => {
    props.onEnded?.();
  };
  audio.onloadedmetadata = () => {
    props.setDuration?.(audio.duration);
  };

  return {
    load: () => {
      if (isLoaded) {
        return;
      }

      audio.currentTime = props.startTimeInSeconds || 0;
      audio.src = props.uri;
      isLoaded = true;
    },
    play: () => {
      intervalId = setInterval(() => {
        props.setCurrentTime?.(audio.currentTime);
      }, 500);
      audio.play();
    },
    pause: () => {
      clearInterval(intervalId);
      audio.pause();
    },
    destroy: () => {
      clearInterval(intervalId);
      props.setIsBuffering?.(false);
      props.setCurrentTime?.(0);
      props.setDuration?.(0);
      audio.pause();
      audio.src = '';
    },
    seekTo: (value: number) => {
      props.setCurrentTime?.(value);
      audio.currentTime = value;
    },
  };
};
